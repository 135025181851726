import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { User } from '../../models/user.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  verifying = false;
  verifySuccess = false;
  verifyFailed = false;
  notVerified = false;
  verificationEmailSent = false;
  loginFailed = false;
  successfulLogin = false;
  user: User;
  token: string;
  loginSuccess = false;
  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    submitButton: new FormControl(''),
  });

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.apiService.GetCurrentUser().then((user) => {
      if (user) {
        debug(user);
        debug('Already Logged In');
        this.router.navigate(['/m']);
      }
    });

    // return;
    // you already logged in?

    //this.loginForm.get('submitButton')?.disable();
    this.updateFormValidation();
    this.route.queryParams.subscribe((params) => {
      if (params.token) {
        this.verifying = true;
        debug('Verifying Email Address');
        debug('token: ' + params.token);

        // verify email
        const response = this.apiService
          .verifyEmail(params.token)
          .then((data: any) => {
            debug(data);
            if (data.hasOwnProperty('verified') && data.verified == true) {
              debug('verified');

              this.verifySuccess = true;
              this.verifying = false;
              setTimeout(() => {
                //delay

                this.verifySuccess = false;
                this.loginForm.get('email').setValue(data['user'].email);

                debug('redirecting');
              }, 2000);
            } else {
              debug('not verified');
              this.verifyFailed = true;
              this.verifying = false;
            }
          })
          .catch((error: object) => {
            this.verifyFailed = true;
            this.verifying = false;
          });
      }
      debug(params.token);
    });
  }

  async onSubmit() {
    this.loginForm.disable();
    this.apiService
      .login(
        this.loginForm.get('email')?.value,
        this.loginForm.get('password')?.value
      )
      .then(async (value) => {
        this.loginFailed = false;
        debug('logged in');
        debug(value);
        this.user = value['user'];
        this.token = value['token'].token;
        debug('login: ----------------');
        debug('user: ' + value['user']);
        debug('token: ' + value['token'].token);
        this.apiService.setUser(value['user'], value['token'].token);
        this.loginSuccess = true;
        setTimeout(() => {
          //delay
          this.checkValidation();
          if (!this.notVerified) {
            //if the user IS verified log in fully
            this.successfulLogin = true;
            this.router.navigate(['/m']);
          }
        }, 2000);
      })
      .catch((error) => {
        debug('login failed');
        this.loginForm.disable();
        debug(error);
        this.loginFailed = true;
        setTimeout(() => {
          //delay
          this.updateFormValidation();
          this.loginForm.enable();
          this.loginFailed = false;
        }, 2000);
      });
  }

  async checkValidation() {
    if (this.user) {
      if (this.user.verified) {
        debug('email has been confirmed');
      } else {
        this.notVerified = true;
        debug('email address is not verified');
      }
    }
  }

  async updateFormValidation() {
    if (this.loginForm.valid) {
      this.loginForm.get('submitButton')?.enable();
    } else {
      this.loginForm.get('submitButton')?.disable();
    }
  }

  resendVerificationEmail(): void {
    if (this.user) {
      debug('resending verification email');
      this.apiService.resendVerificationEmail();
      this.verificationEmailSent = true;
      this.notVerified = false;
    }
  }
}

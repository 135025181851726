import { Component, OnInit, HostListener } from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import {debug, warn, error, info} from 'src/app/services/logger.service';

export enum KEY_CODE {
  ESCAPE = 27
}

@Component({
  selector: 'app-add-friend',
  templateUrl: './add-friend.component.html',
  styleUrls: ['./add-friend.component.scss']
})
export class AddFriendComponent implements OnInit {
  visible = false;
  public addFriendForm = new FormGroup({
    usernameOrEmail: new FormControl('',[Validators.required]),
    submitButton: new FormControl(''),
  });
  constructor(private popupController: PopupControllerService,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.addFriendForm.get('submitButton')?.disable();
    this.popupController.newFriend.subscribe((visible: boolean) => {
      this.visible = visible;
    });
  }

  onSubmit() {
    this.apiService.sendFriendRequest(this.addFriendForm.get('usernameOrEmail').value)
      .then((response) => {
        debug('Send Friend Request Response:');
        debug(response);
      })
      .catch((error) => {
        error(error);
      });

    this.killWindow();
  }

  async updateFormValidation() {
    if (this.addFriendForm.valid) {
      this.addFriendForm.get('submitButton')?.enable();
    } else {
      this.addFriendForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.addFriendForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }

}

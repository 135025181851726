import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';
import { ProjectsService } from 'src/app/services/api/projects/projects.service';

export enum KEY_CODE {
  ESCAPE = 27,
}
@Component({
  selector: 'app-modify-project',
  templateUrl: './modify-project.component.html',
  styleUrls: ['./modify-project.component.scss'],
})
export class ModifyProjectComponent implements OnInit {
  visible = false;
  project: any;
  serverId: string;

  public modifyProjectForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serverService: ServersService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.modifyProjectForm.get('submitButton')?.disable();
    this.popupController.modifyProject.subscribe((data) => {
      debug('updating modify-project visibility');
      this.visible = data == null ? false : true;
      this.project = data.project;
      this.serverId = data.serverId;

      console.log(data);

      this.modifyProjectForm.get('name')?.setValue(this.project.name);
    });
  }

  onSubmit() {
    this.projectsService
      .PatchProject(this.project._id, this.modifyProjectForm)
      .then((response) => {
        debug(response);
        this.serverService.getUsersOwnedServers();
        this.killWindow();
      });
  }

  async updateFormValidation() {
    if (this.modifyProjectForm.valid) {
      this.modifyProjectForm.get('submitButton')?.enable();
    } else {
      this.modifyProjectForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.modifyProjectForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

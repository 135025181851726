<div
  class="profile-screen-container"
  (click)="hideProfile()"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
>
  <div class="profile" [class.scalein]="visible" [class.scaleout]="!visible">
    <div class="profile-header"></div>
    <div class="profile-img-status">
      <div class="img-wrapper">
        <img
          *ngIf="userShort"
          [src]="'https://artdominion.imgix.net/' + userShort.avatar"
        />
        <div class="online-status-wrapper">
          <div class="online-status" placement="top" ngbTooltip="Online"></div>
        </div>
      </div>
    </div>
    <div class="profile-body-wrapper">
      <div class="profile-body">
        <div class="profile-username" *ngIf="userShort">
          {{ userShort.username.split("#")[0] }}
          <div class="digits">#{{ userShort.username.split("#")[1] }}</div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';
import { Asset } from 'src/app/models/asset.model';
import { FileService, FileTypes } from 'src/app/services/api/file.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-modify-asset',
  templateUrl: './modify-asset.component.html',
  styleUrls: ['./modify-asset.component.scss'],
})
export class ModifyAssetComponent implements OnInit {
  visible = false;
  asset: Asset;
  selectedImage;
  selectedImageSrc: string;

  public modifyAssetForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    thumbnail: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private fileService: FileService,
    private assetsService: AssetsService
  ) {}

  ngOnInit(): void {
    this.popupController.modifyAsset.subscribe((data) => {
      debug('updating modify-asset visibility');
      this.visible = data == null ? false : true;
      this.asset = data.asset;
      console.log(data);

      this.modifyAssetForm.get('name')?.setValue(this.asset.name);
      this.modifyAssetForm.get('thumbnail')?.setValue(this.asset.thumbnail);
      this.selectedImageSrc =
        'https://artdominion.imgix.net/' + this.asset.thumbnail;
    });
  }

  onSubmit() {
    if (this.selectedImage) {
      const img = new Image();
      img.src = URL.createObjectURL(this.selectedImage);
      img.onload = (e: any) => {
        const width = e.target.width;
        const height = e.target.height;

        // upload selected image
        this.fileService
          .getPresignedUrl(
            FileTypes.IMAGES,
            this.selectedImage.name,
            this.selectedImage.type,
            width,
            height
          )
          .then((response) => {
            debug('received response');
            this.modifyAssetForm.get('thumbnail')?.setValue(response.filename);

            debug(response);
            if (response.url) {
              debug('received presigned url');
              const fileUploadUrl = response.url;
              const imageForm = new FormData();
              imageForm.append('file', this.selectedImage);
              this.fileService
                .uploadToS3(
                  fileUploadUrl,
                  this.selectedImage.type,
                  this.selectedImage
                )
                .subscribe(async (uploadResponse) => {
                  if (uploadResponse && uploadResponse.type == 4) {
                    this.fileService
                      .confirmFileUpload(response._id)
                      .then((confirmResponse) => {
                        debug('thumbnail upload confirmed');
                        debug(confirmResponse);

                        this.modifyAsset();
                      });
                  }
                });
            }
          });
      };
    } else {
      this.modifyAsset();
    }
  }

  modifyAsset() {
    this.assetsService
      .patchAsset(this.modifyAssetForm, this.asset._id)
      .then((response) => {
        debug(response);
        this.assetsService.GetAllChannelAssets(this.asset.channelId);
        this.killWindow();
      });
  }

  async updateFormValidation() {
    if (this.modifyAssetForm.valid) {
      this.modifyAssetForm.get('submitButton')?.enable();
    } else {
      this.modifyAssetForm.get('submitButton')?.disable();
    }
  }

  onFileUpload(event) {
    debug(event);
    debug('selected new image 2');
    const image = (event.target as HTMLInputElement).files[0];
    console.log('.5');
    const reader = new FileReader();
    console.log('1');

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log('2');
      reader.readAsDataURL(file);
      console.log('3');
      reader.onload = () => {
        console.log('4');
        this.selectedImageSrc = reader.result as string;
        console.log(this.selectedImageSrc);
      };
    }

    this.selectedImage = image;
    debug(this.selectedImage);
    this.updateFormValidation();
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.modifyAssetForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

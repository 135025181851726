<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <div class="popup-title-bar">
          <span class="heading">{{ title }}</span>
          <button
            type="button"
            title="close"
            class="right-alight-titlebar"
            (click)="closeWindow()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </button>
        </div>
        <div class="main-form form-group">
          <div class="thumbnail center">
            <div class="private-description">
              {{ description }}
            </div>
          </div>
          <label
            *ngIf="type == eDialogType.CONFIRMATION_DIALOG_DESTRUCTIVE"
            for="confirmation"
            style="color: red"
            >TO CONFIRM PERMINENTLY DELETION, TYPE <b>"DELETE"</b>
            <input
              #confirmation
              id="confirmation"
              type="text"
              name="confirmation"
              (keyup)="updateFormValidation($event)"
            />
          </label>
          <label
            *ngIf="type == eDialogType.SINGLE_TEXT_INPUT_BOX_DIALOG"
            for="textinput"
            >{{ textInputLabel }}
            <input
              #textinput
              id="textinput"
              [(ngModel)]="textInputValue"
              type="text"
              name="textinput"
              (keyup)="updateFormValidation($event)"
            />
          </label>
          <div class="container dialog-margin">
            <div
              class="row"
              *ngIf="
                type == eDialogType.CONFIRMATION_DIALOG ||
                type == eDialogType.CONFIRMATION_DIALOG_DANGER ||
                type == eDialogType.CONFIRMATION_DIALOG_DESTRUCTIVE
              "
            >
              <div class="col-sm-1"></div>
              <div class="col-sm-3">
                <button
                  [disabled]="!valid"
                  (click)="onAnswer(true)"
                  class="btn btn-block"
                  [class.btn-disabled]="!valid"
                  [class.btn-danger]="
                    type == eDialogType.CONFIRMATION_DIALOG_DANGER ||
                    type == eDialogType.CONFIRMATION_DIALOG_DESTRUCTIVE
                  "
                  type="submit"
                  name="submitButton"
                >
                  <div #yes class="btnText">YES</div>
                </button>
              </div>
              <div class="col-sm-4"></div>
              <div class="col-sm-3">
                <button
                  (click)="onAnswer(false)"
                  class="btn btn-block btn-cancel"
                  type="submit"
                  name="submitButton"
                >
                  <div class="btnText">CANCEL</div>
                </button>
              </div>
            </div>

            <div class="row" *ngIf="type == eDialogType.ALERT">
              <div class="col-sm-4"></div>
              <div class="col-sm-4">
                <button
                  (click)="onAnswer(false)"
                  class="btn btn-block"
                  type="submit"
                  name="submitButton"
                >
                  <div class="btnText">CLOSE</div>
                </button>
              </div>
            </div>
            <div
              class="row"
              *ngIf="type == eDialogType.SINGLE_TEXT_INPUT_BOX_DIALOG"
            >
              <div class="col-sm-4"></div>
              <div class="col-sm-4">
                <button
                  (click)="onAnswer(true)"
                  class="btn btn-block"
                  type="submit"
                  name="submitButton"
                >
                  <div class="btnText">Create</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Asset } from 'src/app/models/asset.model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

export enum RequestTypes {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
}

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractEndpointService {
  protected abstract path;

  constructor(
    protected apiService: ApiService,
    protected httpClient: HttpClient
  ) {}

  protected async AuthenticatedRequest<T>(
    requestType: RequestTypes,
    url: string = '',
    body: any = null
  ): Promise<T> {
    //console.log('AuthenticatedReqest');
    if (body) {
      //console.log('AuthenticatedReqest - has body');
      if (typeof body == typeof FormGroup) {
        //console.log('AuthenticatedReqest - has body - is form group');
        body = body.getRawValue();
      }
      if (typeof body == typeof Object) {
        //console.log('AuthenticatedReqest - has body - is object');
        body = JSON.stringify(body);
      }
    }

    if (this.apiService.user && this.apiService.token) {
      //console.log('AuthenticatedReqest - user is logged in');
      let asset;
      //console.log('AuthenticatedReqest - has asset');
      const fullUrl: string = this.apiService.URI + this.path + url;
      //console.log('AuthenticatedReqest - fullUrl: "' + fullUrl + '"');
      switch (requestType) {
        case RequestTypes.GET:
          //console.log('AuthenticatedReqest - request type is GET');
          asset = await this.httpClient
            .get<T>(fullUrl, this.apiService.options)
            .toPromise();
          break;
        case RequestTypes.POST:
          //console.log('AuthenticatedReqest - request type is POST');
          asset = await this.httpClient
            .post<T>(fullUrl, body, this.apiService.options)
            .toPromise();
          break;
        case RequestTypes.PUT:
          //console.log('AuthenticatedReqest - request type is PUT');
          asset = await this.httpClient
            .put<T>(fullUrl, body, this.apiService.options)
            .toPromise();
          break;
        case RequestTypes.PATCH:
          //console.log('AuthenticatedReqest - request type is PATCH');
          asset = await this.httpClient
            .patch<T>(fullUrl, body, this.apiService.options)
            .toPromise();
          break;
        case RequestTypes.DELETE:
          //console.log('AuthenticatedReqest - request type is DELETE');
          asset = await this.httpClient
            .delete<T>(fullUrl, this.apiService.options)
            .toPromise();
          break;
      }
      return asset as unknown as T;
    }
    return null;
  }
}

import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import ChannelParentIdType from 'src/app/enums/channel-Parent-Id-Type.enum';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-new-channel',
  templateUrl: './new-channel.component.html',
  styleUrls: ['./new-channel.component.scss'],
})
export class NewChannelComponent implements OnInit {
  visible = false;
  isProjectChannel: boolean = false;
  public addChannelForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    private: new FormControl(''),
    channelType: new FormControl(''),
    serverId: new FormControl(''),
    categoryId: new FormControl(''),
    projectId: new FormControl(''),
    channelParentIdType: new FormControl(''),
    owner: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serversService: ServersService
  ) {}

  ngOnInit(): void {
    this.addChannelForm.get('submitButton')?.disable();
    this.popupController.newChannel.subscribe((data: any) => {
      debug('Reloading New Category Popup. Payload:');

      // is this a project or a category
      if (data.channelParentIdType == ChannelParentIdType.CATEGORY) {
        this.isProjectChannel = false;
        this.addChannelForm.get('categoryId')?.setValue(data.id);
      } else if (data.channelParentIdType == ChannelParentIdType.PROJECT) {
        this.isProjectChannel = true;
        this.addChannelForm.get('projectId')?.setValue(data.id);
      }

      this.addChannelForm
        .get('channelParentIdType')
        ?.setValue(data.channelParentIdType);
      this.addChannelForm.get('serverId')?.setValue(data.serverId);
      this.addChannelForm.get('owner')?.setValue(data.owner);

      console.log(data);
      if (data.serverId == null) {
        this.visible = false;
      } else {
        this.visible = true;
      }

      debug('new category popup - ServerId: ' + data.serverId);
    });
  }

  onSubmit() {
    this.serversService
      .createChannel(this.addChannelForm)
      .then((response) => {
        debug('Create Server Response:');
        debug(response);
      })
      .catch((error) => {
        debug('Error: ' + error);
      });

    this.killWindow();
  }

  async updateFormValidation() {
    if (this.addChannelForm.valid) {
      this.addChannelForm.get('submitButton')?.enable();
    } else {
      this.addChannelForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.addChannelForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

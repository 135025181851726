import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Server } from 'src/app/models/server.model';
import { ContextMenuService } from 'src/app/services/context-menu.service';
import { ArtboardService } from 'src/app/services/artboard/artboard.service';
import { interval, Subscription } from 'rxjs';
import { FileService, FileTypes } from 'src/app/services/api/file.service';
import { ClipboardService } from 'ngx-clipboard';
import Point from 'src/app/classes/point.model';
import { DrawingsService } from 'src/app/services/api/assets/drawings.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { convertCompilerOptionsFromJson } from 'typescript';
import { GlobalLocationResolverService } from 'src/app/services/global-location-resolver.service';
import { ServersService } from 'src/app/services/api/servers.service';

interface Elements {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  aspectRatio?: number;
  text?: string;
  selected?: boolean;
  hovered?: boolean;
  elType: ElementTypes;
  imageLocation?: string;
}

export enum KEY_CODE {
  ESCAPE = 27,
  RETURN = 13,
  BACKSPACE = 8,
  DELETE = 46,
  SPACE = 32,
  TAB = 9,
}
interface Rect {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  aspectRatio?: number;
}

enum ArtboardStates {
  RESIZE,
  NONE,
  MOVE,
  NEW_TEXT,
  NEW_IMAGE,
  NEW_RECT,
  PANNING,
}

enum ElementTypes {
  IMAGE = 'image',
  TEXT = 'text',
  RECT = 'rect',
}

enum Tool {
  SELECTION,
  TEXT,
  IMAGE,
}

@Component({
  selector: 'app-art-board-channel',
  templateUrl: './art-board-channel.component.html',
  styleUrls: ['./art-board-channel.component.scss'],
})
export class ArtBoardChannelComponent implements OnInit {
  @Input()
  selectedServer: Server;

  @Input()
  selectedChannel;

  @Input()
  selectedAssetTab;

  @Input()
  selectedAssetId: string;

  @Output()
  selectedAssetTabState = new EventEmitter<string>();

  selectedItemIndex;
  artboardState: ArtboardStates = ArtboardStates.NONE;
  eArtboardStates = ArtboardStates;
  initalRezise = false;

  eElementTypes = ElementTypes;
  /*
  topLeftAnchorHover: boolean;
  topRightAnchorHover: boolean;
  bottomLeftAnchorHover: boolean;
  bottomRightAnchorHover: boolean;
  */
  anchorHover: string;
  // scale = 0.4;
  // resizing: boolean = false;
  initialMousePosition: Rect = {};
  currentMousePosition: Rect = {};
  initialOrigin: Rect = {};

  originalElementPosition: Rect = {};
  moveCursorOffset: Rect = {};

  origin: Rect = { x: 0, y: 0 };
  middleMouseDown: boolean = false;
  // elements: Elements[] = [];
  elements: Elements[] = [];

  @ViewChild('canvas')
  canvasRef: ElementRef;

  @ViewChild('imageinput')
  imageinputRef;

  justClickedElement: boolean = false;

  scale: number = 1.0;
  previousScale: number = 1.0;
  targetScale: number = 1.0;
  currentScale: number = 1.0;
  scaleInterpolationSubscription: Subscription;

  // image uploading
  imageUploadError: boolean = false;
  imageUploadSelected: File;
  testurl: string;

  selectedTool: Tool = Tool.SELECTION;
  eTool = Tool;

  mouseActionlocation: Rect;
  isItemMoving: boolean = false;

  // text for text tool
  editingTextareaIndex = null;
  editingText: boolean = false;

  constructor(
    private contextMenuService: ContextMenuService,
    private artboardService: ArtboardService,
    private clipboardApi: ClipboardService,
    private drawingService: DrawingsService,
    private assetService: FileService,
    private globalLocationResolver: GlobalLocationResolverService,
    private serverService: ServersService
  ) {
    this.assetService.assetUploadEvent.subscribe((uploadedAsset) => {
      this.AddImageAssetToElements(uploadedAsset);
      this.saveDrawing();
    });

    this.globalLocationResolver.activeChannelIdSub.subscribe((channelId) => {
      this.selectedChannel = this.serverService.getChannel(channelId);
    });
  }

  ngOnInit(): void {
    // get asset id from url

    this.RefreshDrawings();
  }

  AddImageAssetToElements(asset) {
    const aspectRatio = asset.height / asset.width;
    let width, height, imageLocation, x, y;

    if (asset.filename) {
      imageLocation = 'https://artdominion.imgix.net/' + asset.filename;
      x = this.mouseActionlocation.x;
      y = this.mouseActionlocation.y;
      if (asset.width > asset.height) {
        width = 400;
        height = width * aspectRatio;
      } else {
        height = 400;
        width = height / aspectRatio;
      }
    } else if (asset.imageLocation) {
      imageLocation = asset.imageLocation;
      width = asset.width;
      height = asset.height;
      x = asset.x;
      y = asset.y;
    } else {
      // image cannot be found
      imageLocation = null;
      width = 0;
      height = 0;
      x = 0;
      y = 0;
    }

    this.elements.push({
      x,
      y,
      width: width,
      height: height,
      aspectRatio: aspectRatio,
      elType: ElementTypes.IMAGE,
      imageLocation: imageLocation,
    });
  }

  changeAssetTab(tab: string) {
    this.selectedAssetTabState.emit(tab);
  }

  deleteElement(i) {
    this.elements.splice(i, 1);
    this.saveDrawing();
  }

  openAddElementMenu(event) {
    this.clearArray();
    debug(event);
    const data = {};
    this.contextMenuService.showArtboardAddElementDialog(
      event.clientX,
      event.clientY - 150,
      data
    );
  }

  disableAddMenu() {
    debug('disableAddMenu');
    if (this.justClickedElement) {
      this.justClickedElement = false;
      return;
    }
    this.clearArray();
    this.contextMenuService.hideArtboardAddElementDialog();
  }

  clickedDivElement(mouseEvent, i) {
    debug('clickedDivElement');
    if (mouseEvent.button == 0) {
      this.justClickedElement = true;
      debug('index: ' + i);
      debug('item: ' + this.elements[i]);
      this.clearArray();
      this.elements[i].selected = true;
      this.selectedItemIndex = i;
      // this.divItems.splice(i,1);
    }
  }

  clearArray() {
    this.elements.forEach((element) => {
      element.selected = false;
    });
  }

  onMouseEnterResizehandle(handleType) {
    if (this.artboardState == ArtboardStates.NONE) {
      debug('mouse enter: ' + handleType);
      this.anchorHover = handleType;
    }
  }

  onMouseLeaveResizehandle() {
    if (this.artboardState == ArtboardStates.NONE) {
      debug('mouse Left');
      this.anchorHover = null;
    }
  }

  onMouseDownHandle(mouseEvent) {
    if (mouseEvent.button == 0) {
      // left mouse
      if (this.artboardState == ArtboardStates.NONE && this.anchorHover) {
        this.artboardState = ArtboardStates.RESIZE;
        this.initalRezise = true;
        debug('mouse down');
      }
    }
  }

  onMouseDown(mouseEvent) {
    debug('mouse down');
    if (mouseEvent.button == 0) {
      switch (this.selectedTool) {
        case Tool.SELECTION:
          debug('deselect');
          debug(this.selectedItemIndex);
          if (this.artboardState == ArtboardStates.NONE) {
            this.elements.forEach((item) => {
              item.selected = false;
            });
            this.selectedItemIndex = null;
            debug('no text enements editing');
            this.editingTextareaIndex = null;
            document.getSelection().removeAllRanges();
            debug('deselecting everything');
          }
          break;
        case Tool.TEXT:
          // create text
          this.mouseActionlocation = {
            x: mouseEvent.x - 310,
            y: mouseEvent.y - 95,
          };
          this.elements.push({
            x: this.mouseActionlocation.x - 10,
            y: this.mouseActionlocation.y - 15,
            width: 200,
            height: 45,
            elType: ElementTypes.TEXT,
          });

          setTimeout(() => {
            this.canvasRef.nativeElement.children[
              this.elements.length - 1
            ].children[0].children[1].focus();
          }, 200);
          this.selectedTool = Tool.SELECTION;
          this.saveDrawing();

          break;
        case Tool.IMAGE:
          // create image
          this.imageinputRef.nativeElement.click();
          debug('Mouse Event');
          debug(mouseEvent);
          this.mouseActionlocation = {
            x: mouseEvent.x - 310,
            y: mouseEvent.y - 95,
          };
          break;
      }
    } else if (mouseEvent.button == 1) {
      //middle mouse
      this.artboardState = ArtboardStates.PANNING;
      this.middleMouseDown = true;
      this.initialMousePosition = {
        x: mouseEvent.x - 310,
        y: mouseEvent.y - 95,
      };
      this.initialOrigin = this.origin;
      debug('first init');
      debug(this.initialMousePosition);
    }
  }

  onMouseUp(mouseEvent) {
    debug('Artboard state set to NONE - onMouseUp');
    if (
      this.artboardState == ArtboardStates.MOVE ||
      this.artboardState == ArtboardStates.RESIZE
    ) {
      this.saveDrawing();
    }
    this.artboardState = ArtboardStates.NONE;
    this.anchorHover = null;
  }

  onMouseEnterElement(i) {
    this.elements[i].hovered = true;
  }

  onMouseLeaveElement(i) {
    this.elements[i].hovered = false;
  }

  onEditElement(i) {
    debug('editElement');
    debug('canvas:');
    debug(this.canvasRef.nativeElement.children[i].children[0]);
    if (this.elements[i].elType == ElementTypes.TEXT) {
      this.editingTextareaIndex = i;
      //this.editingText = true; //changed
      debug('editing element enabled: ' + i);
      this.canvasRef.nativeElement.children[i].children[0].focus();
    }
  }

  onMouseDownElement(mouseEvent, i) {
    if (mouseEvent.button == 0) {
      debug('Artboard State: ' + ArtboardStates[this.artboardState]);
      debug('Anchor Hover: ' + this.anchorHover);
      if (this.artboardState == ArtboardStates.NONE && !this.anchorHover) {
        debug('moving');
        debug('selected Item pre: ' + this.selectedItemIndex);
        this.selectedItemIndex = i;
        debug('selected Item post: ' + this.selectedItemIndex);
        debug('Artboard State set to MOVE - onMouseDownElement');
        this.artboardState = ArtboardStates.MOVE;
        this.moveCursorOffset.x = mouseEvent.offsetX;
        this.moveCursorOffset.y = mouseEvent.offsetY;
        debug(mouseEvent);
      }
    } else if (mouseEvent.button == 1) {
      //middle mouse
      debug('Artboard state panning');
      this.artboardState = ArtboardStates.PANNING;
      this.middleMouseDown = true;
      this.initialMousePosition = {
        x: mouseEvent.x - 310,
        y: mouseEvent.y - 95,
      };
    }
  }

  mouseMoveOnCanvas(mouseEvent) {
    // debug('Artboard State: ' + ArtboardStates[this.artboardState]);
    if (mouseEvent.buttons == 0) {
      // debug('set artboard to NONE - mouseMoveOnCanvas');
      this.artboardState = ArtboardStates.NONE;
    }
    const mouseDifference: Rect = {};
    this.currentMousePosition.x = mouseEvent.x - 310;
    this.currentMousePosition.y = mouseEvent.y - 90;
    //debug('mouse moving on canvas');

    switch (this.artboardState) {
      case ArtboardStates.RESIZE:
        if (this.initalRezise) {
          this.initialMousePosition.x = mouseEvent.x - 310;
          this.initialMousePosition.y = mouseEvent.y - 95;
          this.originalElementPosition.x =
            this.elements[this.selectedItemIndex].x;
          this.originalElementPosition.y =
            this.elements[this.selectedItemIndex].y;
          this.originalElementPosition.width =
            this.elements[this.selectedItemIndex].width;
          this.originalElementPosition.height =
            this.elements[this.selectedItemIndex].height;
          this.initalRezise = false;
        }
        // debug('resizing');

        mouseDifference.x =
          this.currentMousePosition.x - this.initialMousePosition.x;
        mouseDifference.y =
          this.currentMousePosition.y - this.initialMousePosition.y;

        switch (this.anchorHover) {
          case 'top-left':
            this.elements[this.selectedItemIndex].width =
              this.originalElementPosition.width -
              mouseDifference.x / this.scale;
            if (
              this.elements[this.selectedItemIndex].elType == ElementTypes.IMAGE
            ) {
              this.elements[this.selectedItemIndex].height =
                this.elements[this.selectedItemIndex].width *
                this.elements[this.selectedItemIndex].aspectRatio;
            } else {
              this.elements[this.selectedItemIndex].height =
                this.originalElementPosition.height -
                mouseDifference.y / this.scale;
            }
            this.elements[this.selectedItemIndex].x =
              this.originalElementPosition.x + mouseDifference.x / this.scale;
            this.elements[this.selectedItemIndex].y =
              this.originalElementPosition.y + mouseDifference.y / this.scale;
            break;
          case 'top-right':
            this.elements[this.selectedItemIndex].width =
              this.originalElementPosition.width +
              mouseDifference.x / this.scale;
            if (
              this.elements[this.selectedItemIndex].elType == ElementTypes.IMAGE
            ) {
              this.elements[this.selectedItemIndex].height =
                this.elements[this.selectedItemIndex].width *
                this.elements[this.selectedItemIndex].aspectRatio;
            } else {
              this.elements[this.selectedItemIndex].height =
                this.originalElementPosition.height +
                mouseDifference.y / this.scale;
            }
            this.elements[this.selectedItemIndex].y =
              this.originalElementPosition.y + mouseDifference.y / this.scale;
            break;
          case 'bottom-left':
            this.elements[this.selectedItemIndex].width =
              this.originalElementPosition.width -
              mouseDifference.x / this.scale;
            this.elements[this.selectedItemIndex].x =
              this.originalElementPosition.x + mouseDifference.x / this.scale;
            if (
              this.elements[this.selectedItemIndex].elType == ElementTypes.IMAGE
            ) {
              this.elements[this.selectedItemIndex].height =
                this.elements[this.selectedItemIndex].width *
                this.elements[this.selectedItemIndex].aspectRatio;
            } else {
              this.elements[this.selectedItemIndex].height =
                this.originalElementPosition.height -
                mouseDifference.y / this.scale;
            }
            break;
          case 'bottom-right':
            this.elements[this.selectedItemIndex].width =
              this.originalElementPosition.width +
              mouseDifference.x / this.scale;
            if (
              this.elements[this.selectedItemIndex].elType == ElementTypes.IMAGE
            ) {
              this.elements[this.selectedItemIndex].height =
                this.elements[this.selectedItemIndex].width *
                this.elements[this.selectedItemIndex].aspectRatio;
            } else {
              this.elements[this.selectedItemIndex].height =
                this.originalElementPosition.height +
                mouseDifference.y / this.scale;
            }

            break;
        }
        break;
      case ArtboardStates.MOVE:
        //debug('moving');

        // debug(this.selectedItem);
        if (this.selectedItemIndex != this.editingTextareaIndex) {
          //debug('Scale: ' + this.scale);
          //debug('Origin x: ' + this.origin.x + ', y: ' + this.origin.y);
          //debug('point on screen: ' + (this.currentMousePosition.x - this.moveCursorOffset.x));
          //debug('origin: ' + this.origin.x);
          //debug('Scale: ' + this.scale);
          this.elements[this.selectedItemIndex].x =
            (this.currentMousePosition.x -
              this.moveCursorOffset.x -
              this.origin.x) /
            this.scale;
          this.elements[this.selectedItemIndex].y =
            (this.currentMousePosition.y -
              this.moveCursorOffset.y -
              this.origin.y) /
            this.scale;
        }
        break;
      case ArtboardStates.PANNING:
        //debug(mouseEvent);
        //debug('moving origin');
        //debug('Initial: ');
        //debug(this.initialMousePosition);
        //debug('Current: ');
        //debug(this.currentMousePosition);
        //debug('Origin: x:' + this.origin.x + ', y: ' + this.origin.y);
        //debug(this.origin);
        //debug();
        //debug('difference');
        const difference: Rect = {
          x: this.currentMousePosition.x - this.initialMousePosition.x,
          y: this.currentMousePosition.y - this.initialMousePosition.y,
        };
        //debug(difference);
        this.origin = {
          x: this.initialOrigin.x + difference.x,
          y: this.initialOrigin.y + difference.y,
        };
        break;
    }
  }

  scrollUp() {
    this.previousScale = this.scale;
    this.scale += 0.2;
    this.scale = Math.min(this.scale, 2.0);

    // const intval = interval(300);

    // this.scale += .2;

    // set interval for scalar interpolation

    // set target scale

    this.updateScale();
  }

  scrollDown() {
    this.previousScale = this.scale;
    this.scale -= 0.2;
    this.scale = Math.max(this.scale, 0.4);

    this.updateScale();
  }

  updateScale() {
    const scaleDifference = this.previousScale - this.scale;

    const xOffset =
      (this.canvasRef.nativeElement.offsetWidth / 2) * scaleDifference;
    const yOffset =
      (this.canvasRef.nativeElement.offsetHeight / 2) * scaleDifference;

    this.origin = {
      x: this.origin.x + xOffset,
      y: this.origin.y + yOffset,
    };
  }

  scaleTick() {
    this.updateScale();
  }

  changeTool(tool: Tool) {
    this.selectedTool = tool;
  }
  /*
  actionTool(event) {
    debug(event);

  }*/

  disableTool() {
    this.selectedTool = Tool.SELECTION;
  }

  copyText(text) {
    debug('copying');
    this.clipboardApi.copy(text);
  }

  onDropFileUpload(event) {
    debug(event);

    this.mouseActionlocation = { x: event.x - 310, y: event.y - 95 };
    const files = event.dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      this.uploadImage(files[i]);
    }
  }

  onFileUpload(event: Event) {
    const image = (event.target as HTMLInputElement).files[0];
    this.uploadImage(image);
  }

  uploadImage(image) {
    // Get image width and height
    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = (e: any) => {
      const width = e.target.width;
      const height = e.target.height;

      this.assetService
        .getPresignedUrl(
          FileTypes.IMAGES,
          image.name,
          image.type,
          width,
          height
        )
        .then((response) => {
          debug('received response');
          debug(response);
          if (response.url) {
            debug('received presigned url');
            const fileUploadUrl = response.url;
            const imageForm = new FormData();
            imageForm.append('file', image);
            this.assetService
              .uploadToS3(fileUploadUrl, image.type, image)
              .subscribe(async (uploadRes) => {
                debug('upload resolution');
                console.log(uploadRes);
                if (uploadRes && uploadRes.type == 4) {
                  debug(uploadRes);
                  this.assetService
                    .confirmFileUpload(response._id)
                    .then((response) => {
                      debug('Confirmation Response:');
                      debug(response);
                      this.disableTool();
                      this.imageinputRef.nativeElement.value = '';
                    });
                }
              });
          }
        })
        .catch((e) => {
          error('could not get presign url: ' + e);
        });
    };
  }

  changeText(elementIndex: number, event) {
    this.elements[elementIndex].text = event.srcElement.value;
    this.saveDrawing();
  }

  /**
   * Updates the drawing in the api
   */
  saveDrawing() {
    debug('Saving Drawing');
    console.log(this.selectedChannel);
    this.drawingService.CreateDrawing(
      {
        elements: this.elements,
      },
      'art-board',
      this.selectedAssetId
    );
  }

  async RefreshDrawings() {
    if (this.artboardState != ArtboardStates.NONE) return; //Don't update if the user is doing something to the artboard
    debug('refreshing artboard');
    debug('AssetId: ' + this.selectedAssetId);
    const assetDrawings = (await this.drawingService.GetDrawings(
      this.selectedAssetId
    )) as any[];
    if (assetDrawings) {
      console.log(
        '======================== ARTBOARD TESTING ========================'
      );
      console.log(assetDrawings);
      const artboard = await assetDrawings.find(
        (value) => value.drawingType == 'art-board'
      );
      console.log(artboard);
      // this.elements = artboard.elements;
      for (let i = 0; i < artboard.elements.length; i++) {
        console.log(artboard.elements[i]);
        if (artboard.elements[i].elType == 'image') {
          this.AddImageAssetToElements(artboard.elements[i]);
        } else {
          this.elements.push(artboard.elements[i]);
        }
        /*
        console.log('Image ' + i + ' name: ' + this.elements[i].imageLocation);
        console.log('Image ' + i + ' type: ' + this.elements[i].elType);
        console.log('Image ' + i + ' width: ' + this.elements[i].width);
        console.log('Image ' + i + ' height: ' + this.elements[i].height);
        console.log('Image ' + i + ' aspect ratio: ' + this.elements[i].aspectRatio);
*/
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (
      event.keyCode === KEY_CODE.DELETE &&
      this.selectedItemIndex != null &&
      !this.editingText
    ) {
      //TODO: if editing text don't remove
      this.deleteElement(this.selectedItemIndex);
    }
  }

  /*
  @HostListener('window:mousedown', ['$event'])
  mouseDownEvent(event: MouseEvent) {
    event.preventDefault();
  }
*/

  @HostListener('window:mouseup', ['$event'])
  mouseUpEvent(event: MouseEvent) {
    console.log('mouse up triggered');
    this.onMouseUp(event);
  }
}

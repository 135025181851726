import { Injectable } from '@angular/core';
import { Asset } from 'src/app/models/asset.model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AbstractEndpointService,
  RequestTypes,
} from '../abstract-endpoint.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsService extends AbstractEndpointService {
  protected path = '/assets';
  private _assets: Asset[] = [];
  public assets: Subject<Asset[]> = new Subject<Asset[]>();

  RefreshAssets() {
    this.assets.next(this._assets);
  }

  /*
  set SetAssets(assets: Asset[]) {
    this._assets = assets;
    this.assets.next(this._assets);
  }*/

  //TODO: These assets would bring back width and height, but htey need to be bringing back imageSize.x and imageSize.y

  async CreateAsset(data: FormGroup): Promise<Asset> {
    return this.AuthenticatedRequest<Asset>(
      RequestTypes.POST,
      '',
      data.getRawValue()
    ).then((asset) => {
      this._assets.push(asset);
      this.assets.next(this._assets);
      return asset;
    });
  }

  async GetAllChannelAssets(channelId: string): Promise<Asset[]> {
    console.log('getting all channel assets');
    return this.AuthenticatedRequest<any>(
      RequestTypes.GET,
      `/${channelId}`
    ).then((assets) => {
      console.log('getting new assets');
      let newAssets = [];
      for (let i = 0; i < assets.assets.length; i++) {
        // assets contain posts
        const posts = [];
        for (let ipost = 0; ipost < assets.assets[i].posts.length; ipost++) {
          posts.push({
            ...assets.assets[i].posts[ipost],
          });
        }
        newAssets.push({
          ...assets.assets[i],
          posts: posts,
        });
      }

      this._assets = newAssets;
      this.assets.next(this._assets);
      console.log('Assets:');
      console.log(JSON.stringify(this._assets));
      return this._assets;
    });
  }

  async deleteAsset(assetId: string): Promise<object> {
    console.log('deleting asset ' + assetId);
    return this.AuthenticatedRequest<Asset>(
      RequestTypes.DELETE,
      `/${assetId}`
    ).then((response) => {
      this.GetAllChannelAssets(assetId);
      return response;
    });
  }

  async patchAsset(data: FormGroup, assetId: string): Promise<object> {
    const body = data.getRawValue();
    if (this.apiService.user && this.apiService.token) {
      const response = await this.httpClient
        .patch<Asset>(
          `${this.apiService.URI}${this.path}/${assetId}`,
          body,
          this.apiService.options
        )
        .toPromise();
      return response;
    } else {
      return null;
    }
  }

  public getAssetIndexById(assetId: string) {
    return this._assets?.findIndex((asset) => asset._id === assetId);
  }

  // Posts
}

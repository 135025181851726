<div class="main-right">
  <app-user-panel></app-user-panel>
  <app-home-sidebar> </app-home-sidebar>
  <div>
    <router-outlet></router-outlet>
    <!--<app-friend-list></app-friend-list>
      <app-chat-channel
        *ngIf="selectedChannelId"
        [channelId]="selectedChannelId"
      ></app-chat-channel>-->
  </div>
</div>
<!--<router-outlet name="main-right"></router-outlet>-->

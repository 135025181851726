import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// TODO think of a better way to share tab states
type AssetTabOptions = 'thread' | 'board' | 'files';
const tabItems = new Set<AssetTabOptions>(['thread', 'board', 'files']);

@Component({
  selector: 'app-asset-tab',
  templateUrl: './asset-tab.component.html',
  styleUrls: ['./asset-tab.component.scss'],
})
export class AssetTabComponent implements OnInit {
  // TODO implement listener
  // @Input() selectedTabListener = new EventEmitter<string>();

  // TODO implement init value
  @Input()
  selectedTab = '';

  @Output()
  selectedTabState = new EventEmitter<string>();

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.url.subscribe((urlSegment) => {
      const matchedPath = urlSegment
        .map(({ path }) => path)
        .filter((pathItem) => tabItems.has(pathItem as AssetTabOptions))?.[0];
    });
  }

  ngOnInit(): void {}

  onTabSelected(tab: string) {
    if (this.selectedTab !== tab) {
      this.selectedTab = tab;
      this.selectedTabState.emit(tab);
    }
  }
}

<div class="fullscreen" id="fullscreen" [class.fadein]="visible" [class.fadeout]="!visible" (click)="closeWindow($event)">
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div class="popup-box" [class.scalein]="visible" [class.scaleout]="!visible">
        <form [formGroup]="modifyCategoryForm" (ngSubmit)="onSubmit()">
          <h1>Modify Category</h1>
          <div class="form-group">
            <label for="email">Category Name
              <input type="text" name="name" (keyup)="updateFormValidation()" formControlName="name" />
            </label>
          </div>
          <button class="btn btn-block" type="submit" name="submitButton" formControlName="submitButton" ngDefaultControl>
            <div class="btnText">Update</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

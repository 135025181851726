import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { LoginComponent } from './screens/login/login.component';
import { RegisterComponent } from './screens/register/register.component';
import { ComingSoonComponent } from './screens/coming-soon/coming-soon.component';
import { ForgotPasswordComponent } from './screens/forgot-password/forgot-password.component';
import { MainComponent } from './screens/main/main.component';
import { ChatChannelComponent } from './screens/channel-screens/chat-channel/chat-channel.component';
import { HomeComponent } from './home/home.component';
import { ServerComponent } from './server/server.component';
import { ArtWallChannelComponent } from './screens/channel-screens/art-wall-channel/art-wall-channel.component';
import { AssetTabComponent } from './screens/headers/asset-tab/asset-tab.component';
import { ArtMainComponent } from './art-main/art-main.component';
import { AssetMainComponent } from './asset-main/asset-main.component';
import { FriendListComponent } from './screens/channel-screens/friend-list/friend-list.component';
import { ArtFilesComponent } from './screens/channel-screens/art-files/art-files.component';
import { ProjectMainComponent } from './layouts/project-main/project-main.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  {
    path: 'm',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
        children: [
          { path: 'chat/:chatId', component: ChatChannelComponent },
          { path: '', component: FriendListComponent },
        ],
      },
      {
        path: 'server/:serverId',
        component: ServerComponent,
        children: [
          {
            path: 'project/:projectId',
            component: ProjectMainComponent,
            children: [
              { path: 'chat/:chatId', component: ChatChannelComponent },
              {
                path: 'art-wall/:wallId',
                component: ArtMainComponent,
                children: [
                  { path: 'assets', component: ArtWallChannelComponent },
                  {
                    path: 'asset/:assetId/thread',
                    component: AssetMainComponent,
                  },
                  {
                    path: 'asset/:assetId/board',
                    component: AssetMainComponent,
                  },
                  {
                    path: 'asset/:assetId/files',
                    component: AssetMainComponent,
                  },
                  {
                    path: 'asset/:assetId',
                    redirectTo: 'asset/:assetId/thread',
                    pathMatch: 'full',
                  },
                  { path: '', redirectTo: 'assets', pathMatch: 'full' },
                ],
              },
            ],
          },
          {
            path: 'category/:categoryId',
            component: ProjectMainComponent,
            children: [
              { path: 'chat/:chatId', component: ChatChannelComponent },
            ],
          },
        ],
      },
      // {path: "server", component: ServerComponent},
      { path: 'testing', component: ComingSoonComponent },
    ],
  },
  { path: '', redirectTo: 'm', pathMatch: 'full' },
  // {path: '', pathMatch: 'full', component: LoginComponent},
  { path: '**', pathMatch: 'full', component: ComingSoonComponent },
  //Main application
  // { path: 'm', component: MainComponent },
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  imports: [RouterModule.forRoot(routes)], //, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[focusOnCreate]'
})
export class FocusOnCreateDirective {

  constructor(public element: ElementRef) {
    this.element.nativeElement.focus();
   }



}

import { Component, Input, OnInit } from '@angular/core';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { ClipboardService } from 'ngx-clipboard';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { Subject } from 'rxjs';
import { DialogType } from 'src/app/screens/popups/dialog/dialog.component';
import { PostsService } from 'src/app/services/api/assets/posts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServersService } from 'src/app/services/api/servers.service';
import { User } from 'src/app/models/user.model';
import { Server } from 'src/app/models/server.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Asset } from 'src/app/models/asset.model';
import { ChannelParentIdType } from 'src/app/enums/channel-Parent-Id-Type.enum';
import { ProjectsService } from 'src/app/services/api/projects/projects.service';
import { ChannelService } from 'src/app/services/api/channel.service';

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
})
export class ContextmenuComponent implements OnInit {
  x = 0;
  y = 0;
  visible = false;
  _subscription: any;
  eContextTypes = ContextTypes;
  type: ContextTypes = ContextTypes.hidden;

  data;
  user: User;
  servers: Server[];
  eChannelParentIdType = ChannelParentIdType;

  snackbarDuration = 2000;

  constructor(
    private contextMenu: ContextMenuService,
    private clipboardApi: ClipboardService,
    private apiService: ApiService,
    private popupControllerService: PopupControllerService,
    private _snackBar: MatSnackBar,
    private postsService: PostsService,
    private serversService: ServersService,
    private assetsService: AssetsService,
    private projectsService: ProjectsService,
    private channelService: ChannelService
  ) {
    this.apiService.user.subscribe((user) => {
      this.user = user;
      this.serversService.servers.subscribe((servers) => {
        this.servers = servers;
      });
      //this.serversService.getUsersOwnedServers();
    });
    //this.apiService.GetCurrentUser();
  }

  ngOnInit(): void {
    debug('running Conext Menu Component');

    this._subscription = this.contextMenu.dialog.subscribe(
      (value: ContextMenuData) => {
        this.visible = value.isDisplayed;
        if (value.type) {
          this.x = value.x;
          this.y = value.y - 30;
          this.type = value.type;
          this.data = value.data;

          switch (
            value.type // build menus
          ) {
            case ContextTypes.friendList:
            // Build server invite list
          }
        } else {
        }
        // debug('displaying menu');
      }
    );
  }

  copyText(text) {
    this.clipboardApi.copy(text);
    // this._snackBar.open('ID Copied','CLOSE', {duration: this.snackbarDuration});
    this.openSnackBar('ID Copied', null, 'mat-accent');
  }

  invitePeople(server) {
    console.log('context-menu - invitePeople() - opening invite people popup');
    this.popupControllerService.openInvitePeoplePopup(server);
  }

  sendFriendRequest(username) {
    debug('send freind request to: ' + username);
    this.apiService
      .sendFriendRequest(username)
      .then((response) => {
        this.apiService.GetCurrentUser();
        this.openSnackBar(
          'Sent friend request to ' + username,
          null,
          'mat-accent'
        );
      })
      .catch((e) => {
        this.openSnackBar('Friend Request Error: ' + e, null, 'mat-warn');
      });
  }

  acceptFreindRequest(friendUsername) {
    this.apiService
      .acceptFriendRequest(friendUsername)
      .then((response) => {
        this.apiService.GetCurrentUser();
        this.openSnackBar(
          'Accepted friend request from ' + friendUsername,
          null,
          'mat-accent'
        );
      })
      .catch((e) => {
        this.openSnackBar(
          'Accept Friend Request Error: ' + e,
          null,
          'mat-warn'
        );
      });
  }

  removeFriend(username) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.apiService
          .removeFriendRequest(username)
          .then((response) => {
            this.apiService.GetCurrentUser();
            this.openSnackBar(
              'Removed ' + username + ' as a friend',
              null,
              'mat-accent'
            );
          })
          .catch((e) => {
            this.openSnackBar('freind removal error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      'REMOVE FRIEND',
      'Are you sure you want to remove ' + username + ' as your friend?',
      confirmationEvent
    );
  }

  removeSentRequest(username) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.apiService
          .removeSentRequest(username)
          .then((response) => {
            this.apiService.GetCurrentUser();
            this.openSnackBar(
              'Removed sent request to ' + username,
              null,
              'mat-accent'
            );
          })
          .catch((e) => {
            this.openSnackBar(
              'Removing friend request Error: ' + e,
              null,
              'mat-warn'
            );
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      'REMOVE SENT REQUEST',
      'Are you sure you want to unsend the friend request you sent to ' +
        username +
        '?',
      confirmationEvent
    );
  }

  blockUser(username) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.apiService
          .blockUser(username)
          .then((response) => {
            this.apiService.GetCurrentUser();
            this.openSnackBar('Blocked user ' + username, null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Blocking user Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      'BLOCK USER',
      'Are you sure you want to block user ' + username + '?',
      confirmationEvent
    );
  }

  unblockUser(username) {
    this.apiService
      .unblockUser(username)
      .then((response) => {
        this.apiService.GetCurrentUser();
        this.openSnackBar('Unblocked user ' + username, null, 'mat-accent');
      })
      .catch((e) => {
        this.openSnackBar('Unblocking user Error: ' + e, null, 'mat-warn');
      });
  }

  addFriendsToPrivateChannel(channel) {
    this.popupControllerService.openNewDmPopup(channel);
  }

  leaveChat(channel) {
    console.log('context-menu - leaveChat() - leaving chat');
    console.log(channel._id);
    this.channelService.LeavePrivateChannel(channel._id);
  }

  createCategory(serverId, owner) {
    this.popupControllerService.openNewCategoryPopup(serverId, owner);
  }

  createChannel(serverId, id, channelParentIdType: ChannelParentIdType, owner) {
    console.log('context-menu - createChannel() - opening new channel popup');
    console.log('serverId: ' + serverId);
    console.log('id: ' + id);
    console.log('channelParentIdType: ' + channelParentIdType);
    console.log('owner: ' + owner);
    this.popupControllerService.openNewChannelPopup(
      serverId,
      id,
      channelParentIdType,
      owner
    );
  }

  editPost(post: object, assetId: string) {
    this.popupControllerService.openEditPostPopup(post, assetId);
  }

  removePost(post: any, assetId: string) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.postsService
          .RemovePost(post._id, assetId)
          .then((response) => {
            this.openSnackBar('Post Removed', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Post Removal Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      'REMOVE POST',
      'Are you sure you want to delete this post?',
      confirmationEvent
    );
  }

  inviteToSerer(serverId: string, username: string) {
    this.serversService
      .inviteToServer(username, serverId)
      .then((response) => {
        this.openSnackBar(
          'Invited ' + username + ' to server',
          null,
          'mat-accent'
        );
      })
      .catch((e) => {
        this.openSnackBar('Invitation Error: ' + e, null, 'mat-warn');
      });
  }

  kickFromServer(serverId: string, username: string) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.serversService
          .leaveServer(serverId, username)
          .then((response) => {
            this.openSnackBar(
              'Kicked ' + username + ' from server',
              null,
              'mat-accent'
            );
          })
          .catch((e) => {
            this.openSnackBar('Kicking Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      'KICK USER FROM SERVER',
      'Are you sure you want to kick user ' + username + ' from the server?',
      confirmationEvent
    );
  }

  leaveServer(serverId: string) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.serversService
          .leaveServer(serverId)
          .then((response) => {
            this.serversService.getUsersOwnedServers();
            this.openSnackBar('Left Server', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Leaving Server Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      'LEAVE SERVER',
      'Are you sure you want to leave the server?',
      confirmationEvent
    );
  }

  deleteServer(serverId: string) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.serversService
          .deleteServer(serverId)
          .then((response) => {
            // this.serversService.getUsersOwnedServers();
            this.openSnackBar('Deleted Server', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Deleting Server Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE,
      'DELETE SERVER',
      'Are you sure you want to delete the server? This action cannot be undone',
      confirmationEvent
    );
  }

  deleteChannel(serverId: string, channelId: string) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.serversService
          .deleteChannel(serverId, channelId)
          .then((response) => {
            // this.serversService.getUsersOwnedServers();
            this.openSnackBar('Deleted Channel', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Deleting Channel Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE,
      'DELETE CHANNEL',
      'Are you sure you want to delete the channel? This action cannot be undone and will DELETE ALL ASSETS if this is an art wall',
      confirmationEvent
    );
  }

  deleteAsset(asset: Asset) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.assetsService
          .deleteAsset(asset._id)
          .then((response) => {
            this.openSnackBar('Deleted Channel', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Deleting Channel Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE,
      'DELETE ASSET',
      'Are you sure you want to delete this asset? This action cannot be undone and will DELETE the asset PERMINENTLY',
      confirmationEvent
    );
  }

  deleteCategory(serverId, categoryId) {
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.serversService
          .deleteCategory(serverId, categoryId)
          .then((response) => {
            this.openSnackBar('Deleted Channel', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Deleting Channel Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE,
      'DELETE Category',
      'Are you sure you want to delete this category? This action will delete all channels and assets under this category PERMINENTLY and cannot be undone',
      confirmationEvent
    );
  }

  createProject(serverId, owner) {
    this.popupControllerService.openNewProjectPopup(serverId, owner);
  }

  modifyProject(serverId, project) {
    this.popupControllerService.openModifyProjectPopup(serverId, project);
  }

  deleteProject(projectId) {
    console.log('delete project contextmenu - projectId:' + projectId);
    const confirmationEvent: Subject<boolean> = new Subject<boolean>();
    confirmationEvent.subscribe(async (success: Boolean) => {
      if (success) {
        this.projectsService
          .RemoveProject(projectId)
          .then((response) => {
            this.openSnackBar('Deleted Project', null, 'mat-accent');
          })
          .catch((e) => {
            this.openSnackBar('Deleting Project Error: ' + e, null, 'mat-warn');
          });
      }
    });
    this.popupControllerService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE,
      'DELETE PROJECT',
      'Are you sure you want to delete this project? This action will delete all channels and assets under this project PERMINENTLY and cannot be undone',
      confirmationEvent
    );
  }

  modifyAsset(asset) {
    this.popupControllerService.openModifyAsset(asset);
  }

  modifyServer(server) {
    this.popupControllerService.openModifyServer(server);
  }

  modifyCategory(serverId, category) {
    this.popupControllerService.openModifyCategory(serverId, category);
  }

  modifyChannel(serverId, categoryId, channel) {
    this.popupControllerService.openModifyChannel(
      serverId,
      categoryId,
      channel
    );
  }

  onAddServerClick() {
    this.popupControllerService.openNewServerPopup();
  }
  disableContextMenu() {
    this.visible = false;
  }

  openSnackBar(message: string, action: string, color: string) {
    this._snackBar.open(message, action, {
      duration: this.snackbarDuration,
      verticalPosition: 'bottom',
      panelClass: ['center-snackbar', color],
    });
  }
}

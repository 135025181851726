export enum ToolMode {
  SELECTION_TOOL = 'selection-tool', // Added
  TEXT_TOOL = 'text-tool', // Added
  POLYGON_TOOL = 'polygon-tool', // Added
  ARROW_TOOL = 'arrow-tool', //
  LINE_TOOL = 'line-tool', //
  FREEHAND_TOOL = 'freehard-tool', //
  GROUP_TOOL = 'group-tool', //
  RECT_TOOL = 'rect-tool', //
  CIRCLE_TOOL = 'circle-tool', //
  ERASER = 'eraser', //
  IMAGE_TOOL = 'image_tool',
  IMAGE = 'image',
  TEXT = 'text',
}

export default ToolMode;

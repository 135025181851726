<div class="guilds">
  <div
    [class.add-guild-selected]="selectedServerId == 'home'"
    class="add-guild-home"
    placement="right"
    ngbTooltip="Home"
    (click)="changeServer('home')"
  >
    <!--<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
      <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
    </svg>-->

    <svg
      id="Layer_1"
      data-name="Layer 1"
      width="25"
      height="25"
      fill="currentColor"
      class="bi bi-house"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 158 136"
    >
      <path
        d="M97.22,7.93V41.64a6.2,6.2,0,0,0,2.46,4.94L137.53,75.2a6.17,6.17,0,0,1-.64,10.29l-41.3,23.92a6.25,6.25,0,0,1-9.39-5.36V6.21A6.24,6.24,0,0,0,76.82.85L3.1,43.53A6.19,6.19,0,0,0,0,48.89V91.55a6.2,6.2,0,0,0,2.46,4.94L50.76,133a6.24,6.24,0,0,0,10-4.93V94.36a6.2,6.2,0,0,0-2.46-4.94L20.47,60.8a6.17,6.17,0,0,1,.64-10.29l41.3-23.92A6.25,6.25,0,0,1,71.8,32v97.84a6.24,6.24,0,0,0,9.38,5.36L154.9,92.47a6.19,6.19,0,0,0,3.1-5.36V44.45a6.2,6.2,0,0,0-2.46-4.94L107.24,3A6.24,6.24,0,0,0,97.22,7.93Z"
      />
    </svg>
  </div>
  <hr />
  <div class="scroll">
    <div
      *ngFor="let server of servers"
      class="add-guild spin"
      [class.add-guild-selected]="selectedServerId == server._id"
      [class.add-guild-invitation]="
        server.isInvitedOnly && selectedServerId != server._id
      "
      placement="right"
      ngbTooltip="{{ server.name }}"
      (contextmenu)="
        onrightClickServer($event, eContextTypes.server, server._id)
      "
      (click)="changeServer(server._id)"
    >
      <div *ngIf="!server.thumbnail" class="bi">
        <div class="thumbnail-img with-bg spin">
          {{ server.initial | uppercase }}
        </div>

        <svg *ngIf="server.isInvitedOnly" class="invited" viewBox="0 0 16 16">
          <path
            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"
          />
          <path
            d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"
          />
        </svg>
        <!-- topper goes here -->
      </div>

      <div *ngIf="server.thumbnail" class="thumbnail-container spin">
        <img
          [src]="'https://artdominion.imgix.net/' + server.thumbnail"
          class="thumbnail-img"
        />
      </div>
    </div>

    <div
      class="add-guild-home"
      placement="right"
      ngbTooltip="Add a Server"
      (contextmenu)="onrightClickAddServer($event, eContextTypes.AddServer)"
      (click)="onAddServerClick()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="bi bi-plus"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
      </svg>
    </div>
  </div>
</div>

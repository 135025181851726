<div class="sub-main">
  <app-channel-header
    [selectedServer]="selectedServer"
    [selectedChannel]="selectedChannel"
    (selectedAssetTabState)="changeAssetTab($event)"
    [selectedAssetTab]="selectedAssetTab"
  ></app-channel-header>
  <div class="chat scroll">
    <div>
      <div class="friend-list">
        <!--
        <div class="add-container" (click)="newAsset()">
          <div class="add-container-border">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
            <div class="overlay">
              <div class="text">Add Asset</div>
            </div>
          </div>
        </div>
-->
        <!--
        <div class="container" *ngFor="let asset of assets" (click)="openAsset(asset._id)">
          <img src="assets/images/assets/test.png" class="image" />
          <div class="footer"><div class="text">{{ asset.name }}</div></div>
        </div>
-->

        <div
          class="asset-container"
          *ngFor="let asset of assets"
          (click)="openAsset(asset._id)"
          (contextmenu)="openContextMenu(asset, $event)"
        >
          <img
            *ngIf="asset && asset.thumbnail"
            src="https://artdominion.imgix.net/{{ asset.thumbnail }}"
            class="image"
          />

          <div class="overlay">
            <img
              *ngIf="asset && asset.ownerUser && asset.ownerUser.avatar"
              class="logo"
              src="https://artdominion.imgix.net/{{ asset.ownerUser.avatar }}"
            />
            <div
              *ngIf="!asset || !asset.ownerUser || !asset.ownerUser.avatar"
              class="no-avatar"
            >
              <div *ngIf="asset && asset.ownerUser" class="initials">
                {{ asset.ownerUser.username.slice(0, 2) }}
              </div>
            </div>
            <!--
              <div *ngIf="!asset || !asset.thumbnail" class="no-avatar">
                <div *ngIf="asset" class="initials">{{asset.owner.slice(0,2)}}</div>
              </div>
              -->
            <div *ngIf="asset && asset.name" class="text">
              {{ asset.name }}
            </div>
            <div
              *ngIf="asset && asset.ownerUser && asset.ownerUser.username"
              class="user"
            >
              {{ asset.ownerUser.username }}
            </div>
          </div>
        </div>

        <div class="add-container" (click)="newAsset()">
          <div class="add-container-border">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-plus-lg"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"
              />
            </svg>
            <div class="overlay">
              <div class="text">Create Asset</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-channel-side-panel
    [selectedServer]="selectedServer"
    [selectedChannel]="selectedChannel"
  ></app-channel-side-panel>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Server } from 'src/app/models/server.model';
import { Channel } from 'src/app/models/channel.model';
import { ServersService } from 'src/app/services/api/servers.service';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { ApiService } from 'src/app/services/api/api.service';
import { User } from 'src/app/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { GlobalLocationResolverService } from '../../../services/global-location-resolver.service';
import { ProjectsService } from 'src/app/services/api/projects/projects.service';
import { Project } from 'src/app/models/project.model';
import { ChannelParentIdType } from 'src/app/enums/channel-Parent-Id-Type.enum';

@Component({
  selector: 'app-server-sidebar',
  templateUrl: './server-sidebar.component.html',
  styleUrls: ['./server-sidebar.component.scss'],
})
export class ServerSidebarComponent implements OnInit {
  @Input()
  selectedServerId;

  @Input()
  selectedAssetIndex: string;

  //@Input()
  selectedChannel;

  @Input()
  selectedAssetTab;

  @Output()
  selectedChannelState = new EventEmitter<object>();

  selectedServer: Server;
  user: User;

  selectedProjectId: string;
  selectedCategoryId: string;

  eContextTypes = ContextTypes;
  eChannelParentIdType = ChannelParentIdType;
  servers: Server[];

  constructor(
    private serverService: ServersService,
    private contextMenuService: ContextMenuService,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private popupControllerService: PopupControllerService,
    private globalLocationResolver: GlobalLocationResolverService,
    private projectsService: ProjectsService
  ) {
    // TODO this could lead to a race condition if this page loads after HTTP requests finish. store the values as state, then retrieve
    this.serverService.servers.subscribe((servers: Server[]) => {
      console.log('server-sidebar - constructor - servers callback: ');
      this.servers = servers;
      this.updateServers();
    });
    this.apiService.user.subscribe((user: User) => {
      this.user = user;
    });
    this.projectsService.projects.subscribe((projects) => {
      if (projects && this.selectedServer) {
        //TODO: clean this so it only updates what has changed
        this.selectedServer.projects = projects;
      }
    });
    this.apiService.updateDirtyUser();
    this.updateServerList();

    this.globalLocationResolver.activeServerIdSub.subscribe((serverId) => {
      console.log('server-sidebar - constructor - active server callback: ');
      this.selectedServerId = serverId;
    });

    this.globalLocationResolver.activeProjectIdSub.subscribe((projectId) => {
      this.selectedProjectId = projectId;
    });

    this.globalLocationResolver.activeCategoryIdSub.subscribe((categoryId) => {
      this.selectedCategoryId = categoryId;
    });

    this.globalLocationResolver.activeAssetTabSub.subscribe((selected) => {
      this.selectedAssetTab = selected;
    });

    this.globalLocationResolver.activeChannelIdSub.subscribe((channelId) => {
      console.log('server-sidebar - constructor - channelId: ');
      console.log(channelId);
      this.selectedChannel = this.serverService.getChannel(channelId);
      console.log('Selected Channel');
      console.log(this.selectedChannel);
      this.selectedCategoryId = this.selectedChannel?.categoryId || null;
      this.selectedProjectId = this.selectedChannel?.projectId || null;
      this.updateServers();
    });
  }

  async ngOnInit(): Promise<void> {}

  ngOnChanges(): void {
    console.log('server-sidebar - ngOnChanges()');
    console.log('selected channel');
    console.log(this.selectedChannel);
    this.updateServers();
  }

  updateServers() {
    if (this.servers) {
      console.log('server-sidebar - updateServers() - selectedServerId: ');

      console.log('selected channel');
      console.log(this.selectedChannel);

      this.selectedServerId = this.globalLocationResolver.getActiveServerId();

      console.log('Selected Server: ' + this.selectedServerId);

      this.servers.forEach((server: Server) => {
        if (this.selectedServerId == server._id) {
          this.selectedServer = server;
          return;
        }
      });
    }
  }

  onrightClick(
    event,
    type: ContextTypes,
    serverId: string,
    channelParentIdType: ChannelParentIdType,
    object,
    owner: string = null,
    channel = null
  ) {
    const isOwner = this.selectedServer.owner == this.user._id;
    let data;
    if (channelParentIdType == ChannelParentIdType.PROJECT) {
      data = {
        channel,
        isOwner,
        serverId: serverId,
        project: object,
        owner: owner,
      };
    } else {
      data = {
        channel,
        isOwner,
        serverId: serverId,
        category: object,
        owner: owner,
      };
    }
    debug('server-sidebar - data: ' + JSON.stringify(data));
    this.contextMenuService.show(event.clientX, event.clientY, type, data);
  }

  onrightClickBlank(
    event,
    type: ContextTypes,
    serverId: string,
    owner: string
  ) {
    const data = {
      serverId: serverId,
      owner: owner,
    };
    debug('server-sidebar - data: ' + JSON.stringify(data));
    this.contextMenuService.show(event.clientX, event.clientY, type, data);
  }

  selectChannel(channel: Channel) {
    debug('server-sidebar: selectChannel ');
    this.globalLocationResolver.setActiveChannelId(
      'server-sidebar-component - selectChannel',
      channel._id
    );
    this.selectedProjectId = channel.projectId || null;
    this.selectedCategoryId = channel.categoryId || null;
    this.selectedChannel = channel;
    let channelGroupType;
    let channelGroupId;

    if (channel.projectId) {
      channelGroupType = 'project';
      channelGroupId = channel.projectId;
      this.globalLocationResolver.setActiveCategoryId(
        'server-sidebar-component - selectChannel',
        null
      );
      this.globalLocationResolver.setActiveProjectId(
        'server-sidebar-component - selectChannel',
        channel.projectId
      );
    } else if (channel.categoryId) {
      channelGroupType = 'category';
      channelGroupId = channel.categoryId;
      this.globalLocationResolver.setActiveProjectId(
        'server-sidebar-component - selectChannel',
        null
      );
      this.globalLocationResolver.setActiveCategoryId(
        'server-sidebar-component - selectChannel',
        channel.categoryId
      );
    }

    if (channel.channelType == 'art-wall') {
      this.globalLocationResolver.setActiveAssetTab(
        'server-sidebar-component - selectChannel',
        'art-wall'
      );
      console.log('navigating art wall');
      this.router.navigate(
        [channelGroupType, channelGroupId, 'art-wall', channel._id, 'assets'],
        {
          relativeTo: this.activatedRoute,
        }
      );
      //['project', channel.projectId, 'art-wall', channel._id, 'assets'],
      //{
      //  relativeTo: this.activatedRoute,
      //}
    } else {
      //assume it's a chat window
      this.globalLocationResolver.setActiveAssetTab(
        'server-sidebar-component - selectChannel',
        'chat'
      );
      console.log('navigating chat');
      this.router.navigate(
        [channelGroupType, channelGroupId, 'chat', channel._id],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  AddChannel(
    id: string = null,
    idType: ChannelParentIdType = ChannelParentIdType.CATEGORY
  ) {
    this.popupControllerService.openNewChannelPopup(
      this.selectedServer._id,
      id,
      idType,
      this.user._id
    );
  }

  AddProject() {
    this.popupControllerService.openNewProjectPopup(
      this.selectedServer._id,
      this.user._id
    );
  }

  updateServerList() {
    this.serverService.getUsersOwnedServers();
  }

  getProjects() {
    this.projectsService.RefreshProjects();
  }

  setActiveProject(projectId: string) {
    if (this.globalLocationResolver.getActiveProjectId() != projectId) {
      this.selectedProjectId = projectId;
      this.globalLocationResolver.setActiveProjectId(
        'server-sidebar-component - setActiveProject',
        projectId
      );
      // set top most project channel as selected
      const project = this.selectedServer.projects.find(
        (project) => project._id == projectId
      );
      if (project) {
        this.selectChannel(project.channels[0]);
      }
    }
  }

  setActiveCategory(categoryId: string) {
    if (this.globalLocationResolver.getActiveCategoryId() != categoryId) {
      this.selectedCategoryId = categoryId;
      this.globalLocationResolver.setActiveCategoryId(
        'server-sidebar - setActiveCategory',
        categoryId
      );
      // set top most category channel as selected
      const category = this.selectedServer.categories.find(
        (category) => category._id == categoryId
      );
      if (category) {
        this.selectChannel(category.channels[0]);
      }
    }
  }

  onAddAsset() {}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { User } from '../../models/user.model';
import { Server } from '../../models/server.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authPath = '/auth';
  private usersPath = '/users';
  private friendPath = '/users/friend';
  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });
  private _options;
  private _token;
  private _user: User;

  // Public
  public get URI() {
    return environment.apiUrl;
  }

  public get options() {
    return this._options;
  }

  public get token() {
    if (!this._token) {
      //TODO: kick and force re-login
    }
    return this._token;
  }

  // Events
  public user: Subject<User> = new Subject<User>();

  constructor(private httpClient: HttpClient, private router: Router) {
    this._options = { headers: this.httpHeaders };
    debug('api service loaded');
    this._user = JSON.parse(sessionStorage.getItem('user'));
    this._token = localStorage.getItem('token');
    debug(localStorage.getItem('token'));
    // debug('_token: ' + this._token);
    // debug(this user);
    if (this._token) {
      this.setAuthenticationHeaders();
      this.updateDirtyUser();
    } else {
      // this.router.navigate(['/login']);
      // we don't have a _token or user
      // debug('we dont have a _token or user');
    }

    // hack to poll update user
    setInterval(() => {
      if (this._token) {
        this.updateDirtyUser();
      }
    }, 30 * 1000);
  }

  private setAuthenticationHeaders() {
    debug('Auth Token: ' + this._token);
    if (this._token) {
      debug('setting auth header');
      this.httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: 'Bearer ' + this._token,
      });
    } else {
      debug('setting auth header');
      this.httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      });
    }

    this._options = { headers: this.httpHeaders };
  }

  async login(email: string, password: string): Promise<object> {
    const body = { email, password };
    const post = await this.httpClient
      .post(`${environment.apiUrl}${this.authPath}/login`, body, this._options)
      .toPromise();
    debug('login response:');
    debug(post);
    sessionStorage.setItem('user', JSON.stringify(post['user']));
    localStorage.setItem('token', post['token'].token);
    this._user = post['user'];
    this._token = post['token'].token;
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: 'Bearer ' + this._token,
    });
    this._options = { headers: this.httpHeaders };
    this.user.next(this._user);
    return post;
  }

  async register(data: FormGroup): Promise<object> {
    const body = data.getRawValue();

    body.dateOfBirth =
      body.dobYear + '-' + body.dobMonth + '-' + body.dobDay + 'T00:00:00.000Z';
    debug(body);

    return this.httpClient
      .post(
        `${environment.apiUrl}${this.authPath}/register`,
        body,
        this._options
      )
      .toPromise();
  }

  async resetPassword(data: FormGroup): Promise<object> {
    const body = data.getRawValue();
    debug(body);

    return this.httpClient
      .post(
        `${environment.apiUrl}${this.authPath}/resetPassword`,
        body,
        this._options
      )
      .toPromise();
  }

  async changePassword(data: FormGroup): Promise<object> {
    const body = data.getRawValue();
    debug(body);

    return this.httpClient
      .post(
        `${environment.apiUrl}${this.authPath}/changePassword`,
        body,
        this._options
      )
      .toPromise();
  }

  async verifyEmail(token: string): Promise<object> {
    const body = { token };
    return this.httpClient
      .post(`${environment.apiUrl}${this.authPath}/verify`, body, this._options)
      .toPromise();
  }

  async resendVerificationEmail(): Promise<object> {
    const body = {};
    return this.httpClient
      .post(
        `${environment.apiUrl}${this.authPath}/resendVerification`,
        body,
        this._options
      )
      .toPromise();
  }

  async logout(): Promise<boolean> {
    this._user = null;
    this._token = null;
    sessionStorage.removeItem('user');
    localStorage.removeItem('token');
    return true;
  }

  setUser(user, token): void {
    this._user = user;
    this._token = token;
    sessionStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    this.updateDirtyUser();
  }

  async GetCurrentUser(forceDirty = false): Promise<User> {
    debug('USER:');
    debug(this._user);

    if (forceDirty || (!this._user && this._token)) {
      await this.updateDirtyUser();
    }

    if (!this._user && !this._token) {
      const url = this.router.url.split('?')[0];
      console.log(url);
      if (
        url !== 'login' &&
        url !== 'register' &&
        url !== 'forgot-password' &&
        url !== 'coming-soon'
      ) {
        // this.router.navigate(['/login']);
      } else {
        return null; //on the login page so nothing should happen
      }
    }
    this.user.next(this._user);
    return this._user;
  }

  updateDirtyUser() {
    if (this._token) {
      debug('updating dirty user');
      debug(this._options);
      this.httpClient
        .get<User>(
          `${environment.apiUrl}${this.usersPath}/currentUser`,
          this._options
        )
        .toPromise()
        .then((user) => {
          const parsedUser = JSON.stringify(user);
          sessionStorage.setItem('user', parsedUser);
          this._user = JSON.parse(parsedUser) as User;
          this._user.avatarUrl = this._user.avatar
            ? 'https://artdominion.imgix.net/' + this._user.avatar
            : null;
          debug('set new avatar url as: ' + this._user.avatarUrl);
          this.user.next(this._user);
        })
        .catch((e) => {
          error(error);
          if (e.statusText == 'Unauthorized') {
            this._user = null;
            this._token = null;
            this.router.navigate(['/login']);
          }
        });
    }
  }

  async getUsersShorts(usernames: string[]): Promise<any> {
    console.log('getUsersShorts');
    console.log(usernames);
    //TODO: find out what methods are calling this and limit it.
    const body = {
      usernames: usernames,
    };
    const users = await this.httpClient
      .post<User>(
        `${environment.apiUrl}${this.usersPath}/userShorts`,
        body,
        this._options
      )
      .toPromise();
    return users;
  }

  async sendFriendRequest(usernameOrEmail: string): Promise<boolean> {
    if (this._token) {
      const body = { usernameOrEmail };
      const user = await this.httpClient
        .post<User>(
          `${environment.apiUrl}${this.friendPath}`,
          body,
          this._options
        )
        .toPromise();

      // once finished the returned user should be updated and the user update event should be fired
      await this.updateDirtyUser();
      return true;
    }
    return false;
  }

  // accept friend request
  async acceptFriendRequest(username: string): Promise<boolean> {
    if (this._token) {
      const body = { username };
      const user = await this.httpClient
        .post<User>(
          `${environment.apiUrl}${this.friendPath}/accept`,
          body,
          this._options
        )
        .toPromise();

      // once finished the returned user should be updated and the user update event should be fired
      await this.updateDirtyUser();
      return true;
    }
    return false;
  }

  async removeFriendRequest(username: string): Promise<boolean> {
    if (this._token) {
      const body = { username };
      const user = await this.httpClient
        .post<User>(
          `${environment.apiUrl}${this.friendPath}/remove`,
          body,
          this._options
        )
        .toPromise();

      // once finished the returned user should be updated and the user update event should be fired
      await this.updateDirtyUser();
      return true;
    }
    return false;
  }

  async removeSentRequest(username: string): Promise<boolean> {
    if (this._token) {
      const body = { username };
      const user = await this.httpClient
        .post<User>(
          `${environment.apiUrl}${this.friendPath}/removeRequest`,
          body,
          this._options
        )
        .toPromise();

      // once finished the returned user should be updated and the user update event should be fired
      await this.updateDirtyUser();
      return true;
    }
    return false;
  }

  async blockUser(username: string): Promise<boolean> {
    if (this._token) {
      const body = { username };
      const user = await this.httpClient
        .post<User>(
          `${environment.apiUrl}${this.friendPath}/block`,
          body,
          this._options
        )
        .toPromise();

      // once finished the returned user should be updated and the user update event should be fired
      await this.updateDirtyUser();
      return true;
    }
    return false;
  }

  async unblockUser(username: string): Promise<boolean> {
    if (this._token) {
      const body = { username };
      const user = await this.httpClient
        .post<User>(
          `${environment.apiUrl}${this.friendPath}/unblock`,
          body,
          this._options
        )
        .toPromise();

      // once finished the returned user should be updated and the user update event should be fired
      await this.updateDirtyUser();
      return true;
    }
    return false;
  }

  public getUser() {
    // TODO remove avatar when feature is available
    return {
      ...this._user,
      avatarUrl: 'assets/images/users/profile-pic.jpg',
    };
  }

  // Friends
  /*
    const user = await this.httpClient.get<User>(`${environment.apiUrl}${this.friendPath}/`, this._options).toPromise();
    const user = await this.httpClient.get<User>(`${environment.apiUrl}${this.friendPath}/:id/accept`, this._options).toPromise();
    const user = await this.httpClient.get<User>(`${environment.apiUrl}${this.friendPath}/:id/remove`, this._options).toPromise();
    const user = await this.httpClient.get<User>(`${environment.apiUrl}${this.friendPath}/:id/block`, this._options).toPromise();
    const user = await this.httpClient.get<User>(`${environment.apiUrl}${this.friendPath}/:id/unblock`, this._options).toPromise();
  */
  // Change to post variables and remove :id's
}

export {};

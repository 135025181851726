import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { User } from '../../models/user.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  tokenServerVerifyFailed = false;
  submitted = false;
  checkEmail = false;
  changePasswordForm = false;
  passwordMismatch = false;
  completed = false;
  verifyFailed = false;

  public passwordResetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    submitButton: new FormControl(''),
    captcha: new FormControl('', Validators.required),
  });

  public passwordChangeForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    passwordConf: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    token: new FormControl('', Validators.required),
    submitButton: new FormControl(''),
  });

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    console.log('forgot-password ngOnInit');
  }

  ngOnInit(): void {
    console.log('forgot-password ngOnInit');
    this.passwordResetForm.get('submitButton')?.disable();
    this.passwordChangeForm.get('submitButton')?.disable();

    this.route.queryParams.subscribe((params) => {
      if (params.token) {
        this.changePasswordForm = true;
        this.passwordChangeForm.get('token').setValue(params.token);
        debug(params.token);
      }
    });
  }

  onSubmit() {
    this.passwordResetForm.get('submitButton')?.disable();
    this.submitted = true;
    this.apiService.resetPassword(this.passwordResetForm).then((data) => {
      // check your email
      this.checkEmail = true;
    });
  }

  onSubmitChange() {
    this.passwordChangeForm.get('submitButton')?.disable();
    this.apiService
      .changePassword(this.passwordChangeForm)
      .then((data) => {
        console.log('forgot-password onSubmitChange data');
        console.log(data);
        if (data['email']) {
          debug('successfully changed password');
          debug('redirecting');
          this.completed = true;
          setTimeout(() => {
            //delay
            debug('redirecting');
            this.completed = true;
            this.router.navigate(['/login']);
          }, 2000);
        }
      })
      .catch((error) => {
        debug('Failed To Change Password');
        this.verifyFailed = true;
      });
  }

  async resolved(captchaResponse: string) {
    this.passwordResetForm.get('captcha').setValue(captchaResponse);
    debug(`Resolved captcha with response: ${captchaResponse}`);
    this.updateFormValidation();
  }

  async updateFormValidation() {
    if (this.passwordResetForm.valid) {
      this.passwordResetForm.get('submitButton')?.enable();
    } else {
      this.passwordResetForm.get('submitButton')?.disable();
    }
  }

  async updatePasswordChangeFormValidation() {
    if (this.passwordChangeForm.valid) {
      if (
        this.passwordChangeForm.get('password').value ===
        this.passwordChangeForm.get('passwordConf').value
      ) {
        this.passwordChangeForm.get('submitButton')?.enable();
        this.passwordMismatch = false;
      } else {
        this.passwordChangeForm.get('submitButton')?.disable();
        this.passwordMismatch = true;
      }
    } else {
      this.passwordChangeForm.get('submitButton')?.disable();
      this.passwordMismatch = true;
    }
  }
}

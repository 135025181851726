import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  HostListener,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
  AfterContentChecked,
} from '@angular/core';
import { now } from 'moment';
import { Server } from 'src/app/models/server.model';
import {
  ThreadPost /*ThreadChatComment*/,
} from 'src/app/models/thread-chat-message.model';
import Point from 'src/app/classes/point.model';
import ToolMode from 'src/app/enums/tool-mode.enum';
import ArtThreadMode from 'src/app/enums/art-thread-mode.enum';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api/api.service';
import PolygonElement from 'src/app/classes/svg/polygon-element.class';
import SvgElement from 'src/app/classes/svg/svg-element.class';
import { COLOR_LIST } from 'src/app/enums/color-list.enum';
import RectElement from 'src/app/classes/svg/rect-element.class';
import { FreehandElement } from 'src/app/classes/svg/freehand-element.class';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { Asset } from 'src/app/models/asset.model';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import {
  ActionEventType,
  ActionEvent,
  DrawActionValue,
  SelectActionValue,
} from '../../../classes/EventAction';
import {
  Message,
  MessageEditingTypes,
  MessageSyncStates,
} from '../../../models/message.model';
import * as moment from 'moment';
import { DrawingsService } from 'src/app/services/api/assets/drawings.service';
import { Subject, timer } from 'rxjs';
import * as _ from 'lodash';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { GlobalLocationResolverService } from 'src/app/services/global-location-resolver.service';
import { ServersService } from 'src/app/services/api/servers.service';
const timerEvent = timer(0, 30 * 1000); // 30 Seconds

export enum KEY_CODE {
  ESCAPE = 27,
  RETURN = 13,
  BACKSPACE = 8,
  DELETE = 46,
  SPACE = 32,
  TAB = 9,
}

@Component({
  selector: 'app-art-thread-channel',
  templateUrl: './art-thread-channel.component.html',
  styleUrls: ['./art-thread-channel.component.scss'],
})
export class ArtThreadChannelComponent implements OnInit {
  @Input()
  selectedServer: Server;

  @Input()
  selectedChannel;

  @Input()
  selectedAssetId: string;

  @Input()
  selectedAssetTab;

  @Output()
  selectedAssetTabState = new EventEmitter<string>();

  // All posts
  // posts: ThreadPost[] = [];
  asset: Asset;

  // selections
  selectedPost: ThreadPost; // post that is currently selected
  selectedCommentIndex: number; // Array index of the comment that is currently selected
  selectedComment: Message; // Whole object of the comment that is selected
  selectedElementIndex: number; // Array Index of the element currently selected
  heldElement: SvgElement;
  heldOffset: Point;
  newComment: Message;
  actionProcessing: boolean = false;

  replyTextAreaFocused = false;

  // mouse control
  mouseDown = false; // is the mouse currently down on the selected post image?
  mouseMoveTick = 0; // How many times has the mouse move event triggered while the mouse has been down
  mousePosition: Point = { x: 0, y: 0 }; // The current position of the mouse relative to the selected post image

  eArtThreadMode = ArtThreadMode;
  currentEditorMode = ArtThreadMode.VIEW_MODE;
  addingNewDrawing: boolean = false;

  //Editor Tool Mode
  eToolMode = ToolMode;
  currentToolMode: ToolMode = ToolMode.SELECTION_TOOL;
  selectionStart: Point = { x: 0, y: 0 };
  selectionEnd: Point = { x: 0, y: 0 };
  selectedColor: COLOR_LIST = COLOR_LIST.green;
  eColorList = COLOR_LIST;

  @ViewChild('chatbar')
  chatbar: ElementRef;

  @ViewChild('threadContainer')
  threadContainer: ElementRef;
  threadContainerChecked: boolean = false;

  chatText;
  editorChatTextError: boolean = false;
  chatbarFocused: boolean = false;
  focusedChatbar = null;
  user: User;

  AssetSubscription;
  eContextTypes = ContextTypes;

  timer;

  constructor(
    private apiService: ApiService,
    private popupControllerService: PopupControllerService,
    private assetService: AssetsService,
    private drawingService: DrawingsService,
    private contextMenuService: ContextMenuService,
    private globalLocationResolver: GlobalLocationResolverService,
    private serversService: ServersService
  ) {
    timerEvent.subscribe(() => {
      this.RefreshDrawings();
    });
    console.log('Art Thread Channel Constructor');
  }

  async ngOnInit(): Promise<void> {
    this.apiService.user.subscribe((user) => {
      this.user = user;
    });
    this.user = await this.apiService.GetCurrentUser();
    this.AssetSubscription = this.assetService.assets.subscribe((assets) => {
      console.log('Art Thread - Selected Asset ', this.selectedAssetId);

      console.log('Art Thread - Assets: ');
      console.log(assets);

      this.asset = assets?.filter(
        (value) => value._id == this.selectedAssetId
      )?.[0];
      if (this.asset && this.asset.posts.length > 0) {
        this.asset.posts[0].selected = true;
        this.selectedPost = this.asset.posts[0];
      }

      console.log('Art Thread - Selected Asset: ');
      console.log(this.asset);
    });
    this.assetService.RefreshAssets();

    this.globalLocationResolver.activeChannelIdSub.subscribe((channelId) => {
      this.selectedChannel = this.serversService.getChannel(channelId);
    });

    /*this.timer = setInterval(() => {
      // this.RefreshDrawings();
      console.log('---======== Refreshing Drawings ========---');
      console.log('Selected Channel: ' + this.selectedChannel._id);
      if (this.selectedChannel && this.selectedChannel._id) {
        this.assetService.GetAllChannelAssets(this.selectedChannel._id);
      }
    }, 10000);*/
  }

  changeAssetTab(tab: string) {
    this.selectedAssetTabState.emit(tab);
  }

  async selectPost(i) {
    if (!this.asset.posts[i].selected) {
      for (let i = 0; i < this.selectedPost.comments.length; i++) {
        this.selectedPost.comments[i].selected = false;
      }
      await this.asset.posts.map((post) => {
        post.selected = false;
      });
      this.asset.posts[i].selected = true;
      this.selectedPost = this.asset.posts[i];
      console.log('asset');
      console.log(this.asset);
      debug('clearing selected post');
      this.selectedCommentIndex = null;
      this.selectedComment = null;

      // scroll to the image
    }
  }

  async selectComment(comment, selectAllElements = true) {
    if (!this.actionProcessing) {
      debug('selecting comment');
      for (let i = 0; i < this.selectedPost.comments.length; i++) {
        if (comment == this.selectedPost.comments[i]) {
          this.selectedComment = comment;
          this.selectedCommentIndex = i;
          this.selectedPost.comments[i].selected = true;
          debug(this.selectedPost);

          // select all elements of this comment
          if (selectAllElements) {
            if (
              this.selectedPost &&
              this.selectedPost.comments != null &&
              this.selectedPost.comments[i].elements != null
            ) {
              for (
                let j = 0;
                j < this.selectedPost.comments[i].elements.length;
                j++
              ) {
                this.selectedPost.comments[i].elements[j].selected = true;
              }
            }
          }
        } else {
          this.selectedPost.comments[i].selected = false;
        }
      }
      debug(this.threadContainer);
    } else {
      debug('cannot select comment as an action is already processing');
      this.actionProcessing = false;
    }
  }

  selectElement(elementIndex, comment: Message) {
    if (
      this.currentEditorMode == ArtThreadMode.EDITOR_MODE &&
      this.currentToolMode == ToolMode.ERASER
    ) {
      // delete the element
      // this.deleteSelectedElement(elementIndex);
      comment.elements.splice(elementIndex, 1);
    }
    if (
      this.currentToolMode == ToolMode.SELECTION_TOOL ||
      this.currentEditorMode == ArtThreadMode.VIEW_MODE
    ) {
      // this.selectedElementIndex = elementIndex;
      for (let i = 0; i < comment.elements.length; i++) {
        comment.elements[i].selected = false;
      }
      comment.elements[elementIndex].selected = true;

      // select the comment for this element
      this.selectComment(comment, false); //don't select all elements because we just selected 1 element
    }
  }

  async selectCommentByArrayIndex(i) {
    await this.selectedPost.comments.map((comment) => {
      comment.selected = false;
    });
    this.selectedPost.comments[i].selected = true;
    this.selectedCommentIndex = i;
    this.selectedComment = this.selectedPost.comments[i];
  }

  async holdElement(event, element: SvgElement) {
    this.heldElement = element;
    debug(event);
    debug(element);
    this.heldOffset = {
      x: event.layerX / this.selectedPost.imageSize.x - element.x,
      y: event.layerY / this.selectedPost.imageSize.y - element.y,
    };
    debug('Element Held');
    debug(
      'Element Position: x:' + this.heldElement.x + ', y: ' + this.heldElement.y
    );
  }

  async dropElement() {
    debug('Dropped Element');
    if (this.heldElement) {
      debug(
        'Element Position: x:' +
          this.heldElement.x +
          ', y: ' +
          this.heldElement.y
      );
      this.heldElement = null;
    }
  }

  async onMouseDown(event, postArrayIndex: number) {
    debug('disabling action lock: mouseDown');
    debug('Current Editor Mode: ' + ArtThreadMode[this.currentEditorMode]);
    debug('Current Tool Mode: ' + ToolMode[this.currentToolMode]);
    this.mouseDown = true;
    this.actionProcessing = false;
    if (this.currentEditorMode == ArtThreadMode.VIEW_MODE) {
      switch (this.currentToolMode) {
        case ToolMode.SELECTION_TOOL:
          debug('onMouseDown View Mode');

          break;
      }
    } else if (this.currentEditorMode == ArtThreadMode.EDITOR_MODE) {
      //
      this.mouseMoveTick = 0;
      if (this.asset.posts[postArrayIndex] != this.selectedPost) {
        await this.selectPost(postArrayIndex);
      }
      if (this.newComment) {
        this.selectedComment = this.newComment;
      }
      if (this.selectedComment) {
        switch (this.currentToolMode) {
          case ToolMode.SELECTION_TOOL:
            debug('onMouseDown Editor Mode');
            if (!this.heldElement) {
              this.selectedElementIndex = null;
              this.selectionStart = {
                x: event.layerX,
                y: event.layerY,
              };
            }
            break;
          case ToolMode.POLYGON_TOOL:
            debug('image size: ' + this.selectedPost.imageSize);
            debug('Selected Post: ');
            console.log(this.selectedPost);
            this.selectedComment.elements.push(
              new PolygonElement(
                { x: event.layerX, y: event.layerY },
                this.selectedPost.imageSize
              )
            );
            break;
          case ToolMode.RECT_TOOL:
            this.selectedComment.elements.push(
              new RectElement(
                { x: event.layerX, y: event.layerY },
                this.selectedPost.imageSize
              )
            );
            break;
          case ToolMode.FREEHAND_TOOL:
            this.selectedComment.elements.push(
              new FreehandElement(
                { x: event.layerX, y: event.layerY },
                this.selectedPost.imageSize
              )
            );
            break;
        }
        this.selectedElementIndex = this.selectedComment.elements.length - 1;
      }
    }
  }

  async onMouseMove(event, postArrayIndex: number) {
    if (this.currentEditorMode == ArtThreadMode.EDITOR_MODE) {
      if (this.asset.posts[postArrayIndex] == this.selectedPost) {
        this.mousePosition.x = event.layerX;
        this.mousePosition.y = event.layerY;

        if (this.mouseDown) {
          this.mouseMoveTick += 1;
          if (this.mouseMoveTick % 3 == 0) {
            switch (this.currentToolMode) {
              case ToolMode.SELECTION_TOOL:
                if (this.heldElement) {
                  debug('Moving Element');

                  this.heldElement.x =
                    event.layerX / this.selectedPost.imageSize.x -
                    this.heldOffset.x;
                  this.heldElement.y =
                    event.layerY / this.selectedPost.imageSize.y -
                    this.heldOffset.y;
                }
                if (this.mouseDown && !this.heldElement) {
                  this.selectionEnd = {
                    x: event.layerX,
                    y: event.layerY,
                  };
                }
                break;
              case ToolMode.POLYGON_TOOL:
                debug('onMouseMove - Elements:');
                debug(this.selectedComment);
                (
                  this.selectedComment.elements[
                    this.selectedElementIndex
                  ] as PolygonElement
                ).AddPoint(
                  { x: event.layerX, y: event.layerY },
                  this.selectedPost.imageSize
                );
                break;
              case ToolMode.RECT_TOOL:
                (
                  this.selectedComment.elements[
                    this.selectedElementIndex
                  ] as RectElement
                ).SetSize(
                  { x: event.layerX, y: event.layerY },
                  this.selectedPost.imageSize
                );
                break;
              case ToolMode.FREEHAND_TOOL:
                (
                  this.selectedComment.elements[
                    this.selectedElementIndex
                  ] as FreehandElement
                ).AddPoint(
                  { x: event.layerX, y: event.layerY },
                  this.selectedPost.imageSize
                );
                break;
              case ToolMode.ERASER:
                break;
            }
          }
        }
      }
    }
  }

  async onMouseUp(event, postArrayIndex: number) {
    debug('mouse Up');
    this.mouseDown = false;
    if (this.currentEditorMode == ArtThreadMode.VIEW_MODE) {
      if (!this.heldElement) {
        this.selectedElementIndex = null;
        if (this.selectedComment != null) {
          this.selectedComment.elements.forEach((element) => {
            element.selected = false;
          });
        }
        this.selectedCommentIndex = null;
        this.selectedComment = null;
      }
    } else if (this.currentEditorMode == ArtThreadMode.EDITOR_MODE) {
      if (this.currentToolMode == ToolMode.SELECTION_TOOL) {
        this.selectionStart = { x: 0, y: 0 };
        this.selectionEnd = { x: 0, y: 0 };
        if (this.heldElement) {
          this.dropElement();
        }
      }

      switch (this.currentToolMode) {
        case ToolMode.POLYGON_TOOL:
          if (this.asset.posts[postArrayIndex] == this.selectedPost) {
            (
              this.selectedComment.elements[
                this.selectedElementIndex
              ] as PolygonElement
            ).AddPoint(
              { x: event.layerX, y: event.layerY },
              this.selectedPost.imageSize
            );
            this.currentToolMode = ToolMode.SELECTION_TOOL;
          }
          break;
        case ToolMode.FREEHAND_TOOL:
          if (this.asset.posts[postArrayIndex] == this.selectedPost) {
            (
              this.selectedComment.elements[
                this.selectedElementIndex
              ] as FreehandElement
            ).AddPoint(
              { x: event.layerX, y: event.layerY },
              this.selectedPost.imageSize
            );
            this.currentToolMode = ToolMode.SELECTION_TOOL;
          }
          break;
      }
    }
    this.actionProcessing = false;
    debug('disabling action lock: mouseUp');
  }

  onElementMouseMove(elementArrayIndex) {
    debug('ELEMENT MOUSE MOVE');
    if (this.currentToolMode == ToolMode.ERASER && this.mouseDown) {
      this.selectedComment.elements.splice(elementArrayIndex, 1);
    }
  }

  async sendDrawing() {
    debug('Sending Drawing');
    // selectedComment

    if (this.newComment != null) {
      // Then we must have created a new message
      // but we need to have an existing pubnub message to reference to
      // then save the message to the api with the new pubnub message as reference

      debug('New Drawing');
      this.newComment.message = this.chatText;
      this.selectedPost.comments.push(this.newComment);

      // this.selectedPost.comments.splice(0, 0, this.newComment);
      this.newComment = null;
      this.sortCommentArray(this.selectedPost);
    } else {
      // We are editing an existing message
      // We can safely save it
      debug('creating drawing');
      debug(this.selectedComment);
      await this.drawingService.CreateDrawing(
        {
          messageId: this.selectedComment._id,
          elements: this.selectedComment.elements,
          color: this.selectedColor,
        },
        'art_thread_drawing',
        this.asset._id
      );
    }
    this.exitEditorMode();
  }

  changeEditorMode(editorMode: ArtThreadMode) {
    if (editorMode == this.currentEditorMode) return;
    this.currentEditorMode = editorMode;
    this.editorChatTextError = false;

    switch (editorMode) {
      case ArtThreadMode.VIEW_MODE:
        break;
      case ArtThreadMode.EDITOR_MODE:
        this.selectedColor = this.selectedComment.color;
        break;
    }
  }

  focusChatbar(event, value: boolean) {
    this.chatbarFocused = value;
    debug(event.target);
    this.focusedChatbar = event.target;
    //this.focusedChatbar = event.target
  }

  onRightClickUsername(event, type: ContextTypes, username: string) {
    const isOwner = this.selectedServer.owner == this.user._id;
    const isUser = this.user.username == username;

    const isFriend =
      (this.user.friends as any).findIndex(
        (value) => value.username + '#' + value.usercode == username
      ) > -1;
    const isSentRequest =
      (this.user.sentRequests as any).findIndex(
        (value) => value.username + '#' + value.usercode == username
      ) > -1;
    const isRecievedRequest =
      (this.user.requests as any).findIndex(
        (value) => value.username + '#' + value.usercode == username
      ) > -1;

    const data = {
      username,
      serverId: this.selectedServer._id,
      isOwner,
      isUser,
      isFriend,
      isSentRequest,
      isRecievedRequest,
    };
    this.contextMenuService.show(event.clientX, event.clientY - 20, type, data);
  }

  adjustScreenSize() {
    // debug('ADJUSTSCREENSIZE CONTAINER');
    // debug(this.threadContainer);

    try {
      if (
        this.threadContainer &&
        this.threadContainer.nativeElement.children &&
        this.threadContainer.nativeElement.children.length > 2
      ) {
        debug(
          '------------------------- ADJUSTING SCREEN SIZE -------------------------'
        );
        for (
          let i = 0;
          i < this.threadContainer.nativeElement.children.length - 2;
          i++
        ) {
          // there is 1 elements at the bottom of the posts to add a new post so - 2
          debug('adjustScreenSize post #' + (i + 1));
          console.log(
            this.threadContainer.nativeElement.children[i].children[2]
              .children[0].children[1]
          );
          debug('------- ADJUSTING SCREEN SIZE 1 ---------');
          //debug(this.threadContainer.nativeElement.children[i]);
          debug('------- ADJUSTING SCREEN SIZE 2 ---------');
          let svgElement =
            this.threadContainer.nativeElement.children[i].children[2]
              .children[0].children[1];
          debug('------- ADJUSTING SCREEN SIZE 3 ---------');
          debug(
            'second element ' +
              this.threadContainer.nativeElement.children[i].children[2]
                .children[0].children[1]
          );
          debug('------- ADJUSTING SCREEN SIZE 4 ---------');
          debug(svgElement);
          debug('------- ADJUSTING SCREEN SIZE 5 ---------');
          debug(
            'Width: ' +
              svgElement.clientWidth +
              ', Height: ' +
              svgElement.clientHeight
          );
          debug('------- ADJUSTING SCREEN SIZE 6 ---------');
          this.asset.posts[i].imageSize = {
            x: svgElement.clientWidth,
            y: svgElement.clientHeight,
          };
          debug('------- ADJUSTING SCREEN SIZE 7 ---------');
          debug(this.asset.posts[i].imageSize);
        }
        debug(
          '------------------------- ADJUSTING SCREEN SIZE END -------------------------'
        );
      }
    } catch (e) {
      error('Adjusting Screen Size Error: ' + e);
      //      debug(e);
      // this.adjustScreenSize();
    }
  }

  markDone(comment: Message) {
    debug('completed');
    comment.completed = true;
    this.selectedComment = null;
    comment.elements.forEach((element) => {
      element.selected = false;
    });
    // Make sure all completed elements are at the back of the array
    this.sortCommentArray(this.selectedPost);
  }

  sortCommentArray(post: ThreadPost) {
    for (let i = 0; i < post.comments.length; i++) {
      if (post.comments[i].completed) {
        post.comments.push(post.comments.splice(i, 1)[0]);
      }
    }
  }

  clearSelection() {
    debug('clearing');
    this.actionProcessing = true;
    debug('clearSection: enabling action lock');
    this.selectedElementIndex = null;
    if (this.selectedComment.elements != null) {
      this.selectedComment.elements.forEach((element) => {
        element.selected = false;
      });
    }

    this.selectedCommentIndex = null;
    this.selectedComment = null;
  }

  sendReply(comment: Message) {
    // debug('send Reply');
    // debug(comment);
    this.actionProcessing = true;
    // debug('sendReply: enabling action lock');
    let textAreaElement: HTMLTextAreaElement = document.querySelector(
      '#id_' + comment._id
    );
    // debug(inputField.value)
    const chatReply: Message = {
      author: {
        id: this.user._id,
        username: this.user.username,
        avatar: this.user.avatar,
      },
      createdAt: moment.utc().toDate(),
      updatedAt: moment.utc().toDate(),
      message: textAreaElement.value,
      editingState: MessageEditingTypes.NONE,
      syncState: MessageSyncStates.CREATED,
    };
    if (comment.replies == null) comment.replies = [];
    comment.replies.push(chatReply);
    textAreaElement.value = '';
  }

  async createDrawingComment(postArrayIndex: number, commentIndex: number) {
    debug('createDrawComment - post array index: ' + postArrayIndex);
    debug('createDrawComment - comment index: ' + commentIndex);
    this.addingNewDrawing = true;

    await this.selectPost(postArrayIndex);

    this.selectedComment =
      this.asset.posts[postArrayIndex].comments[commentIndex];
    debug('Selected Comment elements');
    debug(this.selectedComment.elements);
    if (!this.selectedComment.color)
      this.selectedComment.color = this.pickRandomColor();
    if (!this.selectedComment.elements) this.selectedComment.elements = [];
    debug(this.selectedComment.elements);
    debug('Selected Color: ' + this.selectedColor);
    // this.currentEditorMode = ArtThreadMode.EDITOR_MODE;
    this.changeEditorMode(ArtThreadMode.EDITOR_MODE);
  }

  changeColor(color: COLOR_LIST) {
    this.selectedColor = color;
    if (this.newComment != null) {
      this.newComment.color = color;
    } else {
      this.selectedComment.color = color;
    }
  }

  pickRandomColor(): COLOR_LIST {
    let newColor = this.selectedColor;
    while (newColor == this.selectedColor) {
      debug('Object.keys ' + Object.keys(COLOR_LIST).length / 2);
      newColor = Math.floor(
        Math.random() * (Object.keys(COLOR_LIST).length / 2)
      );
    }
    return newColor;
  }

  createNewPost() {
    debug('Create New Post');
    this.popupControllerService.openNewPostPopup(
      this.selectedAssetId,
      this.user._id
    );
  }

  exitEditorMode() {
    this.currentEditorMode = ArtThreadMode.VIEW_MODE;
    this.selectedComment = null;
    this.newComment = null;
    this.chatText = null;
    this.editorChatTextError = false;
    this.currentToolMode = ToolMode.SELECTION_TOOL;
  }

  changeEditorTool(artTool: ToolMode) {
    this.currentToolMode = artTool;
  }

  onPostImageLoad(postArrayIndex: number) {
    debug('Image Loaded: ' + postArrayIndex);
    this.adjustScreenSize();
  }

  focusReplyTextArea(isFocused: boolean) {
    this.replyTextAreaFocused = isFocused;
    debug(isFocused);
  }

  onChatComponentActionEvent(action: ActionEvent) {
    debug('Chat Component Action Event Callback');
    debug(action);
    switch (action.action) {
      case ActionEventType.DRAW:
        const drawAction = action.value as DrawActionValue;
        const postIndex = this.asset.posts.findIndex(
          (value) => value._id == drawAction.channelId
        );
        debug('Post Index: ' + postIndex);
        if (postIndex > -1) {
          debug('the asset:');
          console.log(this.asset);
          const commentIndex = this.asset.posts[postIndex]?.comments?.findIndex(
            (value) => value._id == drawAction.messageId
          );
          if (commentIndex > -1) {
            debug('Comment Index: ' + commentIndex);
            this.createDrawingComment(postIndex, commentIndex);
          }
        }
        break;
      case ActionEventType.SELECT:
        const selectAction = action.value as SelectActionValue;
        const post = this.asset.posts.find(
          (value) => value._id == selectAction.channelId
        );
        const comment = post.comments.find(
          (value) => value._id == selectAction.messageId
        );
        this.selectComment(comment);
        break;
    }
  }

  onMessagesRefreshEvent(messages: Message[], postIndex: number) {
    console.log('MESSAGE REFRESH');
    console.log(messages);
    console.log(postIndex);
    console.log(this.asset.posts[postIndex]);
    this.asset.posts[postIndex].comments = messages;
    this.RefreshDrawings();
  }

  async RefreshDrawings() {
    debug(
      'Refresh Drawings 1 ----------------------------------------------TESTING'
    );
    if (this.currentEditorMode == ArtThreadMode.EDITOR_MODE || !this.asset)
      return;
    debug('Refresh Drawings 2');
    const assetDrawings = (await this.drawingService.GetDrawings(
      this.asset._id
    )) as any[];
    debug('Refresh Drawings 3');
    debug('the drawings that were found:');
    console.log(assetDrawings);
    if (!assetDrawings || assetDrawings.length == 0) return; //if there are no drawings, do nothing

    for (let iPost = 0; iPost < this.asset.posts.length; iPost++) {
      if (
        !this.asset.posts[iPost].comments ||
        this.asset.posts[iPost].comments.length == 0
      )
        continue;
      for (
        let iComment = 0;
        iComment < this.asset.posts[iPost].comments.length;
        iComment++
      ) {
        // match the drawings to the messages
        for (let iDrawing = 0; iDrawing < assetDrawings.length; iDrawing++) {
          if (
            assetDrawings[iDrawing].messageId ==
            this.asset.posts[iPost].comments[iComment]._id
          ) {
            // this will override common values, like _id, createdAt, updatedAt, etc
            this.asset.posts[iPost].comments[iComment].color =
              assetDrawings[iDrawing].color;
            this.asset.posts[iPost].comments[iComment].elements = [];
            this.asset.posts[iPost].comments[iComment].elements =
              assetDrawings[iDrawing].elements;
            /*_.merge(
              this.asset.posts[iPost].comments[iComment],
              assetDrawings[iDrawing]
            );*/
            console.log('elements');
            console.log(this.asset.posts[iPost].comments[iComment].elements);
            for (
              let iElement = 0;
              iElement <
              this.asset.posts[iPost].comments[iComment].elements.length;
              iElement++
            ) {
              switch (
                this.asset.posts[iPost].comments[iComment].elements[iElement]
                  .elType
              ) {
                case ToolMode.POLYGON_TOOL:
                  this.asset.posts[iPost].comments[iComment].elements[
                    iElement
                  ] = this.asset.posts[iPost].comments[iComment].elements[
                    iElement
                  ] as PolygonElement;
                  break;
                case ToolMode.RECT_TOOL:
                  this.asset.posts[iPost].comments[iComment].elements[
                    iElement
                  ] = this.asset.posts[iPost].comments[iComment].elements[
                    iElement
                  ] as RectElement;
                  break;
              }
            }
          }
        }
      }
    }

    // OLD CODE START
    /* 
    this.asset.posts = await Promise.all(
      this.asset.posts.map(async (post) => {
        console.log(assetDrawings);
        const drawingPost = await assetDrawings.find(
          (value) => value.postId == post._id
        );

        if (
          drawingPost == undefined ||
          drawingPost.drawings == undefined ||
          drawingPost.drawings.length == 0
        )
          return post;

        post.comments = await Promise.all(
          post.comments.map((comment) => {
            if (comment.elements == undefined) comment.elements = [];

            //TODO: add extra fields

            if (
              drawingPost.drawings == undefined ||
              drawingPost.drawings.length == 0
            )
              return comment;

            for (
              let iDrawing = 0;
              iDrawing < drawingPost.drawings.length;
              iDrawing++
            ) {
              if (drawingPost.drawings[iDrawing].timetoken == comment._id) {
                _.merge(comment, drawingPost.drawings[iDrawing]);
                // up to here
                for (
                  let iElement = 0;
                  iElement < comment.elements.length;
                  iElement++
                ) {
                  switch (comment.elements[iElement].elType) {
                    case ToolMode.POLYGON_TOOL:
                      comment.elements[iElement] = comment.elements[
                        iElement
                      ] as PolygonElement;
                      break;
                    case ToolMode.RECT_TOOL:
                      comment.elements[iElement] = comment.elements[
                        iElement
                      ] as RectElement;
                      break;
                  }
                }
              }
            }
            return comment;
          })
        );
        return post;
      })
    );
*/
    // OLD CODE END

    // this.adjustScreenSize();
  }

  onPostImageVisible(postArrayIndex: number, hovered: boolean = false) {
    this.asset.posts[postArrayIndex].isPostImageHovered = hovered;
  }

  changePostDrawingVisibility(
    postArrayIndex: number,
    visible: boolean = false
  ) {
    this.asset.posts[postArrayIndex].isAllElementsHidden =
      !this.asset.posts[postArrayIndex].isAllElementsHidden;
    this.asset.posts[postArrayIndex].comments.map((comment) => {
      comment.isElementsHidden = visible;
    });
  }

  onOpenProfileHoverClick(username, clickEvent) {
    console.log(clickEvent);

    this.popupControllerService.openProfileHover(
      username,
      clickEvent.clientX,
      clickEvent.clientY
    );
  }

  /**
   *
   * @param elementIndex Element Index to remove, if none is provided the selected element will be removed
   */
  deleteSelectedElement(elementIndex: number = null) {
    if (elementIndex) {
      this.selectedComment.elements.splice(elementIndex, 1);
    } else {
      this.selectedComment.elements.splice(this.selectedElementIndex, 1);
    }
  }

  openKababMenu(event, post: object) {
    debug(event);
    this.contextMenuService.show(
      event.pageX - 170,
      event.pageY,
      ContextTypes.postKababMenu,
      { post, assetId: this.asset._id }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustScreenSize();
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RETURN) {
      // this.killWindow();
      // Send Chat Message
      // debug(this.chatText);
      // let currentReplyTextArea: HTMLTextAreaElement = document.querySelector('#id_' + this.selectedComment._id);
      /*if (this.replyTextAreaFocused) {
        this.sendReply(this.selectedComment);
      }*/
    }

    if (this.chatbarFocused) {
      if (event.keyCode == KEY_CODE.RETURN && !event.shiftKey) {
        event.preventDefault();

        // create new message
        /*
        let comment: ThreadChatComment = {
          message: this.chatText,
          ownerId: this.user._id,
          createdAt: now().toString(),
        };

        this.selectedPost.comments.push(comment);
        // debug('You wrote: ' + this.chatText);
        this.chatText = '';*/

        // this doesn't trigger the input to shrink
        this.focusedChatbar.value = '';

        //fire change event manually
        this.focusedChatbar.dispatchEvent(new Event('change'));
      }

      if (event.keyCode == KEY_CODE.ESCAPE) {
        this.chatbarFocused = false;
      }
    }

    if (this.replyTextAreaFocused) {
      if (event.keyCode == KEY_CODE.RETURN && !event.shiftKey) {
        event.preventDefault();
        /*
        let comment: ThreadChatComment = {
          message: this.chatText,
          ownerId: this.user._id,
          createdAt: now().toString(),
        };

        this.selectedPost.comments.push(comment);
        // this.loggerService.debug('You wrote: ' + this.chatText);
        this.chatText = '';*/
        this.sendReply(this.selectedComment);
      }
      /*
            if (event.keyCode == KEY_CODE.ESCAPE) {
              this.chatbarFocused = false;
            }*/
    }

    if (this.currentEditorMode == ArtThreadMode.EDITOR_MODE) {
      if (event.keyCode == KEY_CODE.ESCAPE) {
        this.exitEditorMode();
      }
      if (
        event.keyCode == KEY_CODE.DELETE &&
        this.selectedElementIndex != null
      ) {
        this.deleteSelectedElement();
      }
      if (event.keyCode == KEY_CODE.RETURN) {
        this.sendDrawing();
      }
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PopupControllerService } from 'src/app/services/popup-controller.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

export enum DialogType {
  SINGLE_TEXT_INPUT_BOX_DIALOG,
  CONFIRMATION_DIALOG_DESTRUCTIVE,
  CONFIRMATION_DIALOG_DANGER,
  CONFIRMATION_DIALOG,
  ALERT,
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  visible = false;
  type: DialogType;
  title: string;
  description: string;
  callbackEvent: Subject<any>;
  eDialogType = DialogType;

  valid: boolean = true;

  textInputLabel: string = null;
  textInputValue: string = '';

  @ViewChild('confirmation') confirmationElement: ElementRef;

  constructor(private popupController: PopupControllerService) {}

  ngOnInit(): void {
    this.popupController.dialog.subscribe((data: any) => {
      if (data.type == null) {
        this.visible = false;
      } else {
        this.type = data.type;
        this.title = data.title;
        this.description = data.description;
        this.callbackEvent = data.callbackEvent;
        this.visible = true;

        if (data.inputLabel) {
          this.textInputLabel = data.inputLabel;
        }

        if (this.type == DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE) {
          this.valid = false;
        } else {
          this.valid = true;
        }
      }
    });
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  onAnswer(answer) {
    if (answer == true) {
      if (this.valid) {
        // yes presses
        if (this.type == DialogType.SINGLE_TEXT_INPUT_BOX_DIALOG) {
          this.callbackEvent.next(this.textInputValue);
        } else {
          this.callbackEvent.next(true);
        }
        this.killWindow();
      }
    } else {
      this.callbackEvent.next(false);
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    if (this.type == DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE) {
      (<HTMLInputElement>document.getElementById('confirmation')).value = '';
    }
    if (this.type == DialogType.SINGLE_TEXT_INPUT_BOX_DIALOG) {
      (<HTMLInputElement>document.getElementById('textinput')).value = '';
    }
  }

  updateFormValidation(event) {
    if (this.type == DialogType.CONFIRMATION_DIALOG_DESTRUCTIVE) {
      this.valid = event.srcElement.value.toLowerCase() == 'delete';
    }
  }
}

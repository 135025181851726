import { Injectable } from '@angular/core';
// import * as Amplitude from '@amplitude/node';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private client;
  constructor() { 
    // this.client = Amplitude.init('e1827d8602b857b03852498d6cda05e2');

    // test log
    /*this.client.logEvent({
      event_type: 'Node.js Event',
      user_id: 'datamonster@gmail.com',
      location_lat: 37.77,
      location_lng: -122.39,
      ip: '127.0.0.1',
      event_properties: {
        keyString: 'valueString',
        keyInt: 11,
        keyBool: true
      }
    });*/
  }
}

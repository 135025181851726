import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';
import { FileService, FileTypes } from 'src/app/services/api/file.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-modify-server',
  templateUrl: './modify-server.component.html',
  styleUrls: ['./modify-server.component.scss'],
})
export class ModifyServerComponent implements OnInit {
  visible = false;
  server: Server;
  selectedImage;
  selectedImageSrc: string;

  public modifyServerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    thumbnail: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serverService: ServersService,
    private assetsService: FileService
  ) {}

  ngOnInit(): void {
    //this.modifyServerForm.get('submitButton')?.disable();
    this.popupController.modifyServer.subscribe((data) => {
      debug('updating modify-server visibility');
      this.visible = data == null ? false : true;
      this.server = data.server;
      console.log(data);

      this.modifyServerForm.get('name')?.setValue(this.server.name);
      this.modifyServerForm.get('thumbnail')?.setValue(this.server.thumbnail);

      this.selectedImageSrc =
        'https://artdominion.imgix.net/' + this.server.thumbnail;
    });
  }

  onSubmit() {
    const img = new Image();
    img.src = URL.createObjectURL(this.selectedImage);
    img.onload = (e: any) => {
      const width = e.target.width;
      const height = e.target.height;

      // upload selected image
      this.assetsService
        .getPresignedUrl(
          FileTypes.IMAGES,
          this.selectedImage.name,
          this.selectedImage.type,
          width,
          height
        )
        .then((response) => {
          debug('received response');
          this.modifyServerForm.get('thumbnail')?.setValue(response.filename);

          debug(response);
          if (response.url) {
            debug('received presigned url');
            const fileUploadUrl = response.url;
            const imageForm = new FormData();
            imageForm.append('file', this.selectedImage);
            this.assetsService
              .uploadToS3(
                fileUploadUrl,
                this.selectedImage.type,
                this.selectedImage
              )
              .subscribe(async (uploadResponse) => {
                if (uploadResponse && uploadResponse.type == 4) {
                  this.assetsService
                    .confirmFileUpload(response._id)
                    .then((confirmResponse) => {
                      debug('thumbnail upload confirmed');
                      debug(confirmResponse);
                      this.serverService
                        .patchServer(this.modifyServerForm, this.server._id)
                        .then((response) => {
                          debug(response);
                          this.serverService.getUsersOwnedServers();
                          this.killWindow();
                        });
                    });
                }
              });
          }
        });
    };
  }

  async updateFormValidation() {
    if (this.modifyServerForm.valid) {
      this.modifyServerForm.get('submitButton')?.enable();
    } else {
      this.modifyServerForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.modifyServerForm.reset();
    this.updateFormValidation();
  }

  onFileUpload(event) {
    debug(event);
    debug('selected new image 2');
    const image = (event.target as HTMLInputElement).files[0];
    console.log('.5');
    const reader = new FileReader();
    console.log('1');

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log('2');
      reader.readAsDataURL(file);
      console.log('3');
      reader.onload = () => {
        console.log('4');
        this.selectedImageSrc = reader.result as string;
        console.log(this.selectedImageSrc);
      };
    }

    this.selectedImage = image;
    debug(this.selectedImage);
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  FormBuilder,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';
import { EmailGroup } from 'src/app/components/multi-email-text-area/multi-email-text-area.component';
import { MultiEmailTextAreaComponent } from 'src/app/components/multi-email-text-area/multi-email-text-area.component';
import { InviteToken } from 'src/app/models/invite-token.model';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-invite-to-server',
  templateUrl: './invite-to-server.component.html',
  styleUrls: ['./invite-to-server.component.scss'],
})
export class InviteToServerComponent implements OnInit {
  visible = false;
  category: any;
  server: any;
  form: FormGroup;
  fb: FormBuilder = new FormBuilder();
  emails: EmailGroup[];
  formValid: boolean = false;
  snackbarDuration = 2000;
  invitationLink: InviteToken;
  invitesSent: boolean = false;
  @ViewChild(MultiEmailTextAreaComponent)
  multiEmailTextAreaComponent: MultiEmailTextAreaComponent;

  constructor(
    private popupController: PopupControllerService,
    private serverService: ServersService,
    private clipboardApi: ClipboardService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      emails: this.fb.array([], [Validators.required]),
      submitButton: this.fb.control('submitButton', [Validators.required]),
    });
    this.form.disable();
    this.popupController.invitePeoplePopup.subscribe((data) => {
      this.visible = data == null ? false : true;
      this.server = data;

      // create invitation link
    });
  }

  invite() {
    const realEmails: string[] = [];
    this.emails.map((email) => {
      if (!email.invalid) {
        realEmails.push(email.email);
      }
    });

    console.log('real emails');
    console.log(realEmails);

    console.log('serverId');
    console.log(this.server._id);
    console.log('server');
    console.log(this.server);

    this.serverService
      .inviteMultipleEmailsToServer(this.server._id, realEmails)
      .then((response) => {
        // set timer to close window
        this.invitesSent = true;
        setTimeout(() => {
          this.invitesSent = false;
          this.resetForm();
          this.killWindow();
        }, 3000);
      });

    // clear everything and close window
    //this.emails = [];
    //this.form.reset();
    //this.closeWindow();
  }

  private resetForm() {
    this.form.reset();
    this.multiEmailTextAreaComponent.reset();
    this.emails = [];
  }

  private updateForm() {
    if (this.formValid) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  updateEmails(_emails: EmailGroup[]) {
    this.emails = _emails;
    console.log('Updating emails');
    console.log(this.emails);

    let formValid = false;
    this.emails.forEach((email) => {
      if (!email.invalid) {
        formValid = true;
      }
    });
    this.formValid = formValid;
    this.updateForm();
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id == 'fullscreen') {
        console.log(event.target.parentNode.parentNode.id);
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  createInviteLink() {
    this.serverService
      .createServerLinkToken(this.server._id)
      .then((data: InviteToken) => {
        this.invitationLink = data;
        this.copyInviteToClipboard();
      });
  }

  copyInviteToClipboard() {
    console.log('Copying to clipboard');
    this.clipboardApi.copy(this.invitationLink.token);
    this.openSnackBar('Invite Copied', null, 'mat-accent');
  }

  openSnackBar(message: string, action: string, color: string) {
    this._snackBar.open(message, action, {
      duration: this.snackbarDuration,
      verticalPosition: 'bottom',
      panelClass: ['center-snackbar', color],
    });
  }

  killWindow() {
    this.resetForm();
    this.visible = false;
    /* this.modifyCategoryForm.reset();
    this.updateFormValidation();*/
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
// import { createMessageObject } from '../chat-channel/ChatMessages';
// import * as Pubnub from 'pubnub';
//import { ChatComment } from '../../../models/chat-message.model';
import { ApiService } from '../../../services/api/api.service';
import {
  ActionEventType,
  ActionEvent,
  DrawActionValue,
  SelectActionValue,
} from '../../../classes/EventAction';
import { ThreadPost } from 'src/app/models/thread-chat-message.model';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { Server } from 'src/app/models/server.model';
import { ServersService } from 'src/app/services/api/servers.service';
import { User } from 'src/app/models/user.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';
/*import Message, {
  MessageEditingTypes,
  MessageSyncStates,
} from 'src/app/models/message.model';*/
import {
  Message,
  MessageEditingTypes,
  MessageSyncStates,
} from 'src/app/models/message.model';
import MessageService from 'src/app/services/api/messages.service';
import * as moment from 'moment';
import { GlobalLocationResolverService } from 'src/app/services/global-location-resolver.service';

// TODO move this to some env configuration
// const PUB_KEY = 'pub-c-4aa80fa7-d026-4272-9645-6a14792e55ce';
// const SUB_KEY = 'sub-c-70213d3e-7102-11ec-bb6e-fa616d2d2ecf';

type ChatTypeConfig = {
  paddingBottom: string;
  paddingTop: string;
  paddingRight: string;
  maxHeight: string;
};

const CHAT_TYPE_CONFIG: Record<string, ChatTypeConfig> = {
  'full-screen-chat': {
    paddingBottom: '90px',
    paddingTop: '50px',
    maxHeight: 'none',
    paddingRight: 'none',
  },
  'art-thread-chat': {
    paddingBottom: '50px',
    paddingTop: '20px',
    maxHeight: '300px',
    paddingRight: 'none',
  },
};

enum MessageActionType {
  DELETE = 'delete_message',
  EDITED = 'message_edited',
}

type MessageAction = {
  type: MessageActionType;
  value: any; // consider making a types for each action payload
};

type EditMessageActionValue = {
  timestamp: number;
  updated: string; // consider making a types for each action payload
};

@Component({
  selector: 'app-general-chat-component',
  templateUrl: './general-chat.component.html',
  styleUrls: ['./general-chat.component.scss'],
})
export class GeneralChatComponent implements OnInit, AfterViewInit {
  @ViewChild('chatarea') private chatarea: ElementRef;

  @ViewChild('editingTextArea', { static: false }) editMessageInput: ElementRef;

  @Input() server: Server;

  @Input() channelId: string;

  @Input() chatType: string;

  @Input() post: ThreadPost;

  @Output() actionEvent = new EventEmitter<ActionEvent>();

  @Output() messagesEvent = new EventEmitter<Message[]>();

  @Input() selectedComment: Message;

  // pubnubInst: Pubnub;
  textInputFocused: boolean;
  chatHistory: Message[] = [];
  currentMessageVal = '';
  currentEditText = '';
  currentUsername = '';
  isFetchingMore = false;
  messages: Message[] = [];
  authUserId;
  authUser;
  selectedChatConfig: ChatTypeConfig;
  eContextTypes = ContextTypes;
  eMessageEditingTypes = MessageEditingTypes;
  eMessageSyncStates = MessageSyncStates;

  fMoment = moment;
  previousPost = null;

  timer;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    private popupController: PopupControllerService,
    private messageService: MessageService,
    private globalLocationResolver: GlobalLocationResolverService
  ) {
    this.authUserId = this.apiService.getUser()._id;
    this.authUser = this.apiService.getUser();

    console.log('general-chat-component - constructor: ');
    console.log('authUserId: ' + this.authUserId);
    console.log('authUser: ');
    console.log(this.authUser);

    this.apiService.user.subscribe((user) => {
      this.authUser = user;
      this.authUserId = user._id;
      console.log('UPDATING USER');
    });

    this.apiService.GetCurrentUser();
  }

  ngOnInit(): void {
    // TODO pass in the authed user's ID as the uuid entry into the pubnub config this.authUserId = `${Math.random()}`
    console.log('|||| generalChat - ngOnInit ||||');
    const chatType = this.chatType || 'full-screen-chat';
    this.selectedChatConfig = CHAT_TYPE_CONFIG[chatType];

    this.currentUsername = this.authUser.username;

    this.timer = setInterval(() => {
      const currentChannelId = this.globalLocationResolver.getActiveChannelId();
      console.log(
        '||| general chat - timer - channelId: ' + currentChannelId + '|||'
      );
      //if (currentChannelId == this.channelId) {
      this.refreshChat();
      //}
    }, 10000);
    this.refreshChat(); //fire it once immediately when the channel changes
  }

  refreshChat() {
    console.log('refreshing chat');
    if (this.channelId) {
      this.messageService
        .getMessages(this.channelId, 100, 0)
        .then((messages) => {
          console.log('general-chat-component - constructor - getMessages');
          console.log(messages);
          console.log('channelId: ' + this.channelId);

          //TODO: check the messages have changed and add messages to the end of the array
          /*if (messages.length > 0) {
            console.log('new messages: ');
            console.log(messages);

            for (let i = 0; i < messages.length; i++) {
              // if the first message in the
              //this.messages.splice(start,0,element)
              // this array is in date order
              // position 0 is oldest. position length-1 is newest
              // both arrays is moving oldest message to newest

              //if nothing has been added yet then add the first message
              if (this.messages.length == 0) {
                this.messages.push(messages[i]);
                continue;
              }

              if (
                moment(messages[i].createdAt).isBefore(
                  this.messages[0].createdAt
                )
              ) {
                // the message precedes the start of the array so add the message to the start of the array
                this.messages.splice(0, 0, messages[i]);
                // isMessageFound = true;
                break; // once we add it go to the next message
              } else {
                // at this point we are at the start of where our existing array begins and i should now be the first message in the existing array at position 0
                let messageFoundIndex = null;

                for (let j = 0; j < this.messages.length; j++) {
                  // if the message exists then update the message and go to the next, otherwise inject the message into the array using slice
                  if (messages[i]._id == this.messages[j]._id) {
                    this.messages[messageFoundIndex].message =
                      messages[i].message;
                    break;
                  }

                  // if this message is newer than the current message then add it to the array
                  // since we are using breaks, we don't have to check if the previous message is old, because we know it has to be to get here
                  if (
                    moment(messages[i].createdAt).isAfter(
                      this.messages[j].createdAt
                    )
                  ) {
                    this.messages.splice(j, 0, messages[i]);
                    break;
                  }
                }
              }
            }
          }*/
          this.messages = messages;
          this.updateMessageAvatars();
          this.refreshMessagesEvent();
        });
    }
  }

  updateMessageAvatars() {
    let previousUsername;
    for (let i = 0; i < this.messages.length; i++) {
      this.messages[i].syncState = MessageSyncStates.SYNCED;
      this.messages[i].editingState = MessageEditingTypes.NONE;

      if (previousUsername != this.messages[i].author.username) {
        previousUsername = this.messages[i].author.username;
        this.messages[i].showUser = true;
      } else {
        this.messages[i].showUser = false;
      }
    }
  }

  onRightClickUsername(event, type: ContextTypes, username: string) {
    const isOwner = this.server.owner == this.authUser._id;
    const isUser = this.authUser.username == username;

    const isFriend =
      this.authUser.friends.findIndex(
        (value) => value.username + '#' + value.usercode == username
      ) > -1;
    const isSentRequest =
      this.authUser.sentRequests.findIndex(
        (value) => value.username + '#' + value.usercode == username
      ) > -1;
    const isRecievedRequest =
      this.authUser.requests.findIndex(
        (value) => value.username + '#' + value.usercode == username
      ) > -1;

    const data = {
      username,
      serverId: this.server._id,
      isOwner,
      isUser,
      isFriend,
      isSentRequest,
      isRecievedRequest,
    };
    this.contextMenuService.show(event.clientX, event.clientY - 20, type, data);
  }

  refreshMessagesEvent() {
    this.messagesEvent.emit(this.messages);
  }

  ngAfterViewInit(): void {
    //this.firstPageMessages();
  }

  selectComment(message: Message) {
    debug('Selecting comment: ' + message._id);
    this.selectedComment = message;

    //TODO: add Select comment code
    /*const actionValue: SelectActionValue = {
      channelId: this.channelId,
      timetoken: timetoken,
      owner: this.authUserId,
    };*/
    /*this.actionEvent.emit({
      action: ActionEventType.SELECT,
      value: actionValue,
    });*/
  }

  /* firstPageMessages(): void {
    this.isFetchingMore = true;
    this.fetchMessageHistory().then((messages) => {
      if (messages) {
        this.messages.push(...messages);
        this.refreshMessagesEvent();
        window.requestAnimationFrame(() => {
          this.scrollToBottom(false);
          this.isFetchingMore = false;
        });
      }
    });
  }*/

  focusTextInput(bState: boolean) {
    this.textInputFocused = bState;
  }

  scrollToBottom(isSmooth: boolean = true): void {
    this.chatarea.nativeElement.scroll({
      top: this.chatarea.nativeElement.scrollHeight,
      left: 0,
      behavior: isSmooth ? 'smooth' : 'auto',
    });
  }

  submitMessage(e: Event) {
    console.log('submitMessage');
    e.preventDefault();
    const message: Message = {
      message: this.currentMessageVal,
      author: this.authUser,
      channelId: this.channelId,
      syncState: MessageSyncStates.CREATED,
      editingState: MessageEditingTypes.NONE,
      createdAt: moment.utc().toDate(),
      updatedAt: moment.utc().toDate(),
    };
    console.log('Message that is being sent:');
    console.log(message);

    //push this message to messages
    this.messages.push(message);
    console.log('Message pushed to messages');
    this.currentMessageVal = '';

    //send message to the server
    this.messageService.sendMessage(message).then((response) => {
      if (response) {
        //TODO: update all the values of the original message to make sure they match the server
        console.log('Received response back from server');
        message.syncState = MessageSyncStates.SYNCED;
        console.log(message);
        this.updateMessageAvatars();
      }
    });

    /*this.currentMessageVal = '';
    const publishPayload = {
      channel: this.channelId,
      message: {
        textMessage: messageValue,
        username: this.currentUsername,
        avatarUrl: this.authUser.avatarUrl,
        authorId: this.authUserId,
      },
    };

    this.pubnubInst.publish(publishPayload, function (status, response) {
      debug(status, response);
    });*/
  }

  submitEditMessage(e: Event, message: Message) {
    e.preventDefault();

    console.log('submitEditMessage');
    console.log('message: ');
    console.log(message);
    console.log('this.currentEditText: ' + this.currentEditText);

    const messageValue = this.currentEditText;
    if (messageValue.length === 0) {
      this.cancelEditing();
    } else {
      message.message = messageValue;
      message.editingState = MessageEditingTypes.NONE;
      message.syncState = MessageSyncStates.MODIFIED;
      message.updatedAt = new Date();
      this.currentEditText = '';
      this.cancelEditing();

      this.messageService.editMessage(message).then((response) => {
        if (response) {
          //TODO: update all the values of the original message to make sure they match the server
          message.syncState = MessageSyncStates.SYNCED;
        }
      });

      /*
        const messagePayload: EditMessageActionValue = {
        timestamp: Date.now(),
        updated: messageValue,
      };
      const messageAction: MessageAction = {
        type: MessageActionType.EDITED,
        value: JSON.stringify(messagePayload),
      };
      this.pubnubInst
        .addMessageAction({
          channel: this.channelId,
          messageTimetoken: messageTimetoken,
          action: messageAction,
        })
        .then(() => {
          this.cancelEditing();
        })
        .catch((e) => error(e));
        */
    }
  }

  blurInput(e: Event) {
    const target = e.target as HTMLTextAreaElement;
    target.blur();
  }

  @HostListener('document:keypress.escape', ['$event'])
  blurEdit(e: Event) {
    const target = e.target as HTMLTextAreaElement;
    e.preventDefault();
    this.cancelEditing();
  }

  onChatAreaScroll() {
    //TODO: Change this to new get message api
    const scrollUpRemaining = this.chatarea.nativeElement.scrollTop;
    if (
      scrollUpRemaining > 0 &&
      scrollUpRemaining < 650 &&
      !this.isFetchingMore &&
      this.messages.length > 0
    ) {
      this.isFetchingMore = true;
      const firstMessage = this.messages[0];
      // const messageToken = firstMessage.timetoken;
      /*this.fetchMessageHistory(messageToken).then((messages) => {
        if (messages) {
          this.messages.unshift(...messages);
          this.refreshMessagesEvent();
          requestAnimationFrame(() => {
            this.isFetchingMore = false;
          });
        }
      });*/
    }
  }

  /*fetchMessageHistory(lastMessageNano = undefined) {
    return this.pubnubInst
      .fetchMessages({
        channels: [this.channelId],
        start: lastMessageNano,
        count: 50,
        includeMessageType: true,
        includeUUID: true,
        includeMeta: true,
        includeMessageActions: true,
      })
      .then(async (response) => {
        const messages = response.channels[this.channelId];
        debug('pubnub messages for ' + this.channelId);
        debug(messages);
        if (messages) {
          let usernames: string[] = [];
          Promise.all(
            messages.map(async (message) => {
              if (!usernames.includes(message.message.username)) {
                usernames.push(message.message.username);
              }
            })
          );

          debug('usernames:');
          debug(usernames);

          // request user shorts from api
          const userShorts = await this.apiService.getUsersShorts(usernames);

          debug('user shorts:');
          debug(userShorts);

          return messages
            .filter((message) => {
              const actions = message?.actions;
              if (actions) {
                return !actions[MessageActionType.DELETE];
              }
              return true;
            })
            .map((message) => {
              const actions = message.actions;
              let displayMessage;
              if (actions && actions[MessageActionType.EDITED]) {
                const edits: {} = actions[MessageActionType.EDITED];
                const editVals = Object.keys(edits).map((edit) =>
                  JSON.parse(edit)
                );
                editVals.sort((val1, val2) => val1.timestamp - val2.timestamp);
                const lastItem = editVals[editVals.length - 1];
                displayMessage = lastItem.updated;
              } else {
                displayMessage = message.message.textMessage;
              }

              const userShort =
                userShorts[
                  userShorts.findIndex(
                    (value) => value.username == message.message.username
                  )
                ];

              return createMessageObject({
                messageValue: displayMessage,
                authorId: message.uuid,
                authorUsername: message.message.username,
                authorAvatar: userShort.avatar
                  ? 'https://artdominion.imgix.net/' + userShort.avatar
                  : null,
                timetoken: message.timetoken.toString(),
                channelId: this.channelId,
              });
            });
        }
        return [];
      })
      .catch((e) => {
        console.log('this is the error', e.status);
      });
  }*/
  /*
  deleteFromMessages(message: Message) {
    this.messageService.deleteMessage(message).then((response) => {
      // find message in messages array
      const index = this.messages.findIndex( (val) => message._id === val._id);

      // delete message from messages array
      this.messages.splice(index, 1);

    });
    this.refreshMessagesEvent();
  }
*/
  /*receiveEditedMessage(toEditTimetoken, editActionValue) {
    const newMessageVal = editActionValue.updated;
    this.messages = this.messages.map((message) => {
      if (message.timetoken === toEditTimetoken) {
        message.message.value = newMessageVal;
      }
      return message;
    });
    this.refreshMessagesEvent();
  }*/

  onDeleteMessageClick(message: Message) {
    this.messageService.deleteMessage(message).then((response) => {
      // find message in messages array
      const index = this.messages.findIndex((val) => message._id === val._id);

      // delete message from messages array
      this.messages.splice(index, 1);
    });
    this.refreshMessagesEvent();
  }

  onEditMessageClick(message: Message) {
    this.selectedComment = message;
    message.editingState = MessageEditingTypes.EDITING;

    //TODO: fix this code
    console.log('onEditMessageClick');
    console.log('selectedComment: ');
    console.log(message);
    for (let i = 0; i < this.messages.length; i++) {
      console.log('checking comment ' + this.messages[i]._id);
      if (this.selectedComment && this.messages[i]._id == message._id) {
        console.log('editing comment ' + this.messages[i]._id);
        this.messages[i].editingState = MessageEditingTypes.EDITING;
        this.currentEditText = this.messages[i].message;
      } else {
        this.messages[i].editingState = MessageEditingTypes.NONE;
      }
    }
    // this.messages = this.messages.map((message) => {
    //   if (message.timetoken === messageTimeToken) {
    //     message.isEditing = MessageEditingTypes.EDITING;
    //     this.currentEditText = message.message.value;
    //   } else {
    //     message.isEditing = MessageEditingTypes.NONE;
    //   }
    //   return message;
    // });
    this.refreshMessagesEvent();
    this.cdr.detectChanges();
    const inputElement = this.editMessageInput.nativeElement;
    if (inputElement) {
      inputElement.focus();
    }
  }

  cancelEditing() {
    this.currentEditText = '';
    this.messages = this.messages.map((message) => {
      message.editingState = MessageEditingTypes.NONE;
      return message;
    });
    this.refreshMessagesEvent();
  }

  onOpenProfileHoverClick(username, clickEvent) {
    console.log(clickEvent);

    this.popupController.openProfileHover(
      username,
      clickEvent.clientX,
      clickEvent.clientY
    );
  }

  onDrawingClick(message: Message) {
    debug('onDrawingClick');
    const actionValue: DrawActionValue = {
      messageId: message._id,
      channelId: this.channelId,
      owner: this.authUserId,
    };
    this.actionEvent.emit({
      action: ActionEventType.DRAW,
      value: actionValue,
    });
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './screens/login/login.component';
import { RegisterComponent } from './screens/register/register.component';
import { ComingSoonComponent } from './screens/coming-soon/coming-soon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgotPasswordComponent } from './screens/forgot-password/forgot-password.component';
import { MainComponent } from './screens/main/main.component';
import { HomeSidebarComponent } from './screens/sidebars/home-sidebar/home-sidebar.component';
import { FriendListComponent } from './screens/channel-screens/friend-list/friend-list.component';
import { FriendListHeaderComponent } from './screens/headers/friend-list-header/friend-list-header.component';
import { FriendListSidePanelComponent } from './screens/sidepanels/friend-list-side-panel/friend-list-side-panel.component';
import { UserPanelComponent } from './screens/user-panel/user-panel.component';
import { ContextmenuComponent } from './helpers/contextmenu/contextmenu.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { UserSettingsComponent } from './screens/popups/user-settings/user-settings.component';
import { NewServerComponent } from './screens/popups/new-server/new-server.component';
import { ServersComponent } from './screens/servers/servers.component';
import { AddFriendComponent } from './screens/popups/add-friend/add-friend.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ServerSidebarComponent } from './screens/sidebars/server-sidebar/server-sidebar.component';
import { ArtWallChannelComponent } from './screens/channel-screens/art-wall-channel/art-wall-channel.component';
import { ChannelHeaderComponent } from './screens/headers/channel-header/channel-header.component';
import { ChannelSidePanelComponent } from './screens/sidepanels/channel-side-panel/channel-side-panel.component';
import { NewCategoryComponent } from './screens/popups/new-category/new-category.component';
import { NewChannelComponent } from './screens/popups/new-channel/new-channel.component';
import { ChatChannelComponent } from './screens/channel-screens/chat-channel/chat-channel.component';
import { AutosizeModule } from 'ngx-autosize';
import { ArtBoardChannelComponent } from './screens/channel-screens/art-board-channel/art-board-channel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ArtBoardAddMenuComponent } from './helpers/art-board-add-menu/art-board-add-menu.component';
import { MouseWheelDirective } from './directives/mousewheel.directive';
import { ArtThreadChannelComponent } from './screens/channel-screens/art-thread-channel/art-thread-channel.component';
import { NewAssetComponent } from './screens/popups/new-asset/new-asset.component';
import { PointsToStringPipe } from './pipes/points-to-string.pipe';
import { AutoGrowDirective } from './directives/auto-grow.directive';
import { GeneralChatComponent } from './screens/channel-screens/general-chat/general-chat.component';
import { TitlebarComponent } from './screens/headers/titlebar/titlebar.component';
import { NgxElectronModule } from 'ngx-electron';
import { AssetTabComponent } from './screens/headers/asset-tab/asset-tab.component';
import { AddThreadPostComponent } from './screens/popups/add-thread-post/add-thread-post.component';
import { EditThreadPostComponent } from './screens/popups/edit-thread-post/edit-thread-post.component';
import { DialogComponent } from './screens/popups/dialog/dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { FocusOnCreateDirective } from './directives/focus-on-create.directive';
import { AcceptServerInvitationComponent } from './screens/accept-server-invitation/accept-server-invitation.component';
import { UploadAvatarComponent } from './screens/popups/upload-avatar/upload-avatar.component';
import { HomeComponent } from './home/home.component';
import { ServerComponent } from './server/server.component';
import { ModifyServerComponent } from './screens/popups/modify-server/modify-server.component';
import { ModifyAssetComponent } from './screens/popups/modify-asset/modify-asset.component';
import { ModifyCategoryComponent } from './screens/popups/modify-category/modify-category.component';
import { ModifyChannelComponent } from './screens/popups/modify-channel/modify-channel.component';
import { AssetMainComponent } from './asset-main/asset-main.component';
import { ArtMainComponent } from './art-main/art-main.component';
import { ProfileHoverComponent } from './screens/popups/profile-hover/profile-hover.component';
import { ProfileComponent } from './screens/popups/profile/profile.component';
import { ArtFilesComponent } from './screens/channel-screens/art-files/art-files.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InviteToServerComponent } from './screens/popups/invite-to-server/invite-to-server.component';
import { MultiEmailTextAreaComponent } from './components/multi-email-text-area/multi-email-text-area.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { ProjectMainComponent } from './layouts/project-main/project-main.component';
import { NewProjectComponent } from './screens/popups/new-project/new-project.component';
import { ModifyProjectComponent } from './screens/popups/modify-project/modify-project.component';
import { NewDmComponent } from './screens/popups/new-dm/new-dm.component';

const config: SocketIoConfig = { url: 'http://localhost:4201', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    GeneralChatComponent,
    ComingSoonComponent,
    ForgotPasswordComponent,
    MainComponent,
    ServersComponent,
    HomeSidebarComponent,
    FriendListComponent,
    FriendListHeaderComponent,
    FriendListSidePanelComponent,
    UserPanelComponent,
    ContextmenuComponent,
    UserSettingsComponent,
    NewServerComponent,
    AddFriendComponent,
    ServerSidebarComponent,
    ArtWallChannelComponent,
    ChannelSidePanelComponent,
    NewCategoryComponent,
    NewChannelComponent,
    ChatChannelComponent,
    ChannelHeaderComponent,
    ArtBoardChannelComponent,
    ArtBoardAddMenuComponent,
    MouseWheelDirective,
    ArtThreadChannelComponent,
    NewAssetComponent,
    PointsToStringPipe,
    AutoGrowDirective,
    TitlebarComponent,
    AssetTabComponent,
    AddThreadPostComponent,
    EditThreadPostComponent,
    DialogComponent,
    DragAndDropDirective,
    FocusOnCreateDirective,
    AcceptServerInvitationComponent,
    UploadAvatarComponent,
    HomeComponent,
    ServerComponent,
    ModifyServerComponent,
    ModifyAssetComponent,
    ModifyCategoryComponent,
    ModifyChannelComponent,
    AssetMainComponent,
    ArtMainComponent,
    ProfileHoverComponent,
    ProfileComponent,
    ArtFilesComponent,
    InviteToServerComponent,
    MultiEmailTextAreaComponent,
    ProjectMainComponent,
    NewProjectComponent,
    ModifyProjectComponent,
    NewDmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    SocketIoModule.forRoot(config),
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    ContextMenuModule.forRoot(),
    ClipboardModule,
    AutosizeModule,
    DragDropModule,
    NgxElectronModule,
    MatIconModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressBarModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

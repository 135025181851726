import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ChannelService } from 'src/app/services/api/channel.service';
@Component({
  selector: 'app-home-sidebar',
  templateUrl: './home-sidebar.component.html',
  styleUrls: ['./home-sidebar.component.scss'],
})
export class HomeSidebarComponent implements OnInit {
  @Output()
  selectedChannelState = new EventEmitter<object>();

  @Output()
  freindsListButtonClicked = new EventEmitter<object>();

  eContextTypes = ContextTypes;
  userSubscription;
  // friends;

  selectedChatIndex;
  user;

  constructor(
    private contextMenuService: ContextMenuService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private popupController: PopupControllerService,
    private channelService: ChannelService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.apiService.user.subscribe(async (user) => {
      this.user = user;
      // TODO don't combine requests and sent requests with friends once those flows are complete
      // this.friends = [...user.friends, ...user.requests, ...user.sentRequests]
      // this.cdr.detectChanges();

      // fix up the names of the channels
      for (let i = 0; i < this.user.privateChannels.length; i++) {
        if (this.user.privateChannels[i].users.length == 1) {
          this.user.privateChannels[i].name = 'Alone';
        } else if (this.user.privateChannels[i].users.length == 2) {
          // find the user who isn't you
          const otherUser = this.user.privateChannels[i].users.find(
            (value) => value != this.user.username
          );
          this.user.privateChannels[i].name = otherUser.split('#')[0];
          if (this.user.friends) {
            for (let j = 0; j < this.user.friends.length; j++) {
              if (
                this.user.friends[j].username +
                  '#' +
                  this.user.friends[j].usercode ==
                otherUser
              ) {
                this.user.privateChannels[i].avatarUrl =
                  this.user.friends[j].avatar;
              }
            }
          }
        } else if (this.user.privateChannels[i].users.length > 2) {
          this.user.privateChannels[i].name = 'Group DM';

          // get all usernames from user that are not my id
          let name = '';

          for (let j = 0; j < this.user.privateChannels[i].users.length; j++) {
            if (this.user.privateChannels[i].users[j]._id != this.user._id) {
              const user = this.user.privateChannels[i].users[j];

              // if this user is my user skip
              if (user == this.user.username) continue;
              name += this.user.privateChannels[i].users[j].split('#')[0];
              if (j != this.user.privateChannels[i].users.length - 1) {
                name += ', ';
              }
            }
          }
          this.user.privateChannels[i].name = name;
        }
      }

      // get user shorts
      let usernames = [];

      await Promise.all(
        this.user.friends.map((friend) => {
          const fullUsername = friend.username + '#' + friend.usercode;
          if (!usernames.includes(fullUsername)) usernames.push(fullUsername);
        })
      );

      const userShorts = await this.apiService.getUsersShorts(usernames);

      await Promise.all(
        this.user.friends.map((friend) => {
          const fullUsername = friend.username + '#' + friend.usercode;
          const shortIndex = userShorts.findIndex(
            (value) => value.username == fullUsername
          );
          friend.avatarUrl = userShorts[shortIndex].avatar
            ? 'https://artdominion.imgix.net/' + userShorts[shortIndex].avatar
            : null;
        })
      );
    });
    this.apiService.GetCurrentUser();
  }

  onrightClick(event, type: ContextTypes, channel) {
    console.log(channel);
    this.contextMenuService.show(event.clientX, event.clientY, type, channel);
  }

  navToFriendsList(e) {
    this.selectedChatIndex = null;
    this.router.navigateByUrl('/m/home');

    //this.freindsListButtonClicked.emit(e);
  }

  onChannelSelected(channelId: string, i) {
    this.selectedChatIndex = i;
    this.selectedChannelState.emit({ channelType: 'text', channelId });
    this.router.navigate(['chat', channelId], {
      relativeTo: this.activatedRoute,
    });
  }

  onFriendSelected(friendId: string, i) {
    console.log(friendId);
    const userIds = [friendId, this.user._id];
    userIds.sort();
    const combinedIds = userIds.reduce((acc, val) => `${acc}--${val}`);
    const channelId = `dm_${combinedIds}`;
    this.selectedChatIndex = i;
    this.selectedChannelState.emit({ channelType: 'text', channelId });

    this.router.navigate(['chat', channelId], {
      relativeTo: this.activatedRoute,
    });
  }

  openNewDmPopup() {
    this.popupController.openNewDmPopup();
  }
}

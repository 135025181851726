import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { UserShort } from 'src/app/models/user.model';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-profile-hover',
  templateUrl: './profile-hover.component.html',
  styleUrls: ['./profile-hover.component.scss'],
})
export class ProfileHoverComponent implements OnInit {
  visible: boolean = false;
  x: number = 0;
  y: number = 0;
  userShort: UserShort;

  constructor(
    private popupController: PopupControllerService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.popupController.profileHover.subscribe((data) => {
      if (data === null) {
        this.visible = false;
      } else {
        // get user data data.username
        this.apiService.getUsersShorts([data.username]).then((userShort) => {
          console.log(userShort);
          this.userShort = userShort[0];
        });

        const profileHoverHeight = 440;
        const profileHoverWidth = 350;
        const innerPadding = 40; // the area inside the window that is out of bounds for the popup

        let newX = data.x + 20;
        let newY = data.y - profileHoverHeight / 2;

        if (newY + profileHoverHeight > innerHeight - innerPadding) {
          newY = innerHeight - innerPadding - profileHoverHeight;
        }
        if (newY < innerPadding) {
          newY = innerPadding;
        }

        if (newX + profileHoverWidth > innerWidth - innerPadding) {
          newX = innerWidth - innerPadding - profileHoverWidth;
        }

        if (newX < innerPadding) {
          newX = innerPadding;
        }

        this.x = newX;
        this.y = newY;
        this.visible = true;
      }
    });
  }

  hideProfileHover() {
    this.visible = false;
  }

  openProfile() {
    console.log('openProfile: ' + this.userShort.username);
    this.popupController.openProfile(this.userShort.username);
    this.hideProfileHover();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.hideProfileHover();
    }
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { UserShort } from 'src/app/models/user.model';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  visible: boolean = false;
  userShort: UserShort;

  constructor(
    private popupController: PopupControllerService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.popupController.profile.subscribe((data) => {
      if (data === null) {
        this.visible = false;
      } else {
        // get user data data.username
        this.apiService.getUsersShorts([data.username]).then((userShort) => {
          console.log(userShort);
          this.userShort = userShort[0];
        });
        console.log('profile visible');
        this.visible = true;
      }
    });
  }

  hideProfile() {
    this.visible = false;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.hideProfile();
    }
  }
}

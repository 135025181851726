import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { User } from '../../models/user.model';
import { ApiService } from './api.service';
import { Server } from '../../models/server.model';
import { Subject } from 'rxjs';
import { Channel } from 'src/app/models/channel.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { InviteToken } from 'src/app/models/invite-token.model';

@Injectable({
  providedIn: 'root',
})
export class ServersService {
  private path = '/servers';
  private categoriesPath = '/categories';
  private channelsPath = '/channels';
  private _servers: Server[] = [];

  public servers: Subject<Server[]> = new Subject<Server[]>();

  user: User;

  constructor(private apiService: ApiService, private httpClient: HttpClient) {
    this.apiService.user.subscribe((user) => {
      this.user = user;
    });
    this.apiService.GetCurrentUser();

    //TODO: need a graceful way to update the server list without effecting the users screen
    /*setInterval( () => {
      //this.getUsersOwnedServers();
    }, 30 * 1000);*/
  }

  // TODO: consider making this read through caching strategy
  getServers() {
    return this._servers;
  }

  getServer(serverId): Server | undefined {
    for (let server of this._servers) {
      if (server._id === serverId) {
        return server;
      }
    }
    return undefined;
  }

  getChannel(channelId): Channel | void {
    console.log('getChannel - server service');
    console.log(this._servers);

    // check categories for channels
    for (let server of this._servers) {
      if (server.categories) {
        for (let category of server.categories) {
          if (category.channels) {
            for (let channel of category.channels) {
              if (channelId === channel._id) return channel;
            }
          }
        }
      }
      //check projects for channels too
      if (server.projects) {
        for (let project of server.projects) {
          if (project.channels) {
            for (let channel of project.channels) {
              if (channelId === channel._id) return channel;
            }
          }
        }
      }
    }
  }

  getFirstChannel(serverId): Channel | void {
    //TODO make this wait for severs to be available
    const foundServer = this._servers.find((server) => server._id === serverId);
    if (!foundServer) {
      return undefined;
    }
    return foundServer.categories?.[0]?.channels?.[0];
  }

  async createServer(data: FormGroup): Promise<object> {
    const body = data.getRawValue();

    // if private isn't defined add it but set it to false
    if (body.private) body.private = true;

    debug('running createServer in ServerService');
    //debug('user:'); //DEBUG: this is where the code is breaking

    //debug(this.apiService.user);
    //debug('token:');
    //debug(this.apiService.token);
    if (this.user && this.apiService.token) {
      debug('creating server, user+token');
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this._servers.push(server);
      this.servers.next(this._servers);
      return server;
    }
    return null;
  }

  async getUsersOwnedServers(): Promise<object> {
    if (this.user && this.apiService.token) {
      this.httpClient
        .get<Server[]>(
          `${this.apiService.URI}${this.path}`,
          this.apiService.options
        )
        .subscribe((serverArray) => {
          this._servers = serverArray as unknown as Server[];
          console.log('servers: ');
          console.log(this._servers);
          this._servers.forEach((server) => {
            server.isInvitedOnly = server.invitations.includes(
              this.user.username
            );

            // get channels and projects too
          });
          this.servers.next(this._servers);
        });

      return this._servers;

      /* 
      const servers = await this.httpClient
        .get<Server[]>(
          `${this.apiService.URI}${this.path}`,
          this.apiService.options
        )
        .toPromise();
      this._servers = servers as unknown as Server[];
      this._servers.forEach((server) => {
        server.isInvitedOnly = server.invitations.includes(this.user.username);
      });
      this.servers.next(this._servers);
      return servers;*/
    }
    return null;
  }

  async createCategory(data: FormGroup): Promise<object> {
    const body = data.getRawValue();
    debug('private: ' + body.private);

    body.private = body.private === true ? true : false;

    debug('running createCategory in ServerService');
    if (this.user && this.apiService.token) {
      debug('creating Category');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.categoriesPath}`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async patchCategory(
    data: FormGroup,
    serverId: string,
    categoryId: string
  ): Promise<object> {
    const body = data.getRawValue();
    if (this.user && this.apiService.token) {
      const response = await this.httpClient
        .patch<Server>(
          `${this.apiService.URI}${this.categoriesPath}/${categoryId}`,
          body,
          this.apiService.options
        )
        .toPromise();
      return response;
    } else {
      return null;
    }
  }

  async deleteCategory(serverId: string, categoryId: string): Promise<object> {
    debug('serverId: ' + serverId);
    debug('categoryId: ' + categoryId);
    if (this.user && this.apiService.token) {
      const server = (await this.httpClient
        .delete<Server>(
          `${this.apiService.URI}${this.categoriesPath}/${categoryId}`,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async createChannel(data: FormGroup): Promise<object> {
    const body = data.getRawValue();
    body.private = body.private === true ? true : false;
    if (this.user && this.apiService.token) {
      debug('creating Channel');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.channelsPath}`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async deleteChannel(serverId: string, channelId: string): Promise<object> {
    debug('serverId: ' + serverId);
    debug('channelId: ' + channelId);
    if (this.user && this.apiService.token) {
      const server = (await this.httpClient
        .delete<Server>(
          `${this.apiService.URI}${this.channelsPath}/${channelId}`,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async patchChannel(
    data: FormGroup,
    serverId: string,
    categoryId: string,
    channelId: string
  ): Promise<object> {
    const body = data.getRawValue();
    if (this.user && this.apiService.token) {
      const response = await this.httpClient
        .patch<Server>(
          `${this.apiService.URI}${this.channelsPath}/${channelId}`,
          body,
          this.apiService.options
        )
        .toPromise();
      return response;
    } else {
      return null;
    }
  }

  async inviteToServer(username: string, serverId: string): Promise<object> {
    // must be server owner
    const body = { username, serverId };
    if (this.user && this.apiService.token) {
      debug('inviting user to server');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}/invite`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async inviteMultipleEmailsToServer(
    serverId: string,
    emails: string[]
  ): Promise<object> {
    // must be server owner
    const body = { emails, serverId };
    if (this.user && this.apiService.token) {
      debug('inviting multiple emails to server');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}/inviteMultiple`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async createServerLinkToken(serverId: string): Promise<InviteToken> {
    const body = { serverId };
    if (this.user && this.apiService.token) {
      const token = (await this.httpClient
        .post<InviteToken>(
          `${this.apiService.URI}${this.path}/createInviteLink`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as InviteToken;
      return token;
    }
    return null;
  }

  async joinServerWithInviteLink(token: string): Promise<Server> {
    const body = { token };
    if (this.user && this.apiService.token) {
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}/joinInviteLink`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async removeInvitation(serverId: string, username?: string): Promise<object> {
    // must be the server owner
    const body = { serverId, username };
    if (this.user && this.apiService.token) {
      debug('Removing users invitation');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}/removeInvite`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async joinServer(serverId: string): Promise<object> {
    // must be the user joining the server
    const body = { serverId };
    if (this.user && this.apiService.token) {
      debug('Joining Server');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}/join`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      this.getUsersOwnedServers();
      return server;
    }
    return null;
  }

  async leaveServer(serverId: string, username?: string): Promise<object> {
    // can be either the member wanting to leave or the owner of the server
    const body = { serverId, username };
    if (this.user && this.apiService.token) {
      debug('Leaving server or kicking user from server');
      debug('data packet: ');
      debug(body);
      const server = (await this.httpClient
        .post<Server>(
          `${this.apiService.URI}${this.path}/leave`,
          body,
          this.apiService.options
        )
        .toPromise()) as unknown as Server;
      if (!username) {
        this.getUsersOwnedServers();
      }
      return server;
    }
    return null;
  }

  async deleteServer(serverId: string): Promise<object> {
    if (this.user && this.apiService.token) {
      const response = await this.httpClient
        .delete<Server>(
          `${this.apiService.URI}${this.path}/${serverId}`,
          this.apiService.options
        )
        .toPromise();
      this.getUsersOwnedServers();
      return response;
    }
    return null;
  }

  async patchServer(data: FormGroup, serverId: string): Promise<object> {
    const body = data.getRawValue();
    if (this.user && this.apiService.token) {
      const response = await this.httpClient
        .patch<Server>(
          `${this.apiService.URI}${this.path}/${serverId}`,
          body,
          this.apiService.options
        )
        .toPromise();
      return response;
    } else {
      return null;
    }
  }
}

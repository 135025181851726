/* for Callback actions */
export enum ActionEventType {
  DRAW,
  SELECT,
}

export type ActionEvent = {
  action: ActionEventType;
  value: any;
};

export type DrawActionValue = {
  messageId: string;
  channelId: string;
  owner: string;
};

export type SelectActionValue = {
  messageId: string;
  channelId: string;
  owner: string;
};

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { fFile } from '../../models/ffile.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';

export enum FileTypes {
  IMAGES = 'images',
  AVATAR = 'avatar',
}
@Injectable({
  providedIn: 'root',
})
export class FileService {
  private path = '/files';
  public assetUploadEvent: Subject<fFile> = new Subject<fFile>();

  constructor(private apiService: ApiService, private httpClient: HttpClient) {}

  getPresignedUrl(
    type: string,
    filename: string,
    fileType: string,
    width: number,
    height: number
  ): Promise<any> {
    let getheaders = this.apiService.options;
    // type: "images",
    const body = {
      type,
      filename,
      fileType,
      width,
      height,
    };
    return this.httpClient
      .post<any>(
        `${this.apiService.URI}${this.path}/register`,
        body,
        getheaders
      )
      .toPromise();
  }

  uploadToS3(presignUrl: string, contentType: string, file: File) {
    debug('uploading to S3');
    const headers = new HttpHeaders({ 'Content-Type': contentType });
    const request = new HttpRequest('PUT', presignUrl, file, {
      headers: headers,
      reportProgress: true,
    });
    return this.httpClient.request(request);
  }

  async confirmFileUpload(assetId: string): Promise<boolean> {
    debug('Confirming upload to api');
    let getheaders = this.apiService.options;
    const body = {
      _id: assetId,
    };
    return this.httpClient
      .post<any>(
        `${this.apiService.URI}${this.path}/complete`,
        body,
        getheaders
      )
      .toPromise()
      .then((confirmationResponse) => {
        if (confirmationResponse) {
          debug('Confirmation Response');
          const uploadedAsset: fFile = confirmationResponse as unknown as fFile;
          debug(uploadedAsset);
          this.assetUploadEvent.next(uploadedAsset);
        }
        return true;
      })
      .catch((e) => {
        error('error confirming image upload: ' + e);
        return false;
      });
  }
}

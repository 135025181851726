import { Component, Input, OnInit } from '@angular/core';
import { Server } from 'src/app/models/server.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Component({
  selector: 'app-channel-side-panel',
  templateUrl: './channel-side-panel.component.html',
  styleUrls: ['./channel-side-panel.component.scss'],
})
export class ChannelSidePanelComponent implements OnInit {
  @Input()
  selectedServer: Server;

  @Input()
  selectedChannel;

  constructor() {}

  ngOnInit(): void {
    console.log('Channel-side-pannel ngOnInit: selected channel');
    console.log(this.selectedChannel);
  }
}

import { Component, OnInit } from '@angular/core';
import { NgxElectronModule } from 'ngx-electron';
import { ElectronService } from 'ngx-electron';
import {debug, warn, error, info} from 'src/app/services/logger.service';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']
})
export class TitlebarComponent implements OnInit {
  showMaxButton: boolean;
  isAnElectronApp: boolean;
  isMac: Boolean = false;
  isMaximized: Boolean;
  window;

  constructor(private electronService: ElectronService) { 
    // BrowserWindow.getFocusedWindow()
    this.isAnElectronApp = this.electronService.isElectronApp;
    if (this.isAnElectronApp) {
      this.window = this.electronService.remote.getCurrentWindow();
      this.isMac = this.electronService.isMacOS;
      this.isMaximized = false;
    }
    debug(this.isAnElectronApp);
  }

  ngOnInit(): void {
  }

  minimize() {
    this.window.minimize();
    debug('minimize');
  }
  maximize() {
    this.window.maximize();
    this.isMaximized = true;
    debug('maximize');
  }
  restore() {
    this.window.restore();
    this.isMaximized = false;
    debug('restore');
  }
  close() {
    this.window.close();
    debug('closing');
  }

}

<div class="sub-main">
  <app-friend-list-header
    [currentFriendState]="friendState"
    (friendState)="changeFriendState($event)"
  ></app-friend-list-header>
  <div class="chat scroll">
    <div *ngIf="friendState == 'sent-requests'">
      <div class="friend-list" *ngIf="sentRequests.length > 0">
        <div class="heading">
          Sent Friend Requests
          <!-- - 5 -->
        </div>
        <ul>
          <li
            *ngFor="let sentRequest of sentRequests"
            (contextmenu)="
              openContextMenu(
                $event,
                eContextTypes.sentFriendRequest,
                sentRequest.username + '#' + sentRequest.usercode
              )
            "
          >
            <div class="pic-col">
              <img
                *ngIf="sentRequest && sentRequest.avatarUrl"
                src="{{ sentRequest.avatarUrl }}"
              />
              <div
                *ngIf="!sentRequest || !sentRequest.avatarUrl"
                class="no-avatar"
              >
                <div *ngIf="sentRequest" class="initials">
                  {{ sentRequest.username.slice(0, 2) }}
                </div>
              </div>
            </div>
            <div class="name-col">
              <div *ngIf="sentRequest" class="name">
                {{ sentRequest.username
                }}<span class="tag">#{{ sentRequest.usercode }}</span>
              </div>
              <div *ngIf="sentRequest" class="status">
                {{ sentRequest.tagline }}
              </div>
            </div>
            <div class="buttons-col">
              <!--
              <div class="button" placement="top" ngbTooltip="More" (mouseup)="openContextMenu($event,eContextTypes.friendList, sentRequest._id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
              </div>
            -->
              <div class="button" placement="top" ngbTooltip="Message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chat-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="friendState == 'sent-requests'">
      <div class="friend-list" *ngIf="requests.length > 0">
        <div class="heading">
          Friend Requests
          <!-- - 5 -->
        </div>
        <ul>
          <li
            *ngFor="let request of requests"
            (contextmenu)="
              openContextMenu(
                $event,
                eContextTypes.friendRequest,
                request.username + '#' + request.usercode
              )
            "
          >
            <div class="pic-col">
              <img
                *ngIf="request && request.avatarUrl"
                src="{{ request.avatarUrl }}"
              />
              <div *ngIf="!request || !request.avatarUrl" class="no-avatar">
                <div *ngIf="request" class="initials">
                  {{ request.username.slice(0, 2) }}
                </div>
              </div>
            </div>
            <div class="name-col">
              <div *ngIf="request" class="name">
                {{ request.username
                }}<span class="tag">#{{ request.usercode }}</span>
              </div>
              <div *ngIf="request" class="status">{{ request.tagline }}</div>
            </div>
            <div class="buttons-col">
              <!--
              <div class="button" placement="top" ngbTooltip="More">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
              </div>
            -->
              <div class="button" placement="top" ngbTooltip="Message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chat-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="friendState == 'blocked'">
      <div class="friend-list" *ngIf="blocked.length > 0">
        <div class="heading">
          Blocked Users
          <!-- - 5 -->
        </div>
        <ul>
          <li
            *ngFor="let blockedUser of blocked"
            (contextmenu)="
              openContextMenu(
                $event,
                eContextTypes.blockedUsers,
                blockedUser.username + '#' + blockedUser.usercode
              )
            "
          >
            <div class="pic-col">
              <img
                *ngIf="blockedUser && blockedUser.avatarUrl"
                src="{{ blockedUser.avatarUrl }}"
              />
              <div
                *ngIf="!blockedUser || !blockedUser.avatarUrl"
                class="no-avatar"
              >
                <div *ngIf="blockedUser" class="initials">
                  {{ blockedUser.username.slice(0, 2) }}
                </div>
              </div>
            </div>
            <div class="name-col">
              <div *ngIf="blockedUser" class="name">
                {{ blockedUser.username
                }}<span class="tag">#{{ blockedUser.usercode }}</span>
              </div>
              <div *ngIf="blockedUser" class="status">
                {{ blockedUser.tagline }}
              </div>
            </div>
            <div class="buttons-col">
              <div class="button" placement="top" ngbTooltip="Message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chat-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="friendState == 'online' || friendState == 'all'">
      <div class="friend-list" *ngIf="friends.length > 0">
        <div class="heading">
          Friends
          <!-- - 5 -->
        </div>
        <ul>
          <li
            *ngFor="let friend of friends"
            (contextmenu)="
              openContextMenu(
                $event,
                eContextTypes.friendList,
                friend.username + '#' + friend.usercode
              )
            "
          >
            <div class="pic-col">
              <!--<img *ngIf="friend && friend.avatarUrl" src="{{ friend.avatarUrl }}" /> -->

              <img
                *ngIf="friend && friend.avatarUrl"
                src="{{ friend.avatarUrl }}"
              />
              <div *ngIf="!friend || !friend.avatarUrl" class="no-avatar">
                <div *ngIf="friend" class="initials">
                  {{ friend.username.slice(0, 2) }}
                </div>
              </div>
            </div>
            <div class="name-col">
              <div *ngIf="friend" class="name">
                {{ friend.username
                }}<span class="tag">#{{ friend.usercode }}</span>
              </div>
              <div *ngIf="friend" class="status">{{ friend.tagline }}</div>
            </div>
            <div class="buttons-col">
              <div class="button" placement="top" ngbTooltip="Message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chat-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-friend-list-side-panel></app-friend-list-side-panel>
</div>

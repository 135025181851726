<div class="fullscreen" [class.scalein]="visible" [class.scaleout]="!visible">
  <div class="left scrollable">
    <div class="left-text-col">
      <h1>User Settings</h1>
      <div class="buttons-col">
        <div class="button" [class.selected]="selectedTab === 'profile'">
          Profile
        </div>
        <div class="button danger" (click)="logout()">Log Out</div>
      </div>
    </div>
  </div>
  <div class="right scrollable">
    <div class="right-text-col">
      <div class="detail-wrapper" *ngIf="selectedTab === 'profile'">
        <h1>Profile</h1>
        <div class="profile-details">Details</div>
        <div class="profile">
          <div class="profile-header">
            <div class="profile-header-hover">CHANGE BANNER</div>
          </div>
          <div class="profile-img-status">
            <div class="img-wrapper">
              <img
                *ngIf="user"
                [src]="'https://artdominion.imgix.net/' + user.avatar"
              />
              <div class="view-profile-hover">CHANGE AVATAR</div>
              <div class="online-status-wrapper">
                <div
                  class="online-status"
                  placement="top"
                  ngbTooltip="Online"
                ></div>
              </div>
            </div>
          </div>
          <div class="profile-body-wrapper">
            <div class="profile-body">
              <div class="profile-username" *ngIf="user">
                {{ user.username ? user.username.split("#")[0] : "" }}
                <div class="digits">
                  #{{ user.username ? user.username.split("#")[1] : "" }}
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="closeWindow" (click)="closeWindow()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="currentColor"
      class="bi bi-x-circle"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  </div>
</div>

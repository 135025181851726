import ToolMode from "src/app/enums/tool-mode.enum";
import Point from "../point.model";
import SvgElement from "./svg-element.class"


// this object draws an svg polygon
export class PolygonElement extends SvgElement {
  
  public elType = ToolMode.POLYGON_TOOL;
  public points: Point[];

  constructor(creationPoint: Point, screenSpace: Point) {
    super();
    this.points = [];
    this.x = creationPoint.x / screenSpace.x;
    this.y = creationPoint.y / screenSpace.y;
    this.AddPoint(creationPoint, screenSpace);
  }

  public AddPoint(point: Point, screenSpace: Point) {
    /*
        This needs an explination
        So because we have to use an angular pipe to display the points into a polygon svg 
        if we use a pure pipe, it won't trigger unless the reference of the array is changed
        in other words using push and pop wont trigger the pipe and the data won't be updated on screen
        I could use an impure pipe but this will update far too often and with how complicated the pipe is
        it will effect performance
        So instead, I'm flipping a dirty flag that is added to the pipe, I know, I know it's super dirty
        but it's far faster than anything else I could think of lol
     */
    this.points.push({
      x: (point.x / screenSpace.x) - this.x,
      y: (point.y / screenSpace.y) - this.y,
    });
    this.dirty = !this.dirty;
  }
  
}

export default PolygonElement;

<!-- This cannot be a text area, it has to be a div with buttons as each email address and an array of email addresses where each time space or comma is pressed it adds the email to the array -->

<mat-form-field class="email-chip-list">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let email of _emails"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(email)"
      [ngClass]="{ warn: email.invalid, ok: !email.invalid }"
    >
      {{ email.email }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      (paste)="checkInputForEmails($event.clipboardData.getData('text/plain'))"
    />
  </mat-chip-list>
</mat-form-field>

<div class="sidebar">
  <input type="text" class="sidebar-search" placeholder="Search ..." />
  <hr />
  <div class="scroll">
    <ul class="list-group">
      <li
        [class.selected]="selectedChatIndex == null"
        (click)="navToFriendsList($event)"
      >
        <div class="friend-list-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            />
          </svg>
        </div>
        <div class="friend-list-text">Friends</div>
      </li>
    </ul>

    <div class="sidebar-heading">
      DIRECT MESSAGES
      <span
        class="add"
        data-toggle="tooltip"
        placement="top"
        ngbTooltip="Create Room"
        (click)="openNewDmPopup()"
        >+</span
      >
    </div>

    <ul class="list-group" *ngIf="user">
      <!--
      <li
        *ngFor="let friend of user.friends; let i = index"
        [class.selected]="selectedChatIndex == i"
        (click)="onFriendSelected(friend._id, i)"
        (contextmenu)="onrightClick($event, eContextTypes.friendChat, 234)"
      >
        <div *ngIf="friend && friend.avatarUrl" class="friend-list-icon">
          <img [src]="friend.avatarUrl" />
        </div>

        <div *ngIf="!friend || !friend.avatarUrl" class="no-avatar">
          <div *ngIf="friend" class="initials">
            {{ friend.username.slice(0, 2) | uppercase }}
          </div>
        </div>

        <div class="friend-list-username" *ngIf="friend">
          {{ friend.username }}
        </div>
      </li>-->
      <li
        *ngFor="let channel of user.privateChannels; let i = index"
        [class.selected]="selectedChatIndex == i"
        (click)="onChannelSelected(channel._id, i)"
        (contextmenu)="onrightClick($event, eContextTypes.friendChat, channel)"
      >
        <!--
        If there are 2 users in a channel, the avatar should be the other user's avatar.
        If there are more than 2 users in a channel, the avatar should be a multi-user avatar.
      -->
        <div *ngIf="channel && channel.avatarUrl" class="friend-list-icon">
          <img [src]="'https://artdominion.imgix.net/' + channel.avatarUrl" />
        </div>

        <div *ngIf="!channel || !channel.avatarUrl" class="no-avatar">
          <div *ngIf="channel && channel.name != 'Alone'" class="initials">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.05em"
              viewBox="0 0 640 512"
              class="default-group-avatar"
            >
              <path
                d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"
              />
            </svg>
          </div>
          <div *ngIf="channel && channel.name == 'Alone'" class="initials">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.40em"
              viewBox="0 0 320 512"
              class="default-group-avatar"
              style="padding-left: 3px"
            >
              <path
                d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z"
              />
            </svg>
          </div>
        </div>

        <div class="friend-list-username">
          {{ channel.name }}
        </div>
      </li>
    </ul>
  </div>
</div>

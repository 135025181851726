<div class="user-panel">
  <div class="image-col">
    <div
      *ngIf="user && user.avatarUrl"
      class="img"
      (click)="onAvartarClick()"
      [ngStyle]="{
        'background-image': 'url(' + user.avatarUrl + '?w=33&h=33)'
      }"
    >
      <div class="image-col-top"></div>
    </div>
    <div
      *ngIf="!user || !user.avatarUrl"
      class="img no-avatar"
      (click)="onAvartarClick()"
    >
      <div class="image-col-top"></div>
      <div *ngIf="user" class="initials">
        {{ (user.username ? user.username.slice(0, 2) : "") | uppercase }}
      </div>
    </div>
  </div>
  <div class="text-col">
    <div class="username">{{ username }}</div>
    <div class="username-code">#{{ usercode }}</div>
  </div>
  <div class="buttons-col">
    <div
      class="button"
      placement="top"
      ngbTooltip="User Settings"
      (click)="onSettingsClick()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="currentColor"
        class="bi bi-gear-fill settings-icon"
        viewBox="0 0 16 16"
      >
        <path
          d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
        />
      </svg>
    </div>
  </div>
</div>

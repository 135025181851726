<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <div class="invite-sent" *ngIf="invitesSent">
          <div class="invite-sent-message">Invitations Sent</div>
        </div>
        <h1>Invite To {{ server?.name }} Server</h1>
        <!--<h3>Email Addresses</h3>-->
        <form [formGroup]="form">
          <app-multi-email-text-area
            placeholder="Email Addresses"
            formControlName="emails"
            (emails)="updateEmails($event)"
          ></app-multi-email-text-area>
          <div class="form-group">
            <button
              class="btn btn-block invite-btn"
              type="submit"
              name="submitButton"
              ngDefaultControl
              formControlName="submitButton"
              (click)="invite()"
            >
              <div class="btnText">Invite</div>
            </button>
          </div>
        </form>
        <hr />

        <h3>Invite Link</h3>

        <div class="form-group">
          <div
            *ngIf="!invitationLink"
            class="invite-cover"
            (click)="createInviteLink()"
          >
            Create Server Invite Link
          </div>
          <div
            *ngIf="invitationLink"
            class="invite-text"
            (click)="copyInviteToClipboard()"
          >
            <input
              disabled
              class="inviteText"
              type="text"
              [value]="invitationLink.token || ''"
            />
          </div>

          <button
            class="invite-button"
            (click)="copyInviteToClipboard()"
            mat-raised-button
            color="primary"
          >
            COPY
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

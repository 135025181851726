export enum EChannelType {
  TEXT = 'text',
  ART_WALL = 'art-wall',
}

export interface Channel {
  _id?: string;
  categoryId?: string;
  projectId?: string;
  name: string;
  owner: string;
  channelType: EChannelType;
  private: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public selectedChannelId: string = '';

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((routerParams) => {
      //console.log('================ HOME ACTIVATED ROUTE ');
      //console.log(routerParams);
      const { serverId, chatId } = routerParams;
      this.selectedChannelId = chatId;
      //console.log(chatId);
      //if (serverId === 'home') {
      //}
    });
  }

  ngOnInit(): void {
    console.log('INIT home');
  }
}

<div class="fullscreen" id="fullscreen" [class.fadein]="visible" [class.fadeout]="!visible" (click)="closeWindow($event)">
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div class="popup-box" [class.scalein]="visible" [class.scaleout]="!visible">
        <div class="popup-title-bar">
          <span class="heading">UPDATE POST MESSGAE</span>
          <button type="button" title="close" class="right-alight-titlebar" (click)="closeWindow()">
            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </button>
        </div>
        <form [formGroup]="editPostForm" (ngSubmit)="onSubmit()">
          <div class="form-group">

              <div class="thumbnail center">
                <img alt="post image" *ngIf="selectedImage" [src]="selectedImageSrc"/>
                <!--
                <label for="imageUrl">
                  
                  <div class="thumbnail-overlay" >
                      <span>UPLOAD<br>IMAGE</span>
                  </div>
                  <div class="thumbnail-overlay-main" >
                    <span>IMAGE</span>
                  </div>
                </label>-->

              </div>
            <!--<input  (change)="onFileUpload($event)" 
                    type="file" 
                    accept=".png,.jpg,.gif" 
                    name="thumbnail" 
                    id="imageUrl" 
                    style="display: none;"/>-->
            
            <div class="main-form">
              <textarea placeholder="MESSAGE" name="caption" (keyup)="updateFormValidation()" formControlName="caption">
              </textarea>
            </div>



            <!--<div class="private-description">
              A private channel only allows members you add with the roles you select to have access.
            </div>-->
          </div>
          <button class="btn btn-block" type="submit" name="submitButton" formControlName="submitButton" ngDefaultControl>
            <div class="btnText">UPLOAD POST</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

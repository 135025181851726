import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { FileService, FileTypes } from 'src/app/services/api/file.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-new-asset',
  templateUrl: './new-asset.component.html',
  styleUrls: ['./new-asset.component.scss'],
})
export class NewAssetComponent implements OnInit {
  visible = false;
  selectedImage;
  selectedImageSrc: string;
  channelId: string;

  public addAssetForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    serverId: new FormControl(''),
    channelId: new FormControl(''),
    owner: new FormControl(''),
    thumbnail: new FormControl(''),
    submitButton: new FormControl(''),
  });

  constructor(
    private popupController: PopupControllerService,
    private serversService: ServersService,
    private assetsService: AssetsService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.addAssetForm.get('submitButton')?.disable();
    this.popupController.newAsset.subscribe((data: any) => {
      debug('Reloading New Category Popup');
      if (data.serverId == null) {
        this.visible = false;
      } else {
        this.visible = true;
      }

      this.channelId = data.channelId;
      this.addAssetForm.get('serverId')?.setValue(data.serverId);
      this.addAssetForm.get('owner')?.setValue(data.owner);
      this.addAssetForm.get('channelId')?.setValue(data.channelId);
      debug('new asset popup - ServerId: ' + data.serverId);
    });
  }

  onSubmit() {
    debug('Sending Create Asset Request. Data:');
    debug(this.addAssetForm.getRawValue());

    if (this.selectedImage) {
      const img = new Image();
      console.log(this.selectedImage);
      img.src = URL.createObjectURL(this.selectedImage);
      img.onload = (e: any) => {
        const width = e.target.width;
        const height = e.target.height;

        // upload selected image
        this.fileService
          .getPresignedUrl(
            FileTypes.IMAGES,
            this.selectedImage.name,
            this.selectedImage.type,
            width,
            height
          )
          .then((response) => {
            debug('recieved response');
            this.addAssetForm.get('thumbnail')?.setValue(response.filename);

            debug(response);
            if (response.url) {
              debug('recieved presigned url');
              const fileUploadUrl = response.url;
              const imageForm = new FormData();
              imageForm.append('file', this.selectedImage);
              this.fileService
                .uploadToS3(
                  fileUploadUrl,
                  this.selectedImage.type,
                  this.selectedImage
                )
                .subscribe(async (uploadResponse) => {
                  debug('upload resolution');
                  debug(uploadResponse);
                  if (uploadResponse && uploadResponse.type == 4) {
                    this.fileService
                      .confirmFileUpload(response._id)
                      .then((confirmResponse) => {
                        debug('thumbnail upload confirmed');
                        debug(confirmResponse);

                        this.createAsset();
                      });
                  }
                });
            }
          });
      };
    } else {
      this.createAsset();
    }
  }

  createAsset() {
    this.assetsService
      .CreateAsset(this.addAssetForm)
      .then((response) => {
        debug('Create Asset Response:');
        debug(response);
        console.log('the response', response);
        this.killWindow();
      })
      .catch((error) => {
        error('Create Asset Error:' + error);
      });
  }

  async updateFormValidation() {
    if (this.addAssetForm.valid) {
      this.addAssetForm.get('submitButton')?.enable();
    } else {
      this.addAssetForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.assetsService.GetAllChannelAssets(this.channelId);
    this.visible = false;
    this.selectedImage = null;
    this.selectedImageSrc = null;
    this.addAssetForm.reset();
    this.updateFormValidation();
  }

  onFileUpload(event) {
    debug(event);
    debug('selected new image');
    const image = (event.target as HTMLInputElement).files[0];

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedImageSrc = reader.result as string;
      };
    }

    this.selectedImage = image;
    debug(this.selectedImage);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

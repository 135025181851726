import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLocationResolverService {
  debug = true;

  private _activeAssetTab;
  public activeAssetTabSub = new BehaviorSubject<string | undefined>('');

  private _activeAssetId;
  public activeAssetIdSub = new BehaviorSubject<string | undefined>('');

  private _activeChannelId;
  public activeChannelIdSub = new BehaviorSubject<string | undefined>('');

  private _activeServerId;
  public activeServerIdSub = new BehaviorSubject<string | undefined>('');

  private _activeProjectId;
  public activeProjectIdSub = new BehaviorSubject<string | undefined>('');

  private _activeCategoryId;
  public activeCategoryIdSub = new BehaviorSubject<string | undefined>('');

  private _activeChannelType;
  public activeChannelTypeSub = new BehaviorSubject<string | undefined>('');

  constructor() {}

  public getActiveChannelType() {
    return this._activeChannelType;
  }

  public setActiveChannelType(location: string, activeChannelType: string) {
    if (this.debug) {
      console.log(
        'Setting active channel type to: ' +
          activeChannelType +
          ' from: ' +
          location
      );
      if (activeChannelType == null) {
        console.log(
          '--== ' +
            location +
            ' IS SETTING THE ACTIVE CHANNEL TYPE TO NULL ==--'
        );
      }
    }
    this._activeChannelType = activeChannelType;
    this.activeChannelTypeSub.next(this._activeChannelType);
  }

  public getActiveAssetTab() {
    return this._activeAssetTab;
  }

  public setActiveAssetTab(location: string, assetTab: string) {
    if (this.debug) {
      console.log(
        'Setting active asset tab to: ' + assetTab + ' from: ' + location
      );
      if (assetTab == null) {
        console.log(
          '--== ' + location + ' IS SETTING THE ASSET TAB TO NULL ==--'
        );
      }
    }
    this._activeAssetTab = assetTab;
    this.activeAssetTabSub.next(this._activeAssetTab);
  }

  public getActiveAssetId() {
    return this._activeAssetId;
  }

  public setActiveAssetId(location: string, assetId: string) {
    if (this.debug) {
      console.log(
        'Setting active asset id to: ' + assetId + ' from: ' + location
      );
      if (assetId == null) {
        console.log(
          '--== ' + location + ' IS SETTING THE ACTIVE ASSET ID TO NULL ==--'
        );
      }
    }
    this._activeAssetId = assetId;
    this.activeAssetIdSub.next(this._activeAssetId);
  }

  public getActiveChannelId() {
    return this._activeChannelId;
  }

  public setActiveChannelId(location: string, channelId: string) {
    if (this.debug) {
      console.log(
        'Setting active channel id to: ' + channelId + ' from: ' + location
      );
      if (channelId == null) {
        console.log(
          '--== ' + location + ' IS SETTING THE ACTIVE CHANNEL ID TO NULL ==--'
        );
      }
    }
    this._activeChannelId = channelId;
    this.activeChannelIdSub.next(this._activeChannelId);
  }

  public getActiveServerId() {
    if (this.debug)
      console.log('Getting active server id: ' + this._activeServerId);
    return this._activeServerId || 'home';
  }

  public setActiveServerId(location: string, serverId: string) {
    if (this.debug) {
      console.log(
        'Setting active server id to: ' + serverId + ' from: ' + location
      );
      if (serverId == null) {
        console.log(
          '--== ' + location + ' IS SETTING THE ACTIVE SERVER ID TO NULL ==--'
        );
      }
    }
    this._activeServerId = serverId;
    this.activeServerIdSub.next(this._activeServerId);
  }

  public getActiveProjectId() {
    if (this.debug)
      console.log('Getting active project id: ' + this._activeProjectId);
    return this._activeProjectId;
  }

  public setActiveProjectId(location: string, projectId: string) {
    if (this.debug) {
      console.log(
        'Setting active project id to: ' + projectId + ' from: ' + location
      );
      if (projectId == null) {
        console.log(
          '--== ' + location + ' IS SETTING THE ACTIVE PROJECT ID TO NULL ==--'
        );
      }
    }
    this._activeProjectId = projectId;
    this.activeProjectIdSub.next(this._activeProjectId);
  }

  public getActiveCategoryId() {
    if (this.debug)
      console.log('Getting active category id: ' + this._activeCategoryId);
    return this._activeCategoryId;
  }

  public setActiveCategoryId(location: string, categoryId: string) {
    if (this.debug) {
      console.log(
        'Setting active category id to: ' + categoryId + ' from: ' + location
      );
      if (categoryId == null) {
        console.log(
          '--== ' + location + ' IS SETTING THE CATEGORY ID TO NULL ==--'
        );
      }
    }
    this._activeCategoryId = categoryId;
    this.activeCategoryIdSub.next(this._activeCategoryId);
  }
}

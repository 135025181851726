<div
  class="screen-container"
  (click)="disableContextMenu()"
  [class.contextmenu-fadein]="visible"
  [class.contextmenu-fadeout]="!visible"
>
  <div class="contextmenu" [ngStyle]="{ 'left.px': x, 'top.px': y }">
    <!-- *ngIf="visible" -->
    <div class="contextmenu-content">
      <ul *ngIf="type === eContextTypes.sentFriendRequest">
        <li (click)="removeSentRequest(data.username)">Remove Request</li>
        <li (click)="blockUser(data.username)">Block</li>
      </ul>

      <ul *ngIf="type === eContextTypes.friendRequest">
        <li (click)="acceptFreindRequest(data.username)">
          Accept Friend Request
        </li>
        <li (click)="blockUser(data.username)">Block</li>
      </ul>

      <ul *ngIf="type === eContextTypes.blockedUsers">
        <li (click)="unblockUser(data.username)">Unblock</li>
      </ul>

      <!-- Friends List -->
      <ul *ngIf="type === eContextTypes.friendList">
        <!-- eContextTypes.friendList -->
        <!--
        <li>Profile</li>
        <li>Message</li>
        <li>Call</li>
        <li>Add Note</li>
        <hr/>
        -->
        <li>
          Invite to Server
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
          <ul>
            <div *ngFor="let server of servers">
              <li
                *ngIf="
                  server.owner == user._id &&
                  !server.members.includes(data.username) &&
                  !server.invitations.includes(data.username)
                "
                (click)="inviteToSerer(server._id, data.username)"
              >
                {{ server.name }}
              </li>
            </div>
          </ul>
        </li>
        <li (click)="removeFriend(data.username)">Remove Friend</li>
        <li (click)="blockUser(data.username)">Block</li>
        <hr />
        <li (click)="copyText(data.id)">Copy ID</li>
      </ul>
      <!-- Friend List END -->

      <!-- Channel List -->
      <ul *ngIf="type === eContextTypes.channelList">
        <li (click)="createCategory(data.serverId, data.owner)">
          Create Category
        </li>
      </ul>
      <!-- Channel List END -->

      <!-- Channel Category -->
      <ul *ngIf="type === eContextTypes.channel_category">
        <li
          (click)="
            createChannel(
              data.serverId,
              data.category,
              eChannelParentIdType.CATEGORY,
              data.owner
            )
          "
        >
          Create Channel
        </li>
        <li (click)="createCategory(data.serverId, data.owner)">
          Create Category
        </li>
        <hr />
        <li
          *ngIf="data.isOwner"
          (click)="modifyChannel(data.serverId, data.category, data.channel)"
        >
          Modify Channel
        </li>
        <hr />
        <li
          class="danger"
          *ngIf="data.isOwner"
          (click)="deleteChannel(data.serverId, data.channel._id)"
        >
          Delete Channel
        </li>
        <!--<li>Invite to Server</li>-->
      </ul>
      <!-- Channel Category END -->

      <!-- Channel Project -->
      <ul *ngIf="type === eContextTypes.channel_project">
        <li
          (click)="
            createChannel(
              data.serverId,
              data.project,
              eChannelParentIdType.PROJECT,
              data.owner
            )
          "
        >
          Create Channel
        </li>
        <!--<li (click)="createCategory(data.serverId, data.owner)">
          Create Category
        </li>-->
        <hr />
        <li
          *ngIf="data.isOwner"
          (click)="modifyChannel(data.serverId, data.project, data.channel)"
        >
          Modify Channel
        </li>
        <hr />
        <li
          class="danger"
          *ngIf="data.isOwner"
          (click)="deleteChannel(data.serverId, data.channel._id)"
        >
          Delete Channel
        </li>
        <!--<li>Invite to Server</li>-->
      </ul>
      <!-- Channel Project END -->

      <!-- Category -->
      <ul *ngIf="type === eContextTypes.category">
        <li
          (click)="
            createChannel(
              data.serverId,
              data.category._id,
              eChannelParentIdType.CATEGORY,
              data.owner
            )
          "
        >
          Create Channel
        </li>
        <li (click)="createCategory(data.serverId, data.owner)">
          Create Category
        </li>
        <hr />
        <li
          *ngIf="data.isOwner"
          (click)="modifyCategory(data.serverId, data.category)"
        >
          Modify Category
        </li>
        <hr />
        <li
          class="danger"
          *ngIf="data.isOwner"
          (click)="deleteCategory(data.serverId, data.category)"
        >
          Delete Category
        </li>
        <!--<li>Invite to Server</li>-->
      </ul>
      <!-- Category END -->

      <!-- Project -->
      <ul *ngIf="type === eContextTypes.project">
        <li (click)="createProject(data.serverId, data.owner)">
          Create Project
        </li>
        <li
          (click)="
            createChannel(
              data.serverId,
              data.project._id,
              eChannelParentIdType.PROJECT,
              data.owner
            )
          "
        >
          Create Channel
        </li>
        <hr />
        <li
          *ngIf="data.isOwner"
          (click)="modifyProject(data.serverId, data.project)"
        >
          Modify Project
        </li>
        <hr />
        <li
          class="danger"
          *ngIf="data.isOwner"
          (click)="deleteProject(data.project._id)"
        >
          Delete Project
        </li>
        <!--<li>Invite to Server</li>-->
      </ul>
      <!-- Project END -->

      <!-- ArtWall Asset -->
      <ul *ngIf="type === eContextTypes.artwall_asset">
        <li *ngIf="data.isOwner" (click)="modifyAsset(data.asset)">
          Modify Asset
        </li>
        <li
          class="danger"
          *ngIf="data.isOwner"
          (click)="deleteAsset(data.asset)"
        >
          Delete Asset
        </li>

        <!--<li>Invite to Server</li>-->
      </ul>
      <!-- ArtWall Asset END -->

      <!-- Friend Chat -->
      <ul *ngIf="type === eContextTypes.friendChat">
        <li (click)="addFriendsToPrivateChannel(data)">Add Friends</li>
        <hr />
        <!--<hr/>-->
        <li class="danger" (click)="leaveChat(data)">Leave Chat</li>
        <hr />
        <li (click)="copyText(data.id)">Copy ID</li>
      </ul>
      <!-- Friend Chat END -->

      <!-- Server -->
      <ul *ngIf="type === eContextTypes.server">
        <!--<li>Mark As Read</li>-->
        <li *ngIf="data.isOwner" (click)="invitePeople(data.server)">
          Invite People
        </li>
        <hr />
        <!--<li>Mute Server</li>
        <hr/>-->
        <!--<li>Hide Muted Channels</li>-->
        <!--<li>Server Settings</li>-->
        <!--<li>Notification Settings</li>-->
        <!--<li>Privacy Settings</li>-->
        <li *ngIf="data.isOwner" (click)="modifyServer(data.server)">
          Modify Server
        </li>
        <hr *ngIf="data.isOwner" />
        <!--<li (click)="createCategory(data.serverId, data.owner)">Create Category</li>-->
        <hr />
        <li
          class="danger"
          *ngIf="!data.isOwner"
          (click)="leaveServer(data.serverId)"
        >
          Leave Server
        </li>
        <li
          class="danger"
          *ngIf="data.isOwner"
          (click)="deleteServer(data.serverId)"
        >
          Delete Server
        </li>
        <hr />
        <li (click)="copyText(data.id)">Copy ID</li>
      </ul>
      <!-- Server END -->

      <!-- Add Server -->
      <ul *ngIf="type === eContextTypes.AddServer">
        <!--<li>Join a server</li>-->
        <li (click)="onAddServerClick()">Create a server</li>
      </ul>
      <!-- Add Server END -->

      <!-- Post Kabab Menu -->
      <ul *ngIf="type === eContextTypes.postKababMenu">
        <li (click)="editPost(data.post, data.assetId)">Edit Post</li>
        <hr />
        <li (click)="removePost(data.post, data.assetId)" class="danger">
          DELETE POST
        </li>
      </ul>
      <!-- Post Kabab END -->

      <!-- Username -->
      <ul *ngIf="type === eContextTypes.username">
        <li *ngIf="!data.isUser">
          Invite To Server
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
          <ul>
            <div *ngFor="let server of servers">
              <li
                *ngIf="
                  server.owner == user._id &&
                  !server.members.includes(data.username) &&
                  !server.invitations.includes(data.username)
                "
                (click)="inviteToSerer(server._id, data.username)"
              >
                {{ server.name }}
              </li>
            </div>
          </ul>
        </li>
        <li
          *ngIf="
            !data.isUser &&
            !data.isFriend &&
            !data.isSentRequest &&
            !data.isRecievedRequest
          "
          (click)="sendFriendRequest(data.username)"
        >
          Send Friend Request
        </li>
        <li
          *ngIf="!data.isUser && !data.isFriend && data.isSentRequest"
          (click)="removeSentRequest(data.username)"
        >
          Unsend Friend Request
        </li>
        <li
          *ngIf="
            !data.isUser &&
            !data.isFriend &&
            !data.isSentRequest &&
            data.isRecievedRequest
          "
          (click)="acceptFreindRequest(data.username)"
        >
          Accept Friend Request
        </li>
        <li
          *ngIf="!data.isUser && data.isFriend"
          (click)="removeFriend(data.username)"
        >
          Remove Friend
        </li>
        <li
          *ngIf="!data.isUser"
          (click)="blockUser(data.username)"
          class="danger"
        >
          Block User
        </li>
        <li
          *ngIf="data.isOwner && !data.isUser"
          class="danger"
          (click)="kickFromServer(data.serverId, data.username)"
        >
          Kick From Server
        </li>
        <hr *ngIf="!data.isUser" />
        <li (click)="copyText(data.username)">Copy Username</li>
      </ul>
      <!-- Username END -->
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { WebsocketService } from './services/websocket.service';
import { ContextMenuService, ContextMenuData } from './services/context-menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'art-dominion-client';
  contextmenuX = 0;
  contextmenuY = 0;

  constructor(private websocketService: WebsocketService,
              private contextMenuService: ContextMenuService) {}

  ngOnInit() {
    this.websocketService.initialize();
  }
  
  //disables the menu
  disableContextMenu(){
    this.contextMenuService.hide();
 }
}

import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';

export enum KEY_CODE {
  ESCAPE = 27,
}
@Component({
  selector: 'app-modify-category',
  templateUrl: './modify-category.component.html',
  styleUrls: ['./modify-category.component.scss'],
})
export class ModifyCategoryComponent implements OnInit {
  visible = false;
  category: any;
  serverId: string;

  public modifyCategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serverService: ServersService
  ) {}

  ngOnInit(): void {
    this.modifyCategoryForm.get('submitButton')?.disable();
    this.popupController.modifyCategory.subscribe((data) => {
      debug('updating modify-server visibility');
      this.visible = data == null ? false : true;
      this.category = data.category;
      this.serverId = data.serverId;

      console.log(data);

      this.modifyCategoryForm.get('name')?.setValue(this.category.name);
    });
  }

  onSubmit() {
    // TODO: Modify asset

    this.serverService
      .patchCategory(this.modifyCategoryForm, this.serverId, this.category._id)
      .then((response) => {
        debug(response);
        this.serverService.getUsersOwnedServers();
        this.killWindow();
      });
  }

  async updateFormValidation() {
    if (this.modifyCategoryForm.valid) {
      this.modifyCategoryForm.get('submitButton')?.enable();
    } else {
      this.modifyCategoryForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.modifyCategoryForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export enum logLevels {
  INFO = 1,
  DEBUG = 2,
  WARN = 3,
  ERROR = 4,
}

export function info(...message) {
  const logEntry = createLogStatement('info', message);
  if (environment.debugLevel == logLevels.INFO) console.info(logEntry);
  return logEntry;
}

export function debug(...message) {
  const logEntry = createLogStatement('debug', message);
  if (environment.debugLevel <= logLevels.DEBUG) console.info(logEntry);
  return logEntry;
}

export function warn(...message) {
  const logEntry = createLogStatement('warning', message);
  if (environment.debugLevel <= logLevels.WARN) console.warn(logEntry);
  return logEntry;
}

export function error(...message) {
  const logEntry = createLogStatement('error', message);
  if (environment.debugLevel <= logLevels.ERROR) console.error(logEntry);
  return logEntry;
}

function createLogStatement(level, message) {
  const SEPARATOR = ' ';
  const date = getCurrentDate();
  // if message is object, stringify it
  if (typeof message[0] === 'object') {
    message = 'type: ' + typeof message + '\n\r' + JSON.stringify(message);
  }
  return '[' + level + ']' + SEPARATOR + date + SEPARATOR + message;
}

function getCurrentDate() {
  const now = new Date();
  return '[' + now.toLocaleString() + ']';
}

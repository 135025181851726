<div class="side-panel"><!--
  <div class="scroll">
    <div class="status-owner">
      <h2>OWNER</h2>
        <ul>
          <li>
            <div class="pic">
              <img src="assets/images/users/profile-pic.jpg" /> 
              <div class="availability availability-online">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                  <circle class="" cx="8" cy="8" r="8"/>
                </svg>
              </div>
            </div>
            <div class="name-col">
              <div class="name">Scott Hancock</div>
              <div class="status">Woaw! That's a long status!</div>
            </div>
          </li>
        </ul>
    </div>
    <div class="status-online">
      <h2>ONLINE</h2>
      <ul>
        <li>
          <div class="pic">
            <img src="assets/images/users/profile-pic.jpg" /> 
            <div class="availability availability-idle">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
              </svg>
            </div>
          </div>
          <div class="name-col">
            <div class="name-nostatus">Guy No Status</div>
          </div>
        </li>
        <li>
          <div class="pic">
            <img src="assets/images/users/profile-pic.jpg" />
            <div class="availability availability-dnd">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
              </svg>
            </div>
          </div>
          <div class="name-col">
            <div class="name-nostatus">Guy No Status</div>
          </div>
        </li>
        <li>
          <div class="pic">
            <img src="assets/images/users/profile-pic.jpg" />
            <div class="availability availability-online">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <circle class="" cx="8" cy="8" r="8"/>
              </svg>
            </div>
          </div>
          <div class="name-col">
            <div class="name-nostatus">Guy No Status</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="status-offline">
      <h2>OFFLINE</h2>
      <ul>
        <li>
          <div class="pic">
            <img src="assets/images/users/profile-pic.jpg" /> 
            <div class="availability availability-offline">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
            </div>
          </div>
          <div class="name-col">
            <div class="name-nostatus">Guy No Status</div>
          </div>
        </li>
        <li>
          <div class="pic">
            <img src="assets/images/users/profile-pic.jpg" />
            <div class="availability availability-offline">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
            </div>
          </div>
          <div class="name-col">
            <div class="name-nostatus">Guy No Status</div>
          </div>
        </li>
        <li>
          <div class="pic">
            <img src="assets/images/users/profile-pic.jpg" />
            <div class="availability availability-offline">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
            </div>
          </div>
          <div class="name-col">
            <div class="name-nostatus">Guy No Status</div>
          </div>
        </li>
      </ul>
    </div>
  </div>-->
</div>

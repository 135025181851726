import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  visible = false;
  selectedTab = 'profile';
  user: User;
  constructor(
    private popupController: PopupControllerService,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.popupController.userSettings.subscribe((visible: boolean) => {
      this.visible = visible;
    });
    this.apiService.user.subscribe((user) => {
      this.user = user;
    });
    this.apiService.GetCurrentUser();
  }

  /* 
  onFullscreenClick(event) {
    // const pounterEvent: PointerEvent = event;
    if (event.target.parentNode.parentNode.id) {
      this.visible = false;
    }
  }*/

  logout() {
    this.apiService.logout();
    // redirect
    this.router.navigate(['/login']);
  }

  closeWindow() {
    this.visible = false;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.visible = false;
    }
  }
}

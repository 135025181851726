import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { Server } from 'src/app/models/server.model';
import { ServersService } from 'src/app/services/api/servers.service';
import { User } from 'src/app/models/user.model';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalLocationResolverService } from '../../services/global-location-resolver.service';
import { ProjectsService } from 'src/app/services/api/projects/projects.service';
import { Channel, EChannelType } from 'src/app/models/channel.model';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.scss'],
})
export class ServersComponent implements OnInit {
  eContextTypes = ContextTypes;
  servers: Server[];
  selectedServerId: String = 'home';
  user;
  timer;

  constructor(
    private contextMenuService: ContextMenuService,
    private popupController: PopupControllerService,
    private apiService: ApiService,
    private serverService: ServersService,
    private globalLocationService: GlobalLocationResolverService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiService.GetCurrentUser();

    console.log('servers - ngOnInit');
    if (this.activatedRoute.snapshot.queryParamMap.has('token')) {
      console.log('has token!');
      const token = this.activatedRoute.snapshot.queryParamMap.get('token');
      await this.serverService.joinServerWithInviteLink(token);
    }

    debug('getting servers');
    this.updateServerList();

    this.apiService.user.subscribe((user) => {
      this.user = user;
    });

    this.serverService.servers.subscribe((servers: Server[]) => {
      this.servers = servers;
      for (let i = 0; i < this.servers.length; i++) {
        this.servers[i].initial = this.servers[i].name.slice(0, 2);
      }
      console.log(this.servers);
    });

    this.selectedServerId = this.globalLocationService.getActiveServerId();
    console.log('Selected Server Id: ' + this.selectedServerId);
    this.globalLocationService.activeServerIdSub.subscribe((serverId) => {
      this.selectedServerId = serverId || 'home';
    });

    this.timer = setInterval(() => {
      if (this.selectedServerId) {
        this.updateServerList();
      }
    }, 10000);
  }

  onrightClickServer(event, type: ContextTypes, serverId: string) {
    const server = this.servers.find((value) => value._id == serverId);
    const isOwner = server.owner == this.user._id;
    const data = { serverId, isOwner, server };
    this.contextMenuService.show(event.clientX, event.clientY, type, data);
  }
  onrightClickAddServer(event, type: ContextTypes) {
    const data = {};
    this.contextMenuService.show(event.clientX, event.clientY, type, data);
  }
  onAddServerClick() {
    this.popupController.openNewServerPopup();
  }
  updateServerList() {
    this.serverService.getUsersOwnedServers();
  }
  changeServer(serverId) {
    console.log('CHANGE SERVER');
    console.log('New Server: ' + serverId);
    this.globalLocationService.setActiveServerId(
      'servers-component - changeServer',
      serverId
    );

    if (serverId === 'home') {
      this.router.navigateByUrl(`/m/home`);
    } else {
      this.projectService.GetProjects(serverId); //update project list

      // select first category and first channel
      const server = this.servers.find((value) => value._id == serverId);
      const category = server.categories[0];
      const channel = category.channels[0];
      console.log('selected channel: ');
      this.globalLocationService.setActiveCategoryId(
        'servers-component - changeServer',
        category._id
      );
      this.globalLocationService.setActiveChannelId(
        'servers-component - changeServer',
        channel._id
      );

      if (channel.channelType == EChannelType.TEXT) {
        this.globalLocationService.setActiveChannelType(
          'servers-component - changeServer',
          'chat'
        );
      } else if (channel.channelType == EChannelType.ART_WALL) {
        this.globalLocationService.setActiveChannelType(
          'servers-component - changeServer',
          'art-wall'
        );
      }

      this.router.navigateByUrl(`/m/server/${serverId}`);
    }
    // this.selectedServerIndex = value;
    // this.selectedServerState.emit(value);
  }
}

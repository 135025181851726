<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <form [formGroup]="modifyAssetForm" (ngSubmit)="onSubmit()">
          <h1>Modify Asset</h1>
          <div class="form-group">
            <div class="thumbnail center">
              <label for="thumbnail-modify-asset">
                <div class="thumbnail-overlay-main"></div>
                <img
                  *ngIf="selectedImageSrc"
                  [src]="selectedImageSrc"
                  width="200"
                  height="200"
                />
                <div class="thumbnail-overlay">
                  <span>UPLOAD<br />IMAGE</span>
                </div>
                <div class="thumbnail-overlay-main">
                  <span *ngIf="!selectedImageSrc">THUMBNAIL</span>
                </div>
              </label>
            </div>
            <input
              (change)="onFileUpload($event)"
              type="file"
              accept=".png,.jpg,.gif"
              name="thumbnail"
              id="thumbnail-modify-asset"
              style="display: none"
            />

            <label for="email"
              >Asset Name
              <input
                type="text"
                name="name"
                (keyup)="updateFormValidation()"
                formControlName="name"
              />
            </label>
          </div>
          <button
            class="btn btn-block"
            type="submit"
            name="submitButton"
            formControlName="submitButton"
            ngDefaultControl
          >
            <div class="btnText">Update</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

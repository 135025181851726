import { Injectable } from '@angular/core';
import { Asset } from 'src/app/models/asset.model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AbstractEndpointService,
  RequestTypes,
} from '../abstract-endpoint.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { Project } from 'src/app/models/project.model';
import { ChannelService } from '../channel.service';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Channel } from 'src/app/models/channel.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends AbstractEndpointService {
  protected path = '/projects';
  private _projects: Project[] = [];
  public projects: Subject<Project[]> = new Subject<Project[]>();

  constructor(
    protected apiService: ApiService,
    protected httpClient: HttpClient,
    private channelService: ChannelService
  ) {
    super(apiService, httpClient);
  }

  RefreshProjects() {
    this.projects.next(this._projects);
  }

  // Create Project
  async CreateProject(data: FormGroup): Promise<any> {
    return await this.AuthenticatedRequest<Project>(
      RequestTypes.POST,
      '',
      data.getRawValue()
    ).then((project) => {
      this._projects.push(project);
      this.RefreshProjects();
      return project;
    });
  }

  // Get Project
  async GetProjects(serverId: string): Promise<Project[]> {
    return await this.AuthenticatedRequest<Project[]>(
      RequestTypes.GET,
      `/${serverId}`
    ).then(async (projects) => {
      projects = await Promise.all(
        projects.map(async (p) => {
          p.channels = (await this.channelService.GetAllChannelsForProject(
            p._id
          )) as unknown as Channel[];
          return p;
        })
      );
      this._projects = projects;
      this.RefreshProjects();
      return projects;
    });
  }

  // Patch Project
  async PatchProject(projectId: string, data: FormGroup): Promise<Project> {
    return await this.AuthenticatedRequest<Project>(
      RequestTypes.PATCH,
      `/${projectId}`,
      data.getRawValue()
    ).then((project) => {
      this._projects.forEach((p) => {
        if (p._id == projectId) {
          p = project;
        }
      });
      this.RefreshProjects();
      return project;
    });
  }

  // Remove Project
  async RemoveProject(projectId: string): Promise<Project> {
    return await this.AuthenticatedRequest<Project>(
      RequestTypes.DELETE,
      `/${projectId}`
    ).then((project) => {
      this._projects = this._projects.filter((p) => p._id != projectId);
      this.RefreshProjects();
      return project;
    });
  }
}

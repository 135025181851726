<div class="main-right" *ngIf="selectedServer">
  <div *ngIf="!selectedServer.isInvitedOnly">
    <app-server-sidebar
      [selectedAssetTab]="selectedAssetTab"
      [selectedServerId]="selectedServerId"
      [selectedAssetIndex]="selectedAssetIndex"
    ></app-server-sidebar>
  </div>
  <!-- Main Screens -->
  <div *ngIf="!selectedServer.isInvitedOnly">
    <!-- Channel Screens -->
    <!-- FIXME check to see if I can remove this since it's inlined -->
    <app-asset-tab
      *ngIf="selectedAssetIndex"
      (selectedTabState)="changeAssetTab($event)"
    ></app-asset-tab>
    <router-outlet></router-outlet>
  </div>
  <app-accept-server-invitation
    *ngIf="selectedServer.isInvitedOnly"
    [selectedServer]="selectedServer"
  ></app-accept-server-invitation>
</div>
<!--<router-outlet name="main-right"></router-outlet>-->

<!--<app-art-wall-channel (selectedAssetState)="updateSelectedAsset($event)"-->
<!--                      *ngIf=" selectedChannel &&-->
<!--                                  selectedChannel.channelType == 'art-wall'-->
<!--                                "-->
<!--                      [selectedAssetTab]="selectedAssetTab"-->
<!--                      [selectedServer]="selectedServer"-->
<!--                      [selectedChannel]="selectedChannel"-->
<!--                      (selectedAssetTabState)="changeAssetTab($event)"></app-art-wall-channel>-->
<!--<app-art-thread-channel *ngIf="selectedAssetIndex  && selectedAssetTab == 'thread'"-->
<!--                        [selectedAssetIndex]="selectedAssetIndex"-->
<!--                        [selectedAssetTab]="selectedAssetTab"-->
<!--                        [selectedServer]="selectedServer"-->
<!--                        [selectedChannel]="selectedChannel"-->
<!--                        (selectedAssetTabState)="changeAssetTab($event)"></app-art-thread-channel>-->
<!--<app-art-board-channel *ngIf="selectedAssetIndex && selectedAssetTab == 'board'"-->
<!--                       [selectedAssetIndex]="selectedAssetIndex"-->
<!--                       [selectedAssetTab]="selectedAssetTab"-->
<!--                       [selectedServer]="selectedServer" [selectedChannel]="selectedChannel"-->
<!--                       (selectedAssetTabState)="changeAssetTab($event)"></app-art-board-channel>-->
<!--<app-chat-channel *ngIf="selectedChannel && selectedChannel.channelType == 'text'"-->
<!--                  [channelId]="selectedChannel.channelId"-->
<!--                  [selectedServer]="selectedServer"-->
<!--                  [selectedChannel]="selectedChannel"></app-chat-channel>-->

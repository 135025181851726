<div class="sub-main">
  <app-channel-header
    [selectedAssetId]="selectedAssetId"
    (selectedAssetTabState)="changeAssetTab($event)"
  ></app-channel-header>
  <div class="scroll">
    <div>
      <div class="friend-list">
        <div
          #canvas
          DragAndDrop
          (fileDropped)="onDropFileUpload($event)"
          class="canvas"
          [class.canvas-image-tool]="selectedTool == eTool.IMAGE"
          [class.canvas-text-tool]="selectedTool == eTool.TEXT"
          mouseWheel
          (contextmenu)="disableTool()"
          (mousemove)="mouseMoveOnCanvas($event)"
          (mouseWheelUp)="scrollUp()"
          (mouseWheelDown)="scrollDown()"
          (mouseup)="onMouseUp($event)"
          (mousedown)="onMouseDown($event)"
        >
          <div
            *ngFor="let item of elements; let i = index"
            class="example-box"
            (mouseup)="clickedDivElement($event, i)"
            (mouseup)="onMouseUp($event)"
            [class.example-box-hovered]="item.hovered"
            [class.example-box-selected]="item.selected"
            (mouseenter)="onMouseEnterElement(i)"
            (mouseleave)="onMouseLeaveElement(i)"
            (dblclick)="onEditElement(i)"
            [ngStyle]="{
              'left.px': item.x * scale + origin.x,
              'top.px': item.y * scale + origin.y,
              'width.px': item.width * scale,
              'height.px': item.height * scale
            }"
          >
            <!-- image here -->
            <img
              *ngIf="item.elType == eElementTypes.IMAGE"
              class="board-image"
              [src]="item.imageLocation"
              style="position: relative"
              (mousedown)="onMouseDownElement($event, i)"
            />

            <!-- Text Input -->
            <div class="text-input-container">
              <div
                class="text-input-handler"
                (mousedown)="onMouseDownElement($event, i)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-arrows-move"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"
                  />
                </svg>
              </div>
              <!-- 
                transform: 'scale(5)',
                  'transform-origin': 'top left'
              -->
              <textarea
                *ngIf="item.elType == eElementTypes.TEXT"
                [class.text-input-disabled]="editingTextareaIndex != i"
                [ngStyle]="{
                  'font-size': 'calc(16px * ' + scale + ')'
                }"
                class="text-input"
                (focus)="editingText = true"
                (focusout)="editingText = false"
                (change)="changeText(i, $event)"
                >{{ item.text }}</textarea
              >
            </div>
            <div
              *ngIf="item.selected"
              class="anchor-top-left"
              (mouseenter)="onMouseEnterResizehandle('top-left')"
              (mouseleave)="onMouseLeaveResizehandle()"
              (mousedown)="onMouseDownHandle($event)"
              (mouseup)="onMouseUp($event)"
            ></div>
            <div
              *ngIf="item.selected"
              class="anchor-top-right"
              (mouseenter)="onMouseEnterResizehandle('top-right')"
              (mouseleave)="onMouseLeaveResizehandle()"
              (mousedown)="onMouseDownHandle($event)"
              (mouseup)="onMouseUp($event)"
            ></div>
            <div
              *ngIf="item.selected"
              class="anchor-bottom-left"
              (mouseenter)="onMouseEnterResizehandle('bottom-left')"
              (mouseleave)="onMouseLeaveResizehandle()"
              (mousedown)="onMouseDownHandle($event)"
              (mouseup)="onMouseUp($event)"
            ></div>
            <div
              *ngIf="item.selected"
              class="anchor-bottom-right"
              (mouseenter)="onMouseEnterResizehandle('bottom-right')"
              (mouseleave)="onMouseLeaveResizehandle()"
              (mousedown)="onMouseDownHandle($event)"
              (mouseup)="onMouseUp($event)"
            ></div>
            <!--<div *ngIf="item.selected" class="close-element" (click)="deleteElement(i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
              </svg>
            </div>-->
            <!--{{ item.text }}-->
          </div>

          <!-- Drag Over -->
          <div class="fileover">
            <div class="drop-area valign">
              <h4 class="valign">Drop Images Here</h4>
            </div>
          </div>
        </div>

        <div class="toolbox-left-container">
          <div
            class="toolbox-button"
            [class.tool-selected]="selectedTool == eTool.SELECTION"
            (click)="changeTool(eTool.SELECTION)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path
                d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"
              />
            </svg>
          </div>
          <!-- Selection tool -->
          <div
            class="toolbox-button"
            [class.tool-selected]="selectedTool == eTool.TEXT"
            (click)="changeTool(eTool.TEXT)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path
                d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874V2.5zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5v3.563zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
              />
              <path
                d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386L11.434 4z"
              />
            </svg>
          </div>
          <!-- Text tool -->
          <div
            class="toolbox-button"
            [class.tool-selected]="selectedTool == eTool.IMAGE"
            (click)="changeTool(eTool.IMAGE)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              <path
                d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"
              />
            </svg>
            <input
              (change)="onFileUpload($event)"
              #imageinput
              id="imageinput"
              name="filename"
              type="file"
            />
          </div>
          <!-- Image -->
        </div>

        <!--<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="addElement" viewBox="0 0 16 16" (click)="openAddElementMenu($event)">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
        </svg>-->

        <div class="scale-lable">Scale: {{ (scale * 100).toFixed(0) }}%</div>
      </div>
    </div>
  </div>
  <!--<app-channel-side-panel [selectedServer]="selectedServer" [selectedChannel]="selectedChannel"></app-channel-side-panel>-->
</div>

import { Pipe, PipeTransform } from '@angular/core';
import Point from '../classes/point.model';
import PolygonElement from '../classes/svg/polygon-element.class';
import SvgElement from '../classes/svg/svg-element.class';
import { ThreadPost } from '../models/thread-chat-message.model';
import {debug, warn, error, info} from 'src/app/services/logger.service';

@Pipe({
  name: 'pointsToString',
  pure: true
})
export class PointsToStringPipe implements PipeTransform {

  transform(element: SvgElement, 
            post: ThreadPost, 
            dirty: boolean, 
            elementX: number, 
            elementY: number, 
            imageWidth: number, 
            imageHeight: number): string {
    let pointString = '';
    let polygonElement = element as PolygonElement;
    
    if (polygonElement && polygonElement.points) {
      debug('PointsToStringPipe Working');
      for (let i = 0; i < polygonElement.points.length; i++) {
        pointString += ((element.x + polygonElement.points[i].x) * post.imageSize.x) + ',' + ((element.y + polygonElement.points[i].y) * post.imageSize.y) + ' ';
      }
      // debug(pointString);
      return pointString;
    } else {
      error('PointsToStringPipe Error:');
      //debug(polygonElement);
      //debug(post);
      return null;
    }
  }

}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Server } from 'src/app/models/server.model';
import { FileService } from 'src/app/services/api/file.service';
import { AssetFilesService } from 'src/app/services/api/assets/asset-files.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { DialogType } from '../../popups/dialog/dialog.component';

@Component({
  selector: 'app-art-files',
  templateUrl: './art-files.component.html',
  styleUrls: ['./art-files.component.scss'],
})
export class ArtFilesComponent implements OnInit {
  @Input()
  selectedServer: Server;

  @Input()
  selectedChannel;

  @Input()
  selectedAssetTab;

  @Input()
  selectedAssetId: string;

  @Output()
  selectedAssetTabState = new EventEmitter<string>();

  contents: any[] = [];
  currentFolder: string = '';

  selectedFile: number = null;

  isWaitingForServerResponse: boolean = true;
  isDragHovering: boolean = false;

  isUploading: boolean = false;
  fileUploadCount: number = 0;
  fileUploadIndex: number = 0;
  currentUploadingFile: File;

  public fileUploadForm = new FormGroup({
    fileUpload: new FormControl(''),
  });

  // fileDownloadUrl: string = null;

  // @ViewChild('fileDownload')
  // fileDownload: ElementRef;

  constructor(
    private assetFilesService: AssetFilesService,
    private assetService: FileService,
    private popupControllserService: PopupControllerService
  ) {}

  ngOnInit(): void {
    this.changeFolder('');
  }

  changeFolder(folder: string) {
    this.isWaitingForServerResponse = true;
    this.contents = [];
    this.assetFilesService
      .GetFolderContents(this.selectedAssetId, folder)
      .then((response: any) => {
        if (folder != '') {
          this.contents.push({
            type: 'folder',
            name: '..',
          });
        }

        response.CommonPrefixes.forEach((element) => {
          this.contents.push({
            type: 'folder',
            location: this.removeRootFolder(element.Prefix),
            name: this.removeDerivedFolders(element.Prefix),
          });
        });
        console.log(response);
        response.Contents.forEach((element) => {
          const location = this.removeRootFolder(element.Key);
          if (element.Size > 0) {
            this.contents.push({
              type: 'file',
              location,
              name: this.removeDerivedFolders(element.Key),
              size: element.Size,
            });
          }
        });
        this.isWaitingForServerResponse = false;
      });
  }

  removeRootFolder(folder: string): string {
    const mainFolder = `assets/${this.selectedAssetId}/files/`;
    return folder.replace(mainFolder, '');
  }

  removeDerivedFolders(folder: string): string {
    const folders: string[] = folder.split('/');

    if (folder.substring(folder.length - 1) == '/') {
      return folders[folders.length - 2];
    } else {
      return folders[folders.length - 1];
    }
  }

  changeAssetTab(tab: string) {
    this.selectedAssetTabState.emit(tab);
  }

  selectFile(index: number) {
    this.selectedFile = index;
  }

  newFolder() {
    // open popup
    console.log('New Folder');

    const dialogCallback: Subject<any> = new Subject<any>();
    dialogCallback.subscribe((response) => {
      console.log(response);

      this.assetFilesService
        .CreateFolder(this.selectedAssetId, this.currentFolder + response)
        .then((response) => {
          this.changeFolder(this.currentFolder);
        });
    });
    this.popupControllserService.openDialog(
      DialogType.SINGLE_TEXT_INPUT_BOX_DIALOG,
      'Create New Folder',
      `Create a new folder in /${this.currentFolder}`,
      dialogCallback,
      'Folder Name'
    );
  }

  /**
   * This function is async to support multi file upload
   *
   * @param file File to upload
   * @returns uploadResponse
   */
  async uploadFile(file: File): Promise<any> {
    console.log('image: ' + this.currentFolder + file.name);
    this.fileUploadForm.reset();

    let response = await this.assetFilesService.RequestFileUpload(
      this.selectedAssetId,
      this.currentFolder + file.name
    );

    console.log('test 1');
    if (response) {
      const uploadUrl = response as unknown as string;
      const imageForm = new FormData();
      console.log('test 2');
      imageForm.append('file', file);

      console.log('test 3');
      //let uploadResponse = await

      let uploadResponse = await this.assetService
        .uploadToS3(uploadUrl, file.type, file)
        .toPromise();

      if (uploadResponse) {
        return uploadResponse;
      } else {
        return false;
      }
    }
  }

  onFileUpload(event) {
    console.log('onFileUpload Changed');

    this.uploadFile((event.target as HTMLInputElement).files[0]).then(
      (response) => {
        if (response) {
          this.changeFolder(this.currentFolder);
        }
      }
    );
  }

  downloadFile(index: number) {
    console.log('Download File: ' + this.contents[index].name);
    console.log(this.currentFolder + this.contents[index].name);

    this.assetFilesService
      .DownloadFile(
        this.selectedAssetId,
        this.currentFolder + this.contents[index].name
      )
      .then((response) => {
        console.log(response);
        window.open(response as unknown as string);
      });
  }

  deleteFile() {
    console.log('Delete File: ' + this.contents[this.selectedFile].name);

    const dialogCallback: Subject<any> = new Subject<any>();
    dialogCallback.subscribe(async (success) => {
      if (success) {
        this.assetFilesService
          .DeleteFile(
            this.selectedAssetId,
            this.currentFolder + this.contents[this.selectedFile].name
          )
          .then((response) => {
            console.log('Delete File Response:');
            console.log(response);
            this.changeFolder(this.currentFolder);
          });
      }
    });

    this.popupControllserService.openDialog(
      DialogType.CONFIRMATION_DIALOG_DANGER,
      `Delete ${this.contents[this.selectedFile].name}?`,
      `Are you sure you want to delete the file ${
        this.contents[this.selectedFile].name
      }?`,
      dialogCallback
    );
  }

  dblClick(index: number) {
    if (this.contents[index].type == 'file') {
      this.downloadFile(index);
    } else {
      if (this.contents[index].name == '..') {
        this.traverseUpFolder();
      } else {
        this.traverseDownFolder(index);
      }
    }
  }
  traverseDownFolder(index: number) {
    console.log('Open Folder: ' + this.contents[index].name);
    this.currentFolder += this.contents[index].name + '/';
    this.changeFolder(this.currentFolder);
  }

  traverseUpFolder() {
    console.log('current folder: ' + this.currentFolder);
    const folder = this.currentFolder.split('/');
    console.log(folder);

    if (folder[folder.length - 1] == '') {
      folder.pop();
    }

    folder.pop();

    if (folder.length == 0) {
      this.currentFolder = '';
    } else {
      this.currentFolder = folder.join('/') + '/';
    }

    // this.currentFolder = folder.join('/') + '/';
    console.log(folder);
    console.log('after change folder: ' + this.currentFolder);
    this.changeFolder(this.currentFolder);
  }

  async onDropFileUpload(event: DragEvent) {
    console.log('filesDropped');
    console.log(event);
    console.log('Files:');
    console.log(event.dataTransfer.files);
    const files = event.dataTransfer.files;
    const length = files.length;
    this.fileUploadCount = length;

    for (let fileIndex = 0; fileIndex < length; fileIndex++) {
      console.log('uploading file ' + fileIndex + 1);
      this.currentUploadingFile = files[fileIndex];
      this.fileUploadIndex = fileIndex;
      this.isUploading = true;
      await this.uploadFile(files[fileIndex]);
    }

    this.isUploading = false;
    console.log('left loop');
    this.changeFolder(this.currentFolder);
  }

  onDragHover(isHovered: boolean) {
    console.log('Hovering!');
    this.isDragHovering = isHovered;
  }
}

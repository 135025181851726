import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { FileService, FileTypes } from 'src/app/services/api/file.service';
import { PostsService } from 'src/app/services/api/assets/posts.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { GlobalLocationResolverService } from '../../../services/global-location-resolver.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-add-thread-post',
  templateUrl: './add-thread-post.component.html',
  styleUrls: ['./add-thread-post.component.scss'],
})
export class AddThreadPostComponent implements OnInit {
  visible = false;
  selectedImage;
  selectedImageSrc: string;
  currentChannel: string;

  public addPostForm = new FormGroup({
    caption: new FormControl('', [Validators.required]),
    assetId: new FormControl(''),
    owner: new FormControl(''),
    imageUrl: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private assetsService: AssetsService,
    private postsService: PostsService,
    private fileService: FileService,
    private globalLocationResolver: GlobalLocationResolverService
  ) {
    this.globalLocationResolver.activeChannelIdSub.subscribe((channelId) => {
      this.currentChannel = channelId;
    });
  }

  ngOnInit(): void {
    this.addPostForm.get('submitButton')?.disable();
    this.popupController.newPost.subscribe((data: any) => {
      debug('Reloading New Post Popup');
      if (data.assetId == null) {
        this.visible = false;
      } else {
        this.visible = true;
      }

      this.addPostForm.get('assetId')?.setValue(data.assetId);
      this.addPostForm.get('owner')?.setValue(data.owner);
      debug('new asset popup - assetId: ' + data.assetId);
    });
  }

  onSubmit() {
    if (this.selectedImage) {
      debug('Sending Create Post Request. Data:');
      debug(this.addPostForm.getRawValue());
      const img = new Image();
      img.src = URL.createObjectURL(this.selectedImage);
      img.onload = (e: any) => {
        const width = e.target.width;
        const height = e.target.height;

        // upload selected image
        this.fileService
          .getPresignedUrl(
            FileTypes.IMAGES,
            this.selectedImage.name,
            this.selectedImage.type,
            width,
            height
          )
          .then((response) => {
            debug('received response');
            this.addPostForm.get('imageUrl')?.setValue(response.filename);

            debug(response);
            if (response.url) {
              debug('received presigned url');
              const fileUploadUrl = response.url;
              const imageForm = new FormData();
              imageForm.append('file', this.selectedImage);
              this.fileService
                .uploadToS3(
                  fileUploadUrl,
                  this.selectedImage.type,
                  this.selectedImage
                )
                .subscribe(async (uploadResponse) => {
                  debug('upload resolution');
                  debug(uploadResponse);
                  if (uploadResponse && uploadResponse.type == 4) {
                    this.fileService
                      .confirmFileUpload(response._id)
                      .then((confirmResponse) => {
                        debug('image upload confirmed');
                        debug(confirmResponse);

                        console.log('selected image: ');
                        console.log(this.selectedImage);
                        console.log('uploadResponse: ');
                        console.log(uploadResponse);
                        this.createPost({ x: width, y: height });
                      });
                  }
                });
            }
          });
      };
    } else {
      this.createPost(null);
    }
  }

  createPost(imageSize: { x; y }) {
    this.postsService
      .CreatePost(this.addPostForm, imageSize, this.currentChannel)
      .then((response) => {
        console.log('Create Post Response:');
        console.log(response);
        this.selectedImage = null;
        this.selectedImageSrc = null;
        this.killWindow();
      })
      .catch((error) => {
        console.error('Create Post Error: ' + error);
      });
  }

  async updateFormValidation() {
    if (this.addPostForm.valid) {
      this.addPostForm.get('submitButton')?.enable();
    } else {
      this.addPostForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.addPostForm.reset();
    this.updateFormValidation();
  }

  onFileUpload(event) {
    debug(event);
    debug('selected new image');
    const image = (event.target as HTMLInputElement).files[0];

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedImageSrc = reader.result as string;
      };
    }

    this.selectedImage = image;
    debug(this.selectedImage);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

<div class="fullscreen" id="fullscreen" [class.fadein]="visible" [class.fadeout]="!visible" (click)="closeWindow($event)">
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div class="popup-box" [class.scalein]="visible" [class.scaleout]="!visible">
        <form [formGroup]="addFriendForm" (ngSubmit)="onSubmit()">
          <h1>Add A New Friend</h1>
          <div class="form-group">
            <label for="email">Username or Email Address
              <input type="text" name="usernameOrEmail" (keyup)="updateFormValidation()" formControlName="usernameOrEmail" />
            </label>
          </div>
          <button class="btn btn-block" type="submit" name="submitButton" formControlName="submitButton" ngDefaultControl>
            <div class="btnText">Add Friend</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

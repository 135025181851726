import ToolMode from "src/app/enums/tool-mode.enum";
import {debug, warn, error, info} from 'src/app/services/logger.service';
import Point from "../point.model";
import SvgElement from "./svg-element.class"

export class RectElement extends SvgElement {
  public elType:ToolMode = ToolMode.RECT_TOOL;
  public width: number = 0;
  public height: number = 0;
  private _x: number = 0;
  private _y: number = 0;

  constructor(
    creationPoint: Point, 
    screenSpace: Point
  ) {
    super();
    this.x = creationPoint.x / screenSpace.x;
    this.y = creationPoint.y / screenSpace.y;
    this._x = this.x;
    this._y = this.y;
    debug('rect constructor | x: ' + this.x + ', y:' + this.y);
  }

  public SetSize(size: Point, screenSpace: Point) {
    const newXPosition = size.x / screenSpace.x;
    const newYPosition = size.y / screenSpace.y;

    debug('new X position: ' + newXPosition);
    debug('x position: ' + this.x);
    debug('_x position: ' + this._x);

    debug('new Y position: ' + newYPosition);
    debug('y position: ' + this.y);
    debug('_y position: ' + this._y);

    if (newXPosition < this._x) {
      this.width = this._x - newXPosition;
      this.x = newXPosition;
    } else {
      this.width = (size.x / screenSpace.x) - this.x;
    }
    
    if (newYPosition < this._y) {
      this.height = this._y - newYPosition;
      this.y = newYPosition;
    } else {
      this.height = (size.y / screenSpace.y) - this.y;
    }

    debug('rect Setting Size | width: ' + this.width + ', height:' + this.height);
    this.dirty = !this.dirty;
  }
}

export default RectElement;

<app-asset-tab
  [selectedTab]="selectedTab"
  (selectedTabState)="setSelectedTab($event)"
></app-asset-tab>
<app-art-thread-channel
  *ngIf="selectedTab === 'thread' && assetId && selectedChannel"
  [selectedAssetId]="assetId"
  [selectedChannel]="selectedChannel"
></app-art-thread-channel>
<app-art-board-channel
  *ngIf="selectedTab === 'board'"
  [selectedAssetId]="assetId"
></app-art-board-channel>
<app-art-files
  *ngIf="selectedTab === 'files'"
  [selectedAssetId]="assetId"
></app-art-files>

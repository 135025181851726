import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  FormBuilder,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';
import { EmailGroup } from 'src/app/components/multi-email-text-area/multi-email-text-area.component';
import { MultiEmailTextAreaComponent } from 'src/app/components/multi-email-text-area/multi-email-text-area.component';
import { InviteToken } from 'src/app/models/invite-token.model';
import { User, UserShort } from 'src/app/models/user.model';
import { GlobalLocationResolverService } from 'src/app/services/global-location-resolver.service';
import { ChannelService } from 'src/app/services/api/channel.service';
import { MatSelectionList } from '@angular/material/list';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-new-dm',
  templateUrl: './new-dm.component.html',
  styleUrls: ['./new-dm.component.scss'],
})
export class NewDmComponent implements OnInit {
  visible = false;
  category: any;
  server: any;
  form: FormGroup;
  fb: FormBuilder = new FormBuilder();
  emails: EmailGroup[];
  formValid: boolean = false;
  snackbarDuration = 2000;
  invitationLink: InviteToken;
  invitesSent: boolean = false;
  /*@ViewChild(MultiEmailTextAreaComponent)
  multiEmailTextAreaComponent: MultiEmailTextAreaComponent;*/
  user: User;
  userFriends: UserShort[] = [];
  @ViewChild('friends') selectionList: MatSelectionList;

  selectedFriends = new FormControl();

  channel = null;

  constructor(
    private popupController: PopupControllerService,
    private serverService: ServersService,
    private clipboardApi: ClipboardService,
    private _snackBar: MatSnackBar,
    private globalLocationResolver: GlobalLocationResolverService,
    private apiService: ApiService,
    private channelService: ChannelService
  ) {
    this.form = this.fb.group({
      usernames: this.fb.array([]),
      submitButton: new FormControl(''),
    });
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.apiService.GetCurrentUser();
    this.popupController.newDm.subscribe(async (data) => {
      const friendList = [];
      this.user.friends.map((friend: any) => {
        friendList.push(friend.username + '#' + friend.usercode);
      });
      this.userFriends = await this.apiService.getUsersShorts(friendList);
      this.form.disable();
      if (data == true) {
        // this is a new DM
        this.visible = true;
      } else if (data == false || data == null) {
        this.visible = false;
      } else {
        this.visible = true;
        this.channel = data;

        // filter out users who are already in the channel
        this.userFriends = this.userFriends.filter((friend) => {
          let found = false;
          this.channel.users.map((user) => {
            if (user == friend.username) {
              found = true;
            }
          });
          return !found;
        });
      }
      // enable form
      this.form.enable();
    });
  }

  createRoom() {
    const usernames: string[] = [];
    if (this.selectedFriends && this.selectedFriends.value) {
      for (let i = 0; i < this.selectedFriends.value.length; i++) {
        const friend = this.selectedFriends.value[i];
        usernames.push(friend.username);
      }
    }

    if (this.channel) {
      console.log('Adding to room');

      // Add user to to existing room/channel

      this.channelService
        .AddUserToPrivateChannel(this.channel._id, usernames)
        .then((response) => {
          this.closeWindow();
        });
    } else {
      console.log('Creating room');

      // create DM

      this.channelService.CreateChannel().then((response: any) => {
        if (usernames.length > 0) {
          this.channelService
            .AddUserToPrivateChannel(response._id, usernames)
            .then((response) => {
              this.closeWindow();
            });
        } else {
          this.closeWindow();
        }
      });
    }
  }

  private resetForm() {
    this.form.reset();
  }

  private updateForm() {
    /*if (this.formValid) {
      this.form.enable();
    } else {
      this.form.disable();
    }*/
  }

  updateEmails(_emails: EmailGroup[]) {
    this.emails = _emails;

    let formValid = false;
    this.emails.forEach((email) => {
      if (!email.invalid) {
        formValid = true;
      }
    });
    this.formValid = formValid;
    this.updateForm();
  }

  closeWindow(event = null) {
    //force a refresh of the user
    this.apiService.GetCurrentUser(true);
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id == 'fullscreen') {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  createInviteLink() {
    this.serverService
      .createServerLinkToken(this.server._id)
      .then((data: InviteToken) => {
        this.invitationLink = data;
        this.copyInviteToClipboard();
      });
  }

  copyInviteToClipboard() {
    this.clipboardApi.copy(this.invitationLink.token);
    this.openSnackBar('Invite Copied', null, 'mat-accent');
  }

  openSnackBar(message: string, action: string, color: string) {
    this._snackBar.open(message, action, {
      duration: this.snackbarDuration,
      verticalPosition: 'bottom',
      panelClass: ['center-snackbar', color],
    });
  }

  killWindow() {
    this.resetForm();
    this.visible = false;
    this.channel = null;
    /* this.modifyCategoryForm.reset();
      this.updateFormValidation();*/
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AbstractEndpointService,
  RequestTypes,
} from './abstract-endpoint.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { Message } from 'src/app/models/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends AbstractEndpointService {
  protected path = '/messages';

  constructor(
    protected httpClient: HttpClient,
    protected apiService: ApiService
  ) {
    super(apiService, httpClient);
  }

  getMessages(channelId: string, limit: number, offset: number) {
    return this.AuthenticatedRequest<Message[]>(
      RequestTypes.GET,
      `/${channelId}?limit=${limit}&offset=${offset}`
    ).then((response) => {
      response.reverse();
      return response;
    });
  }

  sendMessage(message: Message) {
    const messageToSend = {
      message: message.message,
      channelId: message.channelId,
    };
    console.log('Sending Message (API):');
    console.log('channelId: ' + message.channelId);
    return this.AuthenticatedRequest<Message>(
      RequestTypes.POST,
      '',
      messageToSend
    ).then((response) => {
      return response;
    });
  }

  deleteMessage(message: Message) {
    return this.AuthenticatedRequest<Message>(
      RequestTypes.DELETE,
      `/${message._id}`
    ).then((response) => {
      return response;
    });
  }

  editMessage(message: Message) {
    console.log('Editing Message (API):');
    console.log('message: ');
    console.log(message);

    return this.AuthenticatedRequest<Message>(
      RequestTypes.PATCH,
      `/${message._id}`,

      message
    ).then((response) => {
      return response;
    });
  }
}

export default MessageService;

import { Injectable } from '@angular/core';
import {
  AbstractEndpointService,
  RequestTypes,
} from './abstract-endpoint.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { Project } from 'src/app/models/project.model';
import { channel } from 'diagnostics_channel';

@Injectable({
  providedIn: 'root',
})
export class ChannelService extends AbstractEndpointService {
  protected path = '/channels';

  async GetAllChannelsForProject(projectId: string): Promise<object> {
    return this.AuthenticatedRequest<object>(
      RequestTypes.GET,
      `/project/${projectId}`
    );
  }

  //get all channels for category
  async GetAllChannelsForCategory(categoryId: string): Promise<object> {
    return this.AuthenticatedRequest<object>(
      RequestTypes.GET,
      `/category/${categoryId}`
    );
  }

  /*
  .post(`${this.path}`, this.CreateChannel)
      .patch(`${this.path}/:channelId`, this.PatchChannel)
      .delete(`${this.path}/:channelId`, this.DeleteChannel)
      .post(
        `${this.path}/:channelId/addUserToPrivateChannel`,
        this.AddUserToPrivateChannel
      )
      .post(
        `${this.path}/:channelId/removeUserFromPrivateChannel`,
        this.RemoveUserFromPrivateChannel
      )
      .post(
        `${this.path}/:channelId/leavePrivateChannel`,
        this.LeavePrivateChannel
      );
  */

  // create a new private channel
  async CreateChannel(): Promise<object> {
    const data = {
      channelParentIdType: 'user',
    };
    return this.AuthenticatedRequest<object>(RequestTypes.POST, ``, data);
  }

  // add a user to the channel
  async AddUserToPrivateChannel(
    channelId: string,
    usernames: string[]
  ): Promise<object> {
    const data = {
      usernames,
    };
    return this.AuthenticatedRequest<object>(
      RequestTypes.POST,
      `/${channelId}/addUserToPrivateChannel`,
      data
    );
  }

  // remove a user from the channel
  async RemoveUserFromPrivateChannel(
    channelId: string,
    usernames: [string]
  ): Promise<object> {
    const data = {
      usernames,
    };
    return this.AuthenticatedRequest<object>(
      RequestTypes.POST,
      `/${channelId}/removeUserFromPrivateChannel`,
      data
    );
  }

  // leave a channel
  async LeavePrivateChannel(channelId: string): Promise<object> {
    return this.AuthenticatedRequest<object>(
      RequestTypes.POST,
      `/${channelId}/leavePrivateChannel`
    );
  }
}

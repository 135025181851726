<div class="fullscreen" id="fullscreen" [class.fadein]="visible" [class.fadeout]="!visible" (click)="closeWindow($event)">
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div class="popup-box" [class.scalein]="visible" [class.scaleout]="!visible">
        <form [formGroup]="addCategoryForm" (ngSubmit)="onSubmit()">
          <h1>Create A New Category</h1>
          <div class="form-group">
            <label for="email">Category Name
              <input type="text" name="name" (keyup)="updateFormValidation()" formControlName="name" />
            </label>
            <label class="switcher" for="private">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
              </svg> 
              Private Category
              <input type="checkbox" class="switcher" name="private" formControlName="private" />
            </label>
            <div class="private-description">
              A private category only allows members you add with the roles you select to have access.
            </div>
          </div>
          <button class="btn btn-block" type="submit" name="submitButton" formControlName="submitButton" ngDefaultControl>
            <div class="btnText">Create Category</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

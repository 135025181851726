<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <form [formGroup]="addAssetForm" (ngSubmit)="onSubmit()">
          <h1>Create A New Asset</h1>
          <div class="form-group">
            <div class="thumbnail center">
              <label for="new-asset-thumbnail">
                <div class="thumbnail-overlay-main"></div>
                <img
                  *ngIf="selectedImage"
                  [src]="selectedImageSrc"
                  width="200"
                  height="200"
                />
                <div class="thumbnail-overlay">
                  <span>UPLOAD<br />IMAGE</span>
                </div>
                <div class="thumbnail-overlay-main">
                  <span>THUMBNAIL</span>
                </div>
              </label>
            </div>
            <input
              (change)="onFileUpload($event)"
              type="file"
              accept=".png,.jpg,.gif"
              name="thumbnail"
              id="new-asset-thumbnail"
              style="display: none"
            />

            <input
              placeholder="ASSET NAME"
              type="text"
              name="name"
              (keyup)="updateFormValidation()"
              formControlName="name"
            />

            <!--<div class="private-description">
              A private channel only allows members you add with the roles you select to have access.
            </div>-->
          </div>
          <button
            class="btn btn-block"
            type="submit"
            name="submitButton"
            formControlName="submitButton"
            ngDefaultControl
          >
            <div class="btnText">Create Asset</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Server } from '../models/server.model';
import { debug } from '../services/logger.service';
import { ServersService } from '../services/api/servers.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GlobalLocationResolverService } from '../services/global-location-resolver.service';
import { ProjectsService } from '../services/api/projects/projects.service';
import { Channel } from '../models/channel.model';
import { of } from 'rxjs';
@Component({
  selector: 'app-server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.scss'],
})
export class ServerComponent implements OnInit {
  selectedServer: Server;
  servers: Server[];
  selectedServerId;

  selectedChannelId;
  selectedChannel;
  selectedAssetTab: string = null; // or art-thread

  selectedAssetIndex = null;

  constructor(
    private serversService: ServersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalLocationService: GlobalLocationResolverService,
    private projectsService: ProjectsService
  ) {
    console.log('server - constructor');

    this.activatedRoute.paramMap.subscribe((routerParams: ParamMap) => {
      console.log(
        'server - constructor - activatedRoute: PARAMS CHANGED - UPDATING'
      );
      console.log(routerParams);
      if (!this.servers) {
        this.servers = this.serversService.getServers();
      }

      // const hasRootPathRoute = this.activatedRoute.snapshot?.children[0];
      console.log(
        'server - constructor: this.activatedRoute.snapshot?.children'
      );
      console.log(this.activatedRoute.snapshot.children[0]?.children);
      const projectId =
        this.activatedRoute.snapshot.children[0]?.paramMap.get('projectId');
      const categoryId =
        this.activatedRoute.snapshot.children[0]?.paramMap.get('categoryId');

      const serverId = this.activatedRoute.snapshot.paramMap.get('serverId');
      const chatId =
        this.activatedRoute.snapshot.children[0]?.children[0]?.paramMap.get(
          'chatId'
        );

      const wallId =
        this.activatedRoute.snapshot.children[0]?.children[0]?.paramMap.get(
          'wallId'
        );

      const assetId =
        this.activatedRoute.snapshot.children[0]?.children[0]?.children[0]?.paramMap.get(
          'assetId'
        );

      console.log('PARAM VALUES:');
      console.log('Server ID: ' + serverId);
      console.log('Project ID: ' + projectId);
      console.log('Category ID: ' + categoryId);
      console.log('Chat ID: ' + chatId);
      console.log('Wall ID: ' + wallId);
      console.log('Asset ID: ' + assetId);

      // if we have values set them as the active values
      if (serverId) {
        this.selectedServerId = serverId;
        this.globalLocationService.setActiveServerId(
          'server-component - constructor',
          serverId
        );
      }

      this.selectedServerId = serverId;

      this.updateSelectedServer();
      this.globalLocationService.setActiveServerId(
        'server-component - constructor',
        serverId
      );

      if (serverId && !chatId && !wallId) {
        // Navigate to first server item
        console.log('NO PROVIDED CHAT ID OR WALL ID');
        const firstChannel = this.serversService.getFirstChannel(
          serverId
        ) as Channel;
        const channelId = firstChannel?._id;
        const categoryId = firstChannel?.categoryId;
        if (channelId) {
          // TODO this assumes the first channel will be General (a chat channel)
          this.router.navigateByUrl(
            `/m/server/${serverId}/category/${categoryId}/chat/${channelId}`
          );
        }
      }

      if (projectId) {
        this.globalLocationService.setActiveProjectId(
          'server-component - constructor',
          projectId
        );
      }

      if (categoryId) {
        this.globalLocationService.setActiveCategoryId(
          'server - constructor',
          categoryId
        );
      }

      // get channels
      if (chatId) {
        console.log('Has chat id: ' + chatId);
        // set it as the active channel
        this.globalLocationService.setActiveChannelType(
          'server-component - constructor',
          'chat'
        );
        this.globalLocationService.setActiveChannelId(
          'server-component - constructor',
          chatId
        );
      }

      // find if channel if it exists in server

      if (wallId) {
        console.log('Has wall id: ' + wallId);

        this.globalLocationService.setActiveChannelType(
          'server-component - constructor',
          'art-wall'
        );
        this.globalLocationService.setActiveChannelId(
          'server-component - constructor',
          wallId
        );
      }

      if (assetId) {
        this.globalLocationService.setActiveChannelType(
          'server-component - constructor',
          'asset'
        );
        this.globalLocationService.setActiveAssetId(
          'server-component - constructor',
          assetId
        );

        //TODO: find which tab they are on
        console.log('URL segments:');
        this.activatedRoute.url.forEach((url) => {
          console.log(url);
        });
      } else {
        this.globalLocationService.setActiveAssetTab(
          'server-component - constructor',
          null
        );
        this.globalLocationService.setActiveAssetId(
          'server-component - constructor',
          null
        );
      }

      if (chatId || wallId) {
        const channel = this.serversService.getChannel(
          this.globalLocationService.getActiveChannelId()
        );
        // this.updateSelectedChannel(channel);
      }
    });
  }

  ngOnInit(): void {
    this.selectedServerId =
      this.activatedRoute.snapshot.paramMap.get('serverId');
    this.serversService.servers.subscribe((servers: Server[]) => {
      this.servers = servers;
      this.updateSelectedServer();
    });
  }

  updateSelectedServer() {
    if (this.selectedServerId && this.servers) {
      let matchedServer = null;
      this.servers.forEach((server) => {
        if (this.selectedServerId === server._id) {
          matchedServer = server;
        }
      });
      if (matchedServer) {
        this.selectedServer = matchedServer;
        // get projects for this server
        this.projectsService.GetProjects(matchedServer._id);
      }
    }
  }

  updateSelectedAsset(value) {
    this.selectedAssetTab = 'thread';
    this.selectedAssetIndex = value;
    debug('selected asset tab changed to: ' + this.selectedAssetTab);
  }

  /*updateSelectedChannel(channel) {
    /* 
    console.log('server - updateSelectedChannel:');
    console.log(channel);
    if (channel?._id) {
      let navPath = 'chat';
      if (channel.channelType === 'art-wall') {
        navPath = 'art-wall';
        this.router.navigate([navPath, channel._id, 'assets'], {
          relativeTo: this.activatedRoute,
        });
      } else {
        navPath = 'chat';
        this.router.navigate([navPath, channel._id], {
          relativeTo: this.activatedRoute,
        });
      } }*/
  /*this.router.navigate([navPath, channel._id], {
        relativeTo: this.activatedRoute,
      });
      channel.channelId = channel._id;
    }
    this.selectedChannel = channel;
    this.globalLocationService.setActiveChannelId(
      'server - updateSelectedChannel',
      channel?._id
    );
    this.selectedAssetIndex = null;
  }*/

  changeAssetTab(tab) {
    this.selectedAssetTab = tab;
    if (tab == null) {
      this.selectedAssetIndex = 0;
    }
  }

  showFriendsList() {
    this.selectedChannel = { channelType: 'none' };
  }
}
function expand(arg0: (route: any) => any): any {
  throw new Error('Function not implemented.');
}

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import {debug, warn, error, info} from 'src/app/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  constructor(/*private socket: Socket*/) { }

  initialize() {
/*
    this.socket.emit('message', 'test message');
    this.socket.on('message', data => {
      debug('message', 'from: ' + data.from + ' | message: ' + data.message);
    });
*/
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api/api.service';
import { ServersService } from 'src/app/services/api/servers.service';
import { Server } from 'src/app/models/server.model';

@Component({
  selector: 'app-accept-server-invitation',
  templateUrl: './accept-server-invitation.component.html',
  styleUrls: ['./accept-server-invitation.component.scss']
})
export class AcceptServerInvitationComponent implements OnInit {
  @Input() selectedServer: Server;
  user: User;

  constructor(
    private apiService: ApiService,
    private serverService: ServersService,
  ) {
    this.apiService.user.subscribe( user => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

  acceptInvitation() {
    this.serverService.joinServer(this.selectedServer._id);
  }

  deleteInvitation() {
    this.serverService.removeInvitation(this.selectedServer._id);
    // TODO push to home here
    // this.selectedServerState.emit('home');
  }

}

import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { FileService } from 'src/app/services/api/file.service';
import { PostsService } from 'src/app/services/api/assets/posts.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-edit-thread-post',
  templateUrl: './edit-thread-post.component.html',
  styleUrls: ['./edit-thread-post.component.scss'],
})
export class EditThreadPostComponent implements OnInit {
  visible = false;
  selectedImage;
  selectedImageSrc: string;

  public editPostForm = new FormGroup({
    caption: new FormControl('', [Validators.required]),
    postId: new FormControl('', [Validators.required]),
    assetId: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private assetsService: AssetsService,
    private postsService: PostsService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.editPostForm.get('submitButton')?.disable();
    this.popupController.editPost.subscribe((data: any) => {
      debug('Reloading Edit Post Popup');
      if (data.post == null) {
        this.visible = false;
      } else {
        this.visible = true;
      }

      this.editPostForm.get('postId')?.setValue(data.post._id);
      this.editPostForm.get('caption')?.setValue(data.post.caption);
      this.editPostForm.get('assetId')?.setValue(data.assetId);
      this.selectedImage =
        'https://artdominion.imgix.net/' + data.post.imageUrl;
      this.selectedImageSrc =
        'https://artdominion.imgix.net/' + data.post.imageUrl;
      debug(data);
      debug('selectedImage: ' + this.selectedImage);
      // this.editPostForm.get('owner')?.setValue(data.owner);
      debug('edit post popup - postId: ' + data.post);
      this.updateFormValidation();
    });
  }

  onSubmit() {
    debug('Sending patch Post Request. Data:');
    debug(this.editPostForm.getRawValue());

    // just patch the post
    this.postsService.PatchPost(this.editPostForm).then((response) => {
      debug('Patch Post Response: ');
      debug(response);
      this.selectedImage = null;
      this.selectedImageSrc = null;
      this.editPostForm.get('postId')?.setValue('');
      this.editPostForm.get('caption')?.setValue('');
      this.editPostForm.get('assetId')?.setValue('');
      this.killWindow();
    });
  }

  async updateFormValidation() {
    if (this.editPostForm.valid) {
      this.editPostForm.get('submitButton')?.enable();
    } else {
      this.editPostForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.editPostForm.reset();
    this.updateFormValidation();
  }

  onFileUpload(event) {
    debug(event);
    debug('selected new image');
    const image = (event.target as HTMLInputElement).files[0];

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedImageSrc = reader.result as string;
      };
    }

    this.selectedImage = image;
    debug(this.selectedImage);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

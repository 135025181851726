enum logLevels {
  INFO = 1,
  DEBUG = 2,
  WARN = 3,
  ERROR = 4,
}

export const environment = {
  name: 'dev',
  apiUrl: 'https://dev.api.artdominion.com',
  debugLevel: logLevels.DEBUG,
};
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <form [formGroup]="uploadAvatarForm">
          <h1>Upload an Avatar</h1>
          <div class="form-group">
            <div class="thumbnail center">
              <label for="avatar">
                <div class="thumbnail-overlay-main"></div>
                <img
                  *ngIf="selectedImage"
                  [src]="selectedImage"
                  width="200"
                  height="200"
                />
                <div class="thumbnail-overlay">
                  <span>UPLOAD<br />IMAGE</span>
                </div>
                <div class="thumbnail-overlay-main">
                  <span>AVATAR</span>
                </div>
              </label>
            </div>
            <input
              (change)="onFileUpload($event)"
              type="file"
              accept=".png,.jpg,.gif"
              name="avatar"
              id="avatar"
              style="display: none"
            />
          </div>
          <button
            type="submit"
            class="btn btn-block"
            (click)="closeWindow()"
            name="submitButton"
          >
            <div class="btnText">Close</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api/api.service';
import { Router } from '@angular/router';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {debug, warn, error, info} from 'src/app/services/logger.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {
  user: User;
  username: string;
  usercode: string;
  private _userSubscription;

  constructor(private apiService: ApiService,
              private popupController: PopupControllerService
  ) { }

  ngOnInit(): void {

    this._userSubscription = this.apiService.user.subscribe((user: User) => {
      this.user = user;
      const usernameParts = user.username.split('#');
      this.username = usernameParts[0];
      this.usercode = usernameParts[1];
    });

    this.apiService.GetCurrentUser();

    // this.setUser(this.apiService.user);

    debug('user: ' + JSON.stringify(this.user));
  }
 /* 
  setUser(user: User) {
    this.user = user;
    const usernameParts = user.username.split('#');
    this.username = usernameParts[0];
    this.usercode = usernameParts[1];
  }
*/
  onSettingsClick() {
    // this.router.navigate([{ outlets: { fullscreen: ['user-settings'] }}],{relativeTo:this.route});
    this.popupController.openUserSettingsPopup();
  }

  onAvartarClick() {
    debug('clicked!');
    this.popupController.openEditAvatarPopup();
  }
}

import {
  Directive,
  EventEmitter,
  Output,
  HostListener,
  HostBinding,
} from '@angular/core';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Directive({
  selector: '[DragAndDrop]',
})
export class DragAndDropDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileHover = new EventEmitter<any>();

  @HostBinding('class.canvas-fileover') fileOver: boolean;

  @HostListener('dragover', ['$event']) onDragOver(event) {
    debug('Drag Over');
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
    this.fileHover.emit(true);
  }

  @HostListener('dragLeave', ['$event']) onDragLeave(event) {
    debug('Drag Leave');
    event.preventDefault();
    event.stopPropagation();
    this.fileHover.emit(false);
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    debug('Drop');
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    this.fileDropped.emit(event);
    this.fileHover.emit(false);
  }
}

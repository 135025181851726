<div class="contextmenu" [ngStyle]="{'left.px': x, 'top.px': y}" [class.contextmenu-fadein]="visible" [class.contextmenu-fadeout]="!visible"> <!-- *ngIf="visible" -->
  <div class="contextmenu-content">






  <!-- Server -->
    <ul>
      <label for="imageinput">
        <li>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="artboard-add-image" viewBox="0 0 16 16">
            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
          </svg>
          Image
        </li>
      </label>
      <li>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="artboard-add-image" viewBox="0 0 16 16">
          <path d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874V2.5zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5v3.563zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          <path d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386L11.434 4z"/>
        </svg>
        Text
      </li>
      
      <!-- <hr/> -->
    </ul>
    <input (change)="onFileUpload($event)" id="imageinput" name="filename" type="file" />
  <!-- Server END -->







  </div>
</div>

import { Component, OnInit, HostListener } from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss'],
})
export class NewCategoryComponent implements OnInit {
  visible = false;
  public addCategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    private: new FormControl(''),
    server: new FormControl(''),
    owner: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serversService: ServersService
  ) {}

  ngOnInit(): void {
    this.addCategoryForm.get('submitButton')?.disable();
    this.popupController.newCategory.subscribe((data: any) => {
      debug('Reloading New Category Popup: payload:');
      console.log(data);
      if (data.serverId == null) {
        this.visible = false;
      } else {
        this.visible = true;
      }
      this.addCategoryForm.get('server')?.setValue(data.serverId);
      this.addCategoryForm.get('owner')?.setValue(data.owner);
      //TODO: [AC-39] we need to add the server id into the request being sent to the create category function
      debug('new category popup - ServerId: ' + data.serverId);
    });
  }

  onSubmit() {
    debug('Sending Create Category Request. Data:');
    this.serversService
      .createCategory(this.addCategoryForm)
      .then((response) => {
        debug('Create Server Response:');
        debug(response);
      })
      .catch((error) => {
        error(error);
      });

    this.killWindow();
  }

  async updateFormValidation() {
    if (this.addCategoryForm.valid) {
      this.addCategoryForm.get('submitButton')?.enable();
    } else {
      this.addCategoryForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.addCategoryForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Asset } from 'src/app/models/asset.model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AbstractEndpointService,
  RequestTypes,
} from '../abstract-endpoint.service';
import { AssetsService } from './assets.service';
import { Message } from 'src/app/models/message.model';

@Injectable({
  providedIn: 'root',
})
export class AssetFilesService extends AbstractEndpointService {
  protected path = '/fileStore/asset/';

  async GetFolderContents(assetId: string, folder: string): Promise<object> {
    console.log('GetFolderContents');
    const data = {
      assetId: assetId,
      folder,
    };
    const response = this.AuthenticatedRequest<any>(
      RequestTypes.POST,
      'list-contents',
      data
    );
    return response;
  }

  async DownloadFile(assetId: string, file: string) {
    console.log('download file');
    const data = {
      assetId,
      file,
    };

    // manual to set headers to text
    return this.httpClient
      .post<any>(
        `${this.apiService.URI}${this.path}request-file-download`,
        data,
        { ...this.apiService.options, responseType: 'text' }
      )
      .toPromise();
    /*
    const response = await this.AuthenticatedReqest<any>(
      RequestTypes.POST,
      'request-file-download',
      data
    );

    console.log('response');
    console.log(response);
    return response;*/
  }

  async RequestFileUpload(assetId: string, file: string) {
    const data = {
      assetId,
      file,
    };
    // manual to set headers to text
    return this.httpClient
      .post<any>(
        `${this.apiService.URI}${this.path}request-file-upload`,
        data,
        { ...this.apiService.options, responseType: 'text' }
      )
      .toPromise();
  }

  async DeleteFile(assetId: string, file: string) {
    console.log('delete file');
    const data = {
      assetId,
      file,
    };
    const response = this.AuthenticatedRequest<any>(
      RequestTypes.POST,
      'delete-file',
      data
    );
    return response;
  }

  async CreateFolder(assetId: string, folder: string) {
    console.log('create folder');
    const data = {
      assetId,
      folder,
    };

    const response = this.AuthenticatedRequest<any>(
      RequestTypes.POST,
      'create-folder',
      data
    );
    return response;
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-art-main',
  templateUrl: './art-main.component.html',
  styleUrls: ['./art-main.component.scss']
})

export class ArtMainComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.url.subscribe(events => {
      console.log("the url events", events)
    })
  }

  ngOnInit(): void {
  }

}

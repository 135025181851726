import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Asset } from 'src/app/models/asset.model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AbstractEndpointService,
  RequestTypes,
} from '../abstract-endpoint.service';
import { AssetsService } from './assets.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class PostsService extends AbstractEndpointService {
  protected path = '/posts';

  constructor(
    protected httpClient: HttpClient,
    protected apiService: ApiService,
    private assetsService: AssetsService
  ) {
    super(apiService, httpClient);
  }

  async CreatePost(
    data: FormGroup,
    imageSize: { x; y },
    channelId: string
  ): Promise<Asset> {
    // IS THE IMAGE SIZE COMING THROUGH?
    console.log('imageSize:');
    console.log(imageSize);

    console.log('SENDING CREATE POST REQUEST');
    console.log('data.getRawValue():');
    console.log(data.getRawValue());
    const newData = {
      ...data.getRawValue(),
      width: imageSize.x,
      height: imageSize.y,
    };
    console.log('newData:');
    console.log(newData);
    return this.AuthenticatedRequest<Asset>(
      RequestTypes.POST,
      '',
      newData
    ).then(async (asset) => {
      console.log('CREATE POST RESPONSE:');
      console.log(asset);
      this.assetsService.GetAllChannelAssets(channelId);
      return asset;
    });
  }

  async PatchPost(data: FormGroup): Promise<Asset> {
    const rawData = data.getRawValue();
    return this.AuthenticatedRequest<Asset>(
      RequestTypes.PATCH,
      '',
      rawData
    ).then(async (asset) => {
      await this.assetsService.GetAllChannelAssets(asset.channelId);
      return asset;
    });
  }

  async RemovePost(postId: string, assetId: any): Promise<boolean> {
    const data = { postId, assetId };
    const response = await this.AuthenticatedRequest<any>(
      RequestTypes.DELETE,
      `/${postId}/${assetId}`,
      data
    ).then(async (asset) => {
      await this.assetsService.GetAllChannelAssets(asset.channelId);
      return asset;
    });

    debug('Remove Post Response:');
    debug(response);

    return true;
  }
}

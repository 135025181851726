import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogType } from '../screens/popups/dialog/dialog.component';
import { ChannelParentIdType } from '../enums/channel-Parent-Id-Type.enum';

@Injectable({
  providedIn: 'root',
})
export class PopupControllerService {
  constructor() {}

  // User Settings
  userSettings: Subject<boolean> = new Subject<boolean>();
  public closeUserSettingsPopup() {
    this.userSettings.next(false);
  }
  public openUserSettingsPopup() {
    this.userSettings.next(true);
  }

  //New Server Popup
  newServer: Subject<boolean> = new Subject<boolean>();
  public closeNewServerPopup() {
    this.newServer.next(false);
  }
  public openNewServerPopup() {
    this.newServer.next(true);
  }

  //New Friend Popup
  newFriend: Subject<boolean> = new Subject<boolean>();
  public closeNewFriendPopup() {
    this.newFriend.next(false);
  }
  public openNewFriendPopup() {
    this.newFriend.next(true);
  }

  //New Category Popup
  newCategory: Subject<any> = new Subject<any>();
  public closeNewCategoryPopup() {
    this.newCategory.next(null);
  }
  public openNewCategoryPopup(serverId: string, owner: string) {
    this.newCategory.next({ serverId, owner });
  }

  //New Project Popup
  newProject: Subject<any> = new Subject<any>();
  public closeNewProjectPopup() {
    this.newProject.next(null);
  }
  public openNewProjectPopup(serverId: string, owner: string) {
    this.newProject.next({ serverId, owner });
  }

  //Modify Project Popup
  modifyProject: Subject<any> = new Subject<any>();
  public closeModifyProjectPopup() {
    this.modifyProject.next(null);
  }
  public openModifyProjectPopup(serverId: string, project: object) {
    this.modifyProject.next({ serverId, project });
  }

  modifyCategory: Subject<any> = new Subject<any>();
  public closeModifyCategory() {
    this.modifyCategory.next(null);
  }
  public openModifyCategory(serverId, category: object) {
    this.modifyCategory.next({ serverId, category });
  }

  //New Channel Popup
  newChannel: Subject<any> = new Subject<any>();
  public closeNewChannelPopup() {
    this.newChannel.next(null);
  }
  public openNewChannelPopup(
    serverId: string,
    id: string,
    channelParentIdType: ChannelParentIdType,
    owner: string
  ) {
    this.newChannel.next({ serverId, id, channelParentIdType, owner });
  }

  modifyChannel: Subject<any> = new Subject<any>();
  public closeModifyChannel() {
    this.modifyChannel.next(null);
  }
  public openModifyChannel(serverId, categoryId, channel: object) {
    this.modifyChannel.next({ serverId, categoryId, channel });
  }

  newAsset: Subject<any> = new Subject<any>();
  public closeNewAssetPopup() {
    this.newAsset.next(null);
  }
  public openNewAssetPopup(serverId: string, channelId: string, owner: string) {
    this.newAsset.next({ serverId, channelId, owner });
  }

  modifyAsset: Subject<any> = new Subject<any>();
  public closeModifyAsset() {
    this.modifyAsset.next(null);
  }
  public openModifyAsset(asset: object) {
    this.modifyAsset.next({ asset });
  }

  newPost: Subject<any> = new Subject<any>();
  public closeNewPostPopup() {
    this.newPost.next(null);
  }
  public openNewPostPopup(assetId: string, owner: string) {
    this.newPost.next({ assetId, owner });
  }

  editPost: Subject<any> = new Subject<any>();
  public closeEditPostPopup() {
    this.editPost.next(null);
  }
  public openEditPostPopup(post: object, assetId: string) {
    this.editPost.next({ post, assetId });
  }

  modifyServer: Subject<any> = new Subject<any>();
  public closeModifyServer() {
    this.modifyServer.next(null);
  }
  public openModifyServer(server: object) {
    this.modifyServer.next({ server });
  }

  avatar: Subject<any> = new Subject<any>();
  public closeEditAvatarPopup() {
    this.avatar.next(null);
  }
  public openEditAvatarPopup() {
    this.avatar.next(true);
  }

  dialog: Subject<any> = new Subject<any>();
  public closeDialog() {
    this.dialog.next(null);
  }
  public openDialog(
    type: DialogType,
    title: string,
    description: string,
    callbackEvent: Subject<any>,
    inputLabel: string = null
  ) {
    this.dialog.next({ type, title, description, callbackEvent, inputLabel });
  }

  profileHover: Subject<any> = new Subject<any>();
  public closeProfileHover() {
    this.profileHover.next(null);
  }
  public openProfileHover(username, x, y) {
    this.profileHover.next({ username, x, y });
  }

  profile: Subject<any> = new Subject<any>();
  public closeProfile() {
    this.profile.next(null);
  }
  public openProfile(username) {
    this.profile.next({ username });
  }

  invitePeoplePopup: Subject<any> = new Subject<any>();
  public openInvitePeoplePopup(server) {
    this.invitePeoplePopup.next(server);
  }

  newDm: Subject<any> = new Subject<any>();
  public closeNewDmPopup() {
    this.newDm.next(false);
  }

  public openNewDmPopup(channelId: string = null) {
    const data = channelId == null ? true : channelId;
    this.newDm.next(data);
  }
}

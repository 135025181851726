import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { Asset } from 'src/app/models/asset.model';
import { Server } from 'src/app/models/server.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api/api.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import {
  ContextMenuService,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServersService } from '../../../services/api/servers.service';
import { GlobalLocationResolverService } from '../../../services/global-location-resolver.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-art-wall-channel',
  templateUrl: './art-wall-channel.component.html',
  styleUrls: ['./art-wall-channel.component.scss'],
})
export class ArtWallChannelComponent implements OnInit, OnDestroy {
  selectedServer: Server | undefined;

  selectedChannel;

  @Output()
  selectedAssetTabState = new EventEmitter<string>();

  @Output()
  selectedAssetState = new EventEmitter<string>();

  @Input()
  selectedAssetTab;

  assets: Asset[];

  selectedChannelId: string;

  user: User;

  timer;

  constructor(
    private assetService: AssetsService,
    private serversService: ServersService,
    private popupControllerService: PopupControllerService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private contextMenuService: ContextMenuService,
    private router: Router,
    private globalLocationResolver: GlobalLocationResolverService
  ) {
    this.activatedRoute.params.subscribe(async (routerParams) => {
      console.log('art-wall-channel - constructor - activatedRoute: ');
      console.log(this.activatedRoute.snapshot);

      this.user = await this.apiService.GetCurrentUser();

      const serverId = this.activatedRoute.snapshot.paramMap?.get('serverId');
      const channelId = this.activatedRoute.snapshot.paramMap?.get('wallId');

      if (serverId) {
        this.globalLocationResolver.setActiveServerId(
          'art-wall-channel - constructor',
          serverId
        );
        this.selectedServer = serversService.getServer(serverId);
      }
      if (channelId) {
        this.globalLocationResolver.setActiveChannelId(
          'art-wall-channel - constructor',
          channelId
        );
        this.selectedChannelId = channelId;
        this.selectedChannel = serversService.getChannel(channelId);
      }

      this.globalLocationResolver.activeServerIdSub.subscribe((serverId) => {
        this.selectedServer = serversService.getServer(serverId);
        this.fetchAssets();
      });

      this.globalLocationResolver.activeChannelIdSub.subscribe((channelId) => {
        this.selectedChannel = serversService.getChannel(channelId);
        this.selectedChannelId = channelId;
        this.fetchAssets();
      });

      this.assetService.assets.subscribe((assets) => {
        this.assets = assets;
      });

      console.log(
        'ArtWallChannelComponent - constructor - serverId: ' + serverId
      );
      console.log(
        'ArtWallChannelComponent - constructor - channelId: ' + channelId
      );

      this.fetchAssets();
      // setInterval a timer to get latest assets
      this.timer = setInterval(() => {
        console.log('fetched for new assets');
        this.fetchAssets();
      }, 10000);
    });
  }

  ngOnInit(): void {
    this.globalLocationResolver.activeAssetTabSub.subscribe((activeTab) => {
      this.selectedAssetTab = activeTab;
    });
  }

  ngOnDestroy() {
    console.log('Destryed ArtWallChannelComponent');
    this.timer = null;
  }

  fetchAssets() {
    console.log('fetching assets');
    this.assetService.GetAllChannelAssets(this.selectedChannelId);
  }

  changeAssetTab(tab: string) {
    this.selectedAssetTabState.emit(tab);
  }

  openAsset(assetId: string) {
    debug('Opening Asset: ' + assetId);
    // TODO push the asset
    this.router.navigate(['asset', assetId], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  newAsset() {
    console.log('new asset');
    console.log('selected server: ' + this.selectedServer);
    console.log('selected channelId: ' + this.selectedChannelId);
    console.log('loggedInUser: ' + this.user);
    this.popupControllerService.openNewAssetPopup(
      this.selectedServer._id,
      this.selectedChannelId,
      this.user._id
    );
  }

  openContextMenu(asset, event: MouseEvent) {
    console.log(event);
    console.log(event.target);
    const data = {
      asset,
      isOwner: asset.owner == this.user._id,
    };
    this.contextMenuService.show(
      event.x,
      event.y,
      ContextTypes.artwall_asset,
      data
    );
  }
}

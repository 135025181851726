<div class="sub-main">
  <app-channel-header
    [selectedAssetId]="selectedAssetId"
    (selectedAssetTabState)="changeAssetTab($event)"
  ></app-channel-header>
  <div class="chat">
    <div class="files-box">
      <div>Folder: /{{ currentFolder }}</div>
      <div class="file-box-header">
        <button class="file-box-header-button" (click)="newFolder()">
          New Folder
        </button>
        <button class="file-box-header-button">
          <label for="fileUpload"></label>
          Upload
        </button>
        <button
          class="file-box-header-button"
          [disabled]="!selectedFile"
          (click)="downloadFile(selectedFile)"
        >
          Download
        </button>
        <button
          class="file-box-header-button"
          [disabled]="!selectedFile"
          (click)="deleteFile()"
        >
          Delete
        </button>
      </div>

      <div
        class="file-box-contents scroll"
        DragAndDrop
        (fileDropped)="onDropFileUpload($event)"
        (fileHover)="onDragHover($event)"
      >
        <div
          class="no-files"
          *ngIf="contents.length == 0 && isWaitingForServerResponse"
        >
          <span class="a-all a1">.</span>
          <span class="a-all a2">.</span>
          <span class="a-all a3">.</span>
        </div>

        <div
          class="no-files"
          *ngIf="contents.length == 0 && !isWaitingForServerResponse"
        >
          No Files Or Folders Found
        </div>

        <div class="drag-files" *ngIf="isDragHovering">
          <div class="no-files">Drop Files Here</div>
        </div>

        <div *ngIf="!isDragHovering">
          <div
            class="row"
            *ngFor="let item of contents; let i = index"
            [class.folder]="item.type == 'folder'"
            [class.file]="item.type == 'file'"
            (click)="selectFile(i)"
            [class.selected]="selectedFile == i"
            (dblclick)="dblClick(i)"
          >
            <div class="col-9 description">
              <span class="icon" *ngIf="item.type == 'folder'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-folder-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"
                  />
                </svg>
              </span>

              <span class="icon" *ngIf="item.type == 'file'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-file-earmark"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                  />
                </svg>
              </span>
              {{ item.name }}
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-card *ngIf="isUploading">
  <mat-card-header>
    <mat-card-title>Uploading {{ currentUploadingFile.name }}</mat-card-title>
    <mat-card-subtitle
      >{{ fileUploadIndex }}/{{ fileUploadCount }} completed</mat-card-subtitle
    >
  </mat-card-header>

  <mat-card-footer>
    <mat-progress-bar
      mode="buffer"
      [value]="(100 / fileUploadCount) * fileUploadIndex"
      [bufferValue]="(100 / fileUploadCount) * (fileUploadIndex + 1)"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>

<form [formGroup]="fileUploadForm">
  <input
    (change)="onFileUpload($event)"
    type="file"
    accept=".png,.jpg,.gif"
    name="fileUpload"
    id="fileUpload"
    style="display: none"
  />
</form>

<div
  [class.hide-servers]="currentEditorMode == eArtThreadMode.EDITOR_MODE"
  (click)="exitEditorMode()"
></div>
<div class="sub-main">
  <app-channel-header
    [selectedAssetId]="selectedAssetId"
    (selectedAssetTabState)="changeAssetTab($event)"
  >
  </app-channel-header>
  <div class="chat">
    <div class="thread-container leftscrollbar" *ngIf="asset">
      <div #threadContainer class="post-container">
        <div
          class="post"
          *ngFor="let post of asset.posts; let iP = index"
          (click)="selectPost(iP)"
        >
          <div class="post-header">
            <div
              *ngIf="post && post.ownerUser && post.ownerUser.avatar"
              class="avatar"
              [ngStyle]="{
                'background-image':
                  'url(https://artdominion.imgix.net/' +
                  post.ownerUser.avatar +
                  '?w=35&h=35)'
              }"
            >
              <div class="status"></div>
            </div>

            <div
              *ngIf="!post || !post.ownerUser || !post.ownerUser.avatar"
              class="no-avatar"
            >
              <div *ngIf="post && post.ownerUser" class="initials">
                {{ post.ownerUser.username.slice(0, 2) }}
              </div>
            </div>

            <div
              class="information"
              (click)="onOpenProfileHoverClick(post.ownerUser.username, $event)"
              (contextmenu)="
                onRightClickUsername(
                  $event,
                  eContextTypes.username,
                  post.ownerUser.username
                )
              "
            >
              {{ post.ownerUser?.username?.split("#")[0]
              }}<span class="post-date">{{ post.createdAt }}</span>
            </div>
            <div class="right-action-buttons">
              <div
                class="kebab-action-button"
                (click)="openKababMenu($event, post)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                  />
                </svg>
              </div>
            </div>
            <!--<div class="add-drawing-button" ngbTooltip="Draw" placement="bottom" (click)="createDrawingComment(i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                  class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>
            </div>-->
          </div>
          <div class="post-text">{{ post.caption }}</div>
          <div
            class="post-focus-group"
            [class.editor-focus]="
              currentEditorMode == eArtThreadMode.EDITOR_MODE &&
              selectedPost._id == post._id
            "
          >
            <div
              class="post-image"
              (mouseenter)="onPostImageVisible(iP, true)"
              (mouseleave)="onPostImageVisible(iP, false)"
              [class.editor-focus]="
                currentEditorMode == eArtThreadMode.EDITOR_MODE &&
                selectedPost._id == post._id
              "
            >
              <!-- Image button overlay -->
              <div
                class="image-action-buttons"
                [class.image-action-buttons-visible]="post.isPostImageHovered"
              >
                <div
                  class="image-action-button"
                  [class.image-action-button-inactive]="
                    post.isAllElementsHidden
                  "
                  (click)="
                    changePostDrawingVisibility(iP, !post.isAllElementsHidden)
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-brush-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04z"
                    />
                  </svg>
                </div>
              </div>

              <div
                *ngIf="
                  currentEditorMode == eArtThreadMode.EDITOR_MODE &&
                  selectedPost._id == post._id
                "
                class="editor-toolbar"
              >
                <!-- Selection Tool -->
                <div
                  class="tool-button first"
                  [class.selected]="currentToolMode == eToolMode.SELECTION_TOOL"
                  (click)="changeEditorTool(eToolMode.SELECTION_TOOL)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-cursor-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"
                    />
                  </svg>
                </div>

                <!-- Polygon Tool -->
                <div
                  class="tool-button"
                  [class.selected]="currentToolMode == eToolMode.POLYGON_TOOL"
                  (click)="changeEditorTool(eToolMode.POLYGON_TOOL)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-pentagon-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 0 8 6.5-3 9.5H3L0 6.5 8 0z" />
                  </svg>
                </div>

                <!-- Rect Tool -->
                <div
                  class="tool-button"
                  [class.selected]="currentToolMode == eToolMode.RECT_TOOL"
                  (click)="changeEditorTool(eToolMode.RECT_TOOL)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-square-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
                    />
                  </svg>
                </div>

                <!-- Circle Tool -->
                <!--
                <div class="tool-button tool-button-disabled" [class.selected]="currentToolMode == eToolMode.CIRCLE_TOOL"
                    (click)="changeEditorTool(eToolMode.CIRCLE_TOOL)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                  </svg>
                </div>
                -->
                <!-- Text Tool -->
                <!--
                <div class="tool-button" [class.selected]="currentToolMode == eToolMode.TEXT_TOOL"
                    (click)="changeEditorTool(eToolMode.TEXT_TOOL)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-textarea-t" viewBox="0 0 16 16">
                    <path
                      d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874V2.5zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5v3.563zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    <path
                      d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386L11.434 4z"/>
                  </svg>
                </div>
                -->
                <!-- new Tools -->

                <!-- Arrow Tool -->
                <!--
                <div class="tool-button tool-button-disabled" [class.selected]="currentToolMode == eToolMode.ARROW_TOOL"
                    (click)="changeEditorTool(eToolMode.ARROW_TOOL)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-arrow-down-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z"/>
                  </svg>
                </div>
                -->

                <!-- Line Tool -->
                <!--
                <div class="tool-button tool-button-disabled" [class.selected]="currentToolMode == eToolMode.LINE_TOOL"
                    (click)="changeEditorTool(eToolMode.LINE_TOOL)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-slash-lg"
                      viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                  </svg>
                </div>
                -->

                <!-- Freehand Tool -->
                <div
                  class="tool-button"
                  [class.selected]="currentToolMode == eToolMode.FREEHAND_TOOL"
                  (click)="changeEditorTool(eToolMode.FREEHAND_TOOL)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-brush-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04z"
                    />
                  </svg>
                </div>

                <!-- Eraser Tool -->
                <div
                  class="tool-button"
                  [class.selected]="currentToolMode == eToolMode.ERASER"
                  (click)="changeEditorTool(eToolMode.ERASER)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-eraser-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"
                    />
                  </svg>
                </div>

                <!-- Right Align -->
                <div class="editor-toolbar-colors">
                  <div
                    [class.editor-toolbar-color-selected]="
                      selectedColor == eColorList.green
                    "
                    class="editor-toolbar-color editor-color-green"
                    (click)="changeColor(eColorList.green)"
                  ></div>
                  <div
                    [class.editor-toolbar-color-selected]="
                      selectedColor == eColorList.red
                    "
                    class="editor-toolbar-color editor-color-red"
                    (click)="changeColor(eColorList.red)"
                  ></div>
                  <div
                    [class.editor-toolbar-color-selected]="
                      selectedColor == eColorList.yellow
                    "
                    class="editor-toolbar-color editor-color-yellow"
                    (click)="changeColor(eColorList.yellow)"
                  ></div>
                  <div
                    [class.editor-toolbar-color-selected]="
                      selectedColor == eColorList.blue
                    "
                    class="editor-toolbar-color editor-color-blue"
                    (click)="changeColor(eColorList.blue)"
                  ></div>
                  <div
                    [class.editor-toolbar-color-selected]="
                      selectedColor == eColorList.purple
                    "
                    class="editor-toolbar-color editor-color-purple"
                    (click)="changeColor(eColorList.purple)"
                  ></div>
                  <div
                    [class.editor-toolbar-color-selected]="
                      selectedColor == eColorList.orange
                    "
                    class="editor-toolbar-color editor-color-orange"
                    (click)="changeColor(eColorList.orange)"
                  ></div>
                </div>
              </div>
              <!--<div class="debug-mousepos">x: {{mousePosition.x}}, y: {{mousePosition.y}}</div>-->
              <div
                class="svgs"
                (mousedown)="onMouseDown($event, iP)"
                (mouseup)="onMouseUp($event, iP)"
                (mousemove)="onMouseMove($event, iP)"
              >
                <svg>
                  <!--  height="651.83" width="1062.4" -->
                  <!--<polygon points="220,10 300,210 170,250 123,234" />-->
                  <!--<polygon points="0,0 0,50 55,50 40,80 75,50 150,50 150,0" />-->
                  <!-- <polygon class="chat-balloon" points="600,72 600,122 657,122 642,152 677,122 754,122 754,72" /> -->
                  <!-- <text class="text-balloon" x="608" y="100">{{testing()}}</text> <!-- {{eToolMode[currentToolMode]}} -->

                  <g *ngIf="newComment == null">
                    <!-- Existing Comments -->

                    <g *ngFor="let comment of post.comments">
                      <g
                        *ngIf="
                          currentEditorMode == eArtThreadMode.VIEW_MODE ||
                          (currentEditorMode == eArtThreadMode.EDITOR_MODE &&
                            comment == selectedComment)
                        "
                      >
                        <g *ngIf="selectedComment != comment">
                          <g
                            *ngFor="
                              let element of comment.elements;
                              let iC = index
                            "
                            [ngSwitch]="element.elType"
                          >
                            <g
                              *ngIf="
                                !comment.completed &&
                                !post.isAllElementsHidden &&
                                !comment.isElementsHidden
                              "
                            >
                              <polygon
                                *ngSwitchCase="eToolMode.POLYGON_TOOL"
                                [class.thread-color-blue]="
                                  comment.color == eColorList.blue
                                "
                                [class.thread-color-green]="
                                  comment.color == eColorList.green
                                "
                                [class.thread-color-red]="
                                  comment.color == eColorList.red
                                "
                                [class.thread-color-yellow]="
                                  comment.color == eColorList.yellow
                                "
                                [class.thread-color-orange]="
                                  comment.color == eColorList.orange
                                "
                                [class.thread-color-purple]="
                                  comment.color == eColorList.purple
                                "
                                [attr.points]="
                                  $any(element)
                                    | pointsToString
                                      : post
                                      : element.dirty
                                      : element.x
                                      : element.y
                                      : post.imageSize.x
                                      : post.imageSize.y
                                "
                                (mousedown)="holdElement($event, element)"
                                (mouseup)="dropElement()"
                                (click)="selectElement(iC, comment)"
                                [class.selected]="comment.selected"
                                [class.element-selected]="
                                  comment.selected && element.selected
                                "
                              />

                              <rect
                                *ngSwitchCase="eToolMode.RECT_TOOL"
                                [attr.x]="element.x * post.imageSize.x"
                                [attr.y]="element.y * post.imageSize.y"
                                [attr.width]="
                                  $any(element).width * post.imageSize.x
                                "
                                [attr.height]="
                                  $any(element).height * post.imageSize.y
                                "
                                [class.thread-color-blue]="
                                  comment.color == eColorList.blue
                                "
                                [class.thread-color-green]="
                                  comment.color == eColorList.green
                                "
                                [class.thread-color-red]="
                                  comment.color == eColorList.red
                                "
                                [class.thread-color-yellow]="
                                  comment.color == eColorList.yellow
                                "
                                [class.thread-color-orange]="
                                  comment.color == eColorList.orange
                                "
                                [class.thread-color-purple]="
                                  comment.color == eColorList.purple
                                "
                                [class.selected]="comment.selected"
                                [class.element-selected]="
                                  comment.selected && element.selected
                                "
                                (mousedown)="holdElement($event, element)"
                                (mouseup)="dropElement()"
                                (click)="selectElement(iC, comment)"
                              />

                              <polyline
                                *ngSwitchCase="eToolMode.FREEHAND_TOOL"
                                [class.thread-color-lo-blue]="
                                  comment.color == eColorList.blue
                                "
                                [class.thread-color-lo-green]="
                                  comment.color == eColorList.green
                                "
                                [class.thread-color-lo-red]="
                                  comment.color == eColorList.red
                                "
                                [class.thread-color-lo-yellow]="
                                  comment.color == eColorList.yellow
                                "
                                [class.thread-color-lo-orange]="
                                  comment.color == eColorList.orange
                                "
                                [class.thread-color-lo-purple]="
                                  comment.color == eColorList.purple
                                "
                                [attr.points]="
                                  $any(element)
                                    | pointsToString
                                      : post
                                      : element.dirty
                                      : element.x
                                      : element.y
                                      : post.imageSize.x
                                      : post.imageSize.y
                                "
                                (mousedown)="holdElement($event, element)"
                                (mouseup)="dropElement()"
                                (click)="selectElement(iC, comment)"
                                [class.selected]="comment.selected"
                                [class.element-lo-selected]="
                                  comment.selected && element.selected
                                "
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>

                    <g *ngIf="selectedComment != null && selectedPost == post">
                      <!-- Currently Selected Comment -->
                      <g
                        *ngFor="
                          let element of selectedComment.elements;
                          let i = index
                        "
                        [ngSwitch]="element.elType"
                      >
                        <polygon
                          *ngSwitchCase="eToolMode.POLYGON_TOOL"
                          [class.thread-color-blue]="
                            selectedComment.color == eColorList.blue
                          "
                          [class.thread-color-green]="
                            selectedComment.color == eColorList.green
                          "
                          [class.thread-color-red]="
                            selectedComment.color == eColorList.red
                          "
                          [class.thread-color-yellow]="
                            selectedComment.color == eColorList.yellow
                          "
                          [class.thread-color-orange]="
                            selectedComment.color == eColorList.orange
                          "
                          [class.thread-color-purple]="
                            selectedComment.color == eColorList.purple
                          "
                          [attr.points]="
                            $any(element)
                              | pointsToString
                                : post
                                : element.dirty
                                : element.x
                                : element.y
                                : post.imageSize.x
                                : post.imageSize.y
                          "
                          (mousedown)="holdElement($event, element)"
                          (mouseup)="dropElement()"
                          (click)="selectElement(i, selectedComment)"
                          [class.selected]="selectedComment.selected"
                          [class.element-selected]="
                            selectedComment.selected && element.selected
                          "
                        />

                        <rect
                          *ngSwitchCase="eToolMode.RECT_TOOL"
                          [attr.x]="element.x * post.imageSize.x"
                          [attr.y]="element.y * post.imageSize.y"
                          [attr.width]="$any(element).width * post.imageSize.x"
                          [attr.height]="
                            $any(element).height * post.imageSize.y
                          "
                          [class.thread-color-blue]="
                            selectedComment.color == eColorList.blue
                          "
                          [class.thread-color-green]="
                            selectedComment.color == eColorList.green
                          "
                          [class.thread-color-red]="
                            selectedComment.color == eColorList.red
                          "
                          [class.thread-color-yellow]="
                            selectedComment.color == eColorList.yellow
                          "
                          [class.thread-color-orange]="
                            selectedComment.color == eColorList.orange
                          "
                          [class.thread-color-purple]="
                            selectedComment.color == eColorList.purple
                          "
                          [class.selected]="selectedComment.selected"
                          [class.element-selected]="
                            selectedComment.selected && element.selected
                          "
                          (mousedown)="holdElement($event, element)"
                          (mouseup)="dropElement()"
                          (click)="selectElement(i, selectedComment)"
                        />

                        <polyline
                          *ngSwitchCase="eToolMode.FREEHAND_TOOL"
                          [class.thread-color-lo-blue]="
                            selectedComment.color == eColorList.blue
                          "
                          [class.thread-color-lo-green]="
                            selectedComment.color == eColorList.green
                          "
                          [class.thread-color-lo-red]="
                            selectedComment.color == eColorList.red
                          "
                          [class.thread-color-lo-yellow]="
                            selectedComment.color == eColorList.yellow
                          "
                          [class.thread-color-lo-orange]="
                            selectedComment.color == eColorList.orange
                          "
                          [class.thread-color-lo-purple]="
                            selectedComment.color == eColorList.purple
                          "
                          [attr.points]="
                            $any(element)
                              | pointsToString
                                : post
                                : element.dirty
                                : element.x
                                : element.y
                                : post.imageSize.x
                                : post.imageSize.y
                          "
                          (mousedown)="holdElement($event, element)"
                          (mouseup)="dropElement()"
                          (click)="selectElement(i, selectedComment)"
                          [class.selected]="selectedComment.selected"
                          [class.element-lo-selected]="
                            selectedComment.selected && element.selected
                          "
                        />
                      </g>
                    </g>
                  </g>
                  <!-- Editor drawing that hasn't been saved into the main comment array -->

                  <g *ngIf="newComment && selectedPost._id == post._id">
                    <!-- Currently Drawing Comment -->
                    <g
                      *ngFor="let element of newComment.elements; let i = index"
                      [ngSwitch]="element.elType"
                    >
                      <polygon
                        *ngSwitchCase="eToolMode.POLYGON_TOOL"
                        [attr.points]="
                          $any(element)
                            | pointsToString
                              : post
                              : element.dirty
                              : element.x
                              : element.y
                              : post.imageSize.x
                              : post.imageSize.y
                        "
                        (mousedown)="holdElement($event, element)"
                        (mouseup)="dropElement()"
                        (mousemove)="onElementMouseMove(i)"
                        (click)="selectElement(i, null)"
                        [class.selected]="newComment.selected"
                        [class.element-selected]="
                          newComment.selected && element.selected
                        "
                        [class.thread-color-blue]="
                          selectedColor == eColorList.blue
                        "
                        [class.thread-color-green]="
                          selectedColor == eColorList.green
                        "
                        [class.thread-color-red]="
                          selectedColor == eColorList.red
                        "
                        [class.thread-color-yellow]="
                          selectedColor == eColorList.yellow
                        "
                        [class.thread-color-orange]="
                          selectedColor == eColorList.orange
                        "
                        [class.thread-color-purple]="
                          selectedColor == eColorList.purple
                        "
                      />

                      <rect
                        *ngSwitchCase="eToolMode.RECT_TOOL"
                        [attr.x]="element.x * post.imageSize.x"
                        [attr.y]="element.y * post.imageSize.y"
                        [attr.width]="$any(element).width * post.imageSize.x"
                        [attr.height]="$any(element).height * post.imageSize.y"
                        (mousedown)="holdElement($event, element)"
                        (mouseup)="dropElement()"
                        (mousemove)="onElementMouseMove(i)"
                        (click)="selectElement(i, null)"
                        [class.selected]="newComment.selected"
                        [class.element-selected]="
                          newComment.selected && element.selected
                        "
                        [class.thread-color-blue]="
                          selectedColor == eColorList.blue
                        "
                        [class.thread-color-green]="
                          selectedColor == eColorList.green
                        "
                        [class.thread-color-red]="
                          selectedColor == eColorList.red
                        "
                        [class.thread-color-yellow]="
                          selectedColor == eColorList.yellow
                        "
                        [class.thread-color-orange]="
                          selectedColor == eColorList.orange
                        "
                        [class.thread-color-purple]="
                          selectedColor == eColorList.purple
                        "
                      />

                      <polyline
                        *ngSwitchCase="eToolMode.FREEHAND_TOOL"
                        [attr.points]="
                          $any(element)
                            | pointsToString
                              : post
                              : element.dirty
                              : element.x
                              : element.y
                              : post.imageSize.x
                              : post.imageSize.y
                        "
                        (mousedown)="holdElement($event, element)"
                        (mouseup)="dropElement()"
                        (mousemove)="onElementMouseMove(i)"
                        (click)="selectElement(i, null)"
                        [class.selected]="newComment.selected"
                        [class.element-lo-selected]="
                          newComment.selected && element.selected
                        "
                        [class.thread-color-lo-blue]="
                          selectedColor == eColorList.blue
                        "
                        [class.thread-color-lo-green]="
                          selectedColor == eColorList.green
                        "
                        [class.thread-color-lo-red]="
                          selectedColor == eColorList.red
                        "
                        [class.thread-color-lo-yellow]="
                          selectedColor == eColorList.yellow
                        "
                        [class.thread-color-lo-orange]="
                          selectedColor == eColorList.orange
                        "
                        [class.thread-color-lo-purple]="
                          selectedColor == eColorList.purple
                        "
                      />

                      <!--
                          [class.drawing]="mouseDown"
                        [ngStyle]="{fill: newComment.color}"

                      -->
                      <!-- Add new tools here -->
                    </g>
                  </g>

                  <!-- Selection Tool -->
                  <rect
                    [attr.x]="
                      selectionStart.x > selectionEnd.x
                        ? selectionEnd.x
                        : selectionStart.x
                    "
                    [attr.y]="
                      selectionStart.y > selectionEnd.y
                        ? selectionEnd.y
                        : selectionStart.y
                    "
                    [attr.width]="
                      selectionEnd.x > selectionStart.x
                        ? selectionEnd.x - selectionStart.x
                        : selectionStart.x - selectionEnd.x
                    "
                    [attr.height]="
                      selectionEnd.y > selectionStart.y
                        ? selectionEnd.y - selectionStart.y
                        : selectionStart.y - selectionEnd.y
                    "
                    style="
                      fill: rgba(0, 0, 0, 0.5);
                      stroke-width: 2;
                      stroke: rgb(0, 0, 0);
                    "
                    stroke-dasharray="5,5"
                    *ngIf="
                      currentToolMode == eToolMode.SELECTION_TOOL &&
                      selectedPost &&
                      post &&
                      selectedPost._id == post._id &&
                      selectionStart.x != 0 &&
                      selectionStart.y != 0 &&
                      selectionEnd.x != 0 &&
                      selectionEnd.y != 0
                    "
                  />
                </svg>
              </div>
              <img
                alt="Post Image"
                src="https://artdominion.imgix.net/{{
                  post.imageUrl
                }}?w=1400&h=850&fit=fill&fill=solid&fill-color=1c1c1c"
                draggable="false"
                (load)="onPostImageLoad(iP)"
              />
            </div>
            <div
              *ngIf="currentEditorMode == eArtThreadMode.EDITOR_MODE"
              class="comment-input-editor"
            >
              <div class="chat-bar-editor">
                <!--<div class="chat-input" [class.chat-input-selected]="chatbarFocused" (click)="focusChatbar($event,true)" [class.editor-chat-text-error]="editorChatTextError">
                <textarea #chatbar autosize rows="1" [minRows]="1" [maxRows]="5" [(ngModel)]="chatText" placeholder="Chat Message"></textarea>
              </div>-->
                <div class="chat-send-button" (click)="sendDrawing()">
                  <div class="text">SAVE</div>
                </div>
              </div>
            </div>
            <div class="art-thread-chat-container">
              <app-general-chat-component
                [channelId]="post._id"
                chatType="art-thread-chat"
                [post]="post"
                (actionEvent)="onChatComponentActionEvent($event)"
                (messagesEvent)="onMessagesRefreshEvent($event, iP)"
                [selectedComment]="selectedComment"
                [server]="selectedServer"
              ></app-general-chat-component>
            </div>
          </div>
          <div class="post-break"></div>
        </div>
        <!-- End of post -->

        <div class="add-new-post" (click)="createNewPost()">
          <p>Add new post</p>
        </div>
        <div class="bottom-buffer"></div>
      </div>
    </div>
    <div class="comment-container scrollable">
      <div class="post-group" *ngIf="asset">
        <div *ngFor="let post of asset.posts; let iP = index">
          <div
            class="post-accordion"
            [class.post-accordion-selected]="selectedPost._id == post._id"
            (click)="selectPost(iP)"
          >
            {{ post._id }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div *ngIf="selectedPost._id == post._id">
            <div *ngFor="let comment of selectedPost.comments; let iC = index">
              <div
                class="comment-title"
                *ngIf="iC == 0 && !selectedPost.comments[iC].completed"
              >
                Active
              </div>
              <div
                class="comment-title"
                *ngIf="
                  (iC == 0 && selectedPost.comments[0].completed) ||
                  (iC < selectedPost.comments.length &&
                    selectedPost.comments[iC].completed &&
                    !selectedPost.comments[iC - 1].completed)
                "
              >
                Completed
              </div>
              <div
                [class.comment-color-blue]="
                  comment.color == eColorList.blue && !comment.completed
                "
                [class.comment-color-green]="
                  comment.color == eColorList.green && !comment.completed
                "
                [class.comment-color-red]="
                  comment.color == eColorList.red && !comment.completed
                "
                [class.comment-color-yellow]="
                  comment.color == eColorList.yellow && !comment.completed
                "
                [class.comment-color-orange]="
                  comment.color == eColorList.orange && !comment.completed
                "
                [class.comment-color-purple]="
                  comment.color == eColorList.purple && !comment.completed
                "
                [class.comment-color-grey]="comment.completed"
                [class.comment-background-selected]="comment.selected"
                class="comment-background"
                style="background-color: blue"
                (click)="selectComment(comment)"
              >
                <div
                  class="comment-content comment-content-first"
                  [class.comment-content-last]="iC != selectedCommentIndex"
                >
                  <div class="comment-user-avatar">
                    <div class="comment-user-avatar-status"></div>
                  </div>
                  <div
                    class="comment-complete-task-button"
                    placement="bottom"
                    ngbTooltip="Mark As Done"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-check-lg"
                      viewBox="0 0 16 16"
                      (click)="markDone(comment)"
                    >
                      <path
                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
                      />
                    </svg>
                  </div>
                  <div class="comment-more-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-three-dots"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                      />
                    </svg>
                  </div>
                  <div class="comment-reply-count badge badge-danger">
                    {{ comment.replies ? comment.replies.length : "" }}
                  </div>
                  <div class="comment-user">{{ comment.author.username }}</div>
                  <div class="comment-date">{{ comment.createdAt }}</div>
                  <div class="comment-text">{{ comment.message }}</div>
                </div>

                <div *ngIf="iC == selectedCommentIndex">
                  <div
                    *ngFor="let reply of comment.replies"
                    class="comment-content"
                  >
                    <div class="comment-user-avatar">
                      <div class="comment-user-avatar-status"></div>
                    </div>
                    <div class="comment-more-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-three-dots"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        />
                      </svg>
                    </div>
                    <div class="comment-user">{{ reply.author.username }}</div>
                    <div class="comment-date">{{ reply.createdAt }}</div>
                    <div class="comment-text">{{ reply.message }}</div>
                  </div>
                </div>

                <div
                  class="comment-content comment-content-last"
                  *ngIf="iC == selectedCommentIndex"
                >
                  <textarea
                    id="id_{{ comment._id }}"
                    class="comment-reply-input"
                    autosize
                    rows="1"
                    placeholder="Reply"
                    (focus)="focusReplyTextArea(true)"
                    (blur)="focusReplyTextArea(false)"
                  ></textarea>

                  <div class="comment-reply-row">
                    <div
                      class="comment-reply-button"
                      (click)="sendReply(comment)"
                    >
                      REPLY
                    </div>
                    <div
                      class="comment-reply-cancel-button"
                      (click)="clearSelection()"
                    >
                      CANCEL
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-channel-side-panel
    style="top: 90px"
    [selectedServer]="selectedServer"
    [selectedChannel]="selectedChannel"
  ></app-channel-side-panel>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Asset } from 'src/app/models/asset.model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AbstractEndpointService,
  RequestTypes,
} from '../abstract-endpoint.service';
import { AssetsService } from './assets.service';
import { Message } from 'src/app/models/message.model';

@Injectable({
  providedIn: 'root',
})
export class DrawingsService extends AbstractEndpointService {
  protected path = '/drawings';

  async CreateDrawing(
    data: any,
    drawingType: string,
    assetId: string
  ): Promise<any> {
    console.log('Create Drawing ********');
    console.log(data);
    data.assetId = assetId;
    data.drawingType = drawingType;
    const response = await this.AuthenticatedRequest<any>(
      RequestTypes.POST,
      '',
      data
    );
    return response;
  }

  async GetDrawings(assetId: string): Promise<any[]> {
    const response = await this.AuthenticatedRequest<any[]>(
      RequestTypes.GET,
      `/${assetId}`
    );
    return response;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Server } from 'src/app/models/server.model';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from '../../../services/popup-controller.service';
import { debug } from 'src/app/services/logger.service';
import { GlobalLocationResolverService } from '../../../services/global-location-resolver.service';
import { ServersService } from '../../../services/api/servers.service';
import { Channel } from 'src/app/models/channel.model';

@Component({
  selector: 'app-channel-header',
  templateUrl: './channel-header.component.html',
  styleUrls: ['./channel-header.component.scss'],
})
export class ChannelHeaderComponent implements OnInit {
  @Input()
  selectedServer: Server;

  @Input()
  selectedChannel;

  @Output()
  selectedAssetTabState = new EventEmitter<string>();

  @Input()
  selectedAssetTab;

  @Input()
  selectedAssetId;

  userSubscription;
  user;

  constructor(
    private popupControllerService: PopupControllerService,
    private apiService: ApiService,
    private serverService: ServersService,
    private locationResolver: GlobalLocationResolverService
  ) {
    this.userSubscription = this.apiService.user.subscribe((user) => {
      this.user = user;
    });
    this.apiService.GetCurrentUser();

    this.selectedAssetTab = this.locationResolver.getActiveAssetTab();
    this.locationResolver.activeAssetTabSub.subscribe((tab) => {
      this.selectedAssetTab = tab;
    });

    const channelId = this.locationResolver.getActiveChannelId();
    this.selectedChannel = this.serverService.getChannel(channelId);
    this.locationResolver.activeChannelIdSub.subscribe((channelId) => {
      this.selectedChannel = this.serverService.getChannel(channelId);
    });

    const activeServerId = this.locationResolver.getActiveServerId();
    this.selectedServer = this.serverService.getServer(activeServerId);
    this.locationResolver.activeServerIdSub.subscribe((serverId) => {
      this.selectedServer = this.serverService.getServer(serverId);
    });
  }

  ngOnInit(): void {}

  setChannel(channel: Channel | void) {
    this.selectedChannel = channel;
  }

  setServer(server: Server | void) {
    if (server) {
      this.selectedServer = server;
    }
  }

  ngOnChange(): void {}

  /*changeAssetTab() {
    if (this.selectedAssetTab == 'board') {
      debug('changing asset tab to thread');
      this.selectedAssetTabState.emit('thread');
    } else {
      debug('changing asset tab to board');
      this.selectedAssetTabState.emit('board');
    }
  }*/

  createAsset() {
    debug('Creating Asset');
    debug('Server: ' + this.selectedServer._id);
    debug('Channel: ' + this.selectedChannel._id);
    debug('User: ');
    debug(this.user);
    debug('UserId: ' + this.user._id);
    this.popupControllerService.openNewAssetPopup(
      this.selectedServer._id,
      this.selectedChannel._id,
      this.user._id
    );
  }
}

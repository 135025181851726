import ToolMode from "src/app/enums/tool-mode.enum";

export abstract class SvgElement {
  public x: number;
  public y: number;
  public width: number = 0;
  public height: number = 0;
  public dirty: boolean = false;
  public abstract elType: ToolMode;
  public selected?: boolean = false;
  public hovered?: boolean = false;

  // public abstract Draw(): void;
}

export default SvgElement;

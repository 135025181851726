<!-- menus -->
<!--<app-art-board-add-menu></app-art-board-add-menu>-->

<!-- popups -->
<app-user-settings></app-user-settings>
<app-new-server></app-new-server>
<app-add-friend></app-add-friend>
<app-new-category></app-new-category>
<app-new-channel></app-new-channel>
<app-new-asset></app-new-asset>
<app-add-thread-post></app-add-thread-post>
<app-edit-thread-post></app-edit-thread-post>
<app-dialog></app-dialog>
<app-upload-avatar></app-upload-avatar>
<app-modify-server></app-modify-server>
<app-modify-asset></app-modify-asset>
<app-modify-channel></app-modify-channel>
<app-modify-category></app-modify-category>
<app-profile-hover></app-profile-hover>
<app-profile></app-profile>
<app-invite-to-server></app-invite-to-server>
<app-new-project></app-new-project>
<app-modify-project></app-modify-project>
<app-new-dm></app-new-dm>

<!-- main layout -->
<!--<router-outlet name="guilds"></router-outlet>-->
<app-servers></app-servers>
<div class="main-right">
  <router-outlet></router-outlet>
  <app-user-panel></app-user-panel>
</div>

<!-- FIXME remove this saved just in case-->
<!--<router-outlet name="main-right"></router-outlet>-->

<!-- menus -->
<!--<app-art-board-add-menu></app-art-board-add-menu>-->

<!-- popups -->
<!--<app-user-settings></app-user-settings>-->
<!--<app-new-server></app-new-server>-->
<!--<app-add-friend></app-add-friend>-->
<!--<app-new-category></app-new-category>-->
<!--<app-new-channel></app-new-channel>-->
<!--<app-new-asset></app-new-asset>-->
<!--<app-add-thread-post></app-add-thread-post>-->
<!--<app-edit-thread-post></app-edit-thread-post>-->
<!--<app-dialog></app-dialog>-->
<!--<app-upload-avatar></app-upload-avatar>-->

<!--&lt;!&ndash; main layout &ndash;&gt;-->
<!--&lt;!&ndash;<router-outlet name="guilds"></router-outlet>&ndash;&gt;-->
<!--<app-servers [selectedServerIndex]="selectedServerIndex"-->
<!--             (selectedServerState)="updateSelectedServer($event)"></app-servers>-->
<!--<div class="main-right">-->
<!--  <app-user-panel></app-user-panel>-->
<!--  <app-home-sidebar-->
<!--    (freindsListButtonClicked)="showFriendsList()"-->
<!--    (selectedChannelState)="updateSelectedChannel($event)"-->
<!--    *ngIf="selectedServerIndex == 'home'"></app-home-sidebar>-->
<!--  <div *ngIf="selectedServerIndex != 'home' && !selectedServer.isInvitedOnly">-->
<!--    <app-server-sidebar-->
<!--      [selectedAssetTab]="selectedAssetTab"-->
<!--      [selectedChannel]="selectedChannel"-->
<!--      [selectedServerIndex]="selectedServerIndex"-->
<!--      (selectedChannelState)="updateSelectedChannel($event)"-->
<!--      *ngIf="selectedServerIndex != 'home'"-->
<!--      [selectedAssetIndex]="selectedAssetIndex"></app-server-sidebar>-->
<!--  </div>-->
<!--  &lt;!&ndash; Main Screens &ndash;&gt;-->

<!--  <div *ngIf="selectedServerIndex == 'home'">-->
<!--    <app-friend-list ></app-friend-list>-->
<!--    <app-chat-channel *ngIf="selectedChannel && selectedChannel.channelType == 'text'"-->
<!--                      [channelId]="selectedChannel.channelId"-->
<!--                      [selectedServer]="selectedServer"-->
<!--                      [selectedChannel]="selectedChannel"></app-chat-channel>-->
<!--  </div>-->
<!--  <div *ngIf="selectedServerIndex != 'home' && !selectedServer.isInvitedOnly">-->
<!--    &lt;!&ndash; Channel Screens &ndash;&gt;-->
<!--    <app-asset-tab-->
<!--      *ngIf="selectedAssetIndex"-->
<!--      (selectedTabState)="changeAssetTab($event)"-->
<!--    ></app-asset-tab>-->

<!--    <app-art-wall-channel (selectedAssetState)="updateSelectedAsset($event)"-->
<!--                          *ngIf=" selectedChannel &&-->
<!--                                  selectedChannel.channelType == 'art-wall'-->
<!--                                "-->
<!--                          [selectedAssetTab]="selectedAssetTab"-->
<!--                          [selectedServer]="selectedServer"-->
<!--                          [selectedChannel]="selectedChannel"-->
<!--                          (selectedAssetTabState)="changeAssetTab($event)"></app-art-wall-channel>-->
<!--    <app-art-thread-channel *ngIf="selectedAssetIndex  && selectedAssetTab == 'thread'"-->
<!--                            [selectedAssetIndex]="selectedAssetIndex"-->
<!--                            [selectedAssetTab]="selectedAssetTab"-->
<!--                            [selectedServer]="selectedServer"-->
<!--                            [selectedChannel]="selectedChannel"-->
<!--                            (selectedAssetTabState)="changeAssetTab($event)"></app-art-thread-channel>-->
<!--    <app-art-board-channel *ngIf="selectedAssetIndex && selectedAssetTab == 'board'"-->
<!--                           [selectedAssetIndex]="selectedAssetIndex"-->
<!--                           [selectedAssetTab]="selectedAssetTab"-->
<!--                           [selectedServer]="selectedServer" [selectedChannel]="selectedChannel"-->
<!--                           (selectedAssetTabState)="changeAssetTab($event)"></app-art-board-channel>-->
<!--    <app-chat-channel *ngIf="selectedChannel && selectedChannel.channelType == 'text'"-->
<!--                      [channelId]="selectedChannel.channelId"-->
<!--                      [selectedServer]="selectedServer"-->
<!--                      [selectedChannel]="selectedChannel"></app-chat-channel>-->
<!--  </div>-->
<!--  <app-accept-server-invitation-->
<!--    *ngIf="selectedServer && selectedServer.isInvitedOnly"-->
<!--    [selectedServer]="selectedServer"-->
<!--  ></app-accept-server-invitation>-->
<!--</div>-->
<!--&lt;!&ndash;<router-outlet name="main-right"></router-outlet>&ndash;&gt;-->

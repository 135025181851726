<div class="titlebar-container" *ngIf="isAnElectronApp">
  <div class="titlebar">
    <div class="heading" [class.mac-heading]="isMac">
      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 136">
        <!--<path class="cls-1" d="M97.22,7.93V41.64a6.2,6.2,0,0,0,2.46,4.94L137.53,75.2a6.17,6.17,0,0,1-.64,10.29l-41.3,23.92a6.25,6.25,0,0,1-9.39-5.36V6.21A6.24,6.24,0,0,0,76.82.85L3.1,43.53A6.19,6.19,0,0,0,0,48.89V91.55a6.2,6.2,0,0,0,2.46,4.94L50.76,133a6.24,6.24,0,0,0,10-4.93V94.36a6.2,6.2,0,0,0-2.46-4.94L20.47,60.8a6.17,6.17,0,0,1,.64-10.29l41.3-23.92A6.25,6.25,0,0,1,71.8,32v97.84a6.24,6.24,0,0,0,9.38,5.36L154.9,92.47a6.19,6.19,0,0,0,3.1-5.36V44.45a6.2,6.2,0,0,0-2.46-4.94L107.24,3A6.24,6.24,0,0,0,97.22,7.93Z"/>-->
        <path class="cls-1" d="M246.32,90.29A5.33,5.33,0,0,1,241,95.65a5.21,5.21,0,0,1-5-3.47l-4.15-12.12h-21.3l-4.22,12.12a5.25,5.25,0,0,1-5,3.47A5.34,5.34,0,0,1,196,90.29a7,7,0,0,1,.46-2.24L211,48c1.8-4.94,5.41-7.64,10.25-7.64s8.52,2.7,10.29,7.64l14.35,40A7,7,0,0,1,246.32,90.29ZM228.67,70.76l-6.26-18.33c-.27-.66-.65-1-1.19-1s-.85.27-1.11,1l-6.34,18.29Z"/>
        <path class="cls-1" d="M298,58.52c0,8.8-4.84,13.67-10.71,15.33L298,86.39a5.7,5.7,0,0,1,1.46,3.82A5.45,5.45,0,0,1,294,95.65a5.27,5.27,0,0,1-4.38-2.2L278,78.86c-1.23-1.54-2.15-2.47-4.3-2.47h-6.33V90.06a5.42,5.42,0,1,1-10.83,0V47.68c0-3.71,2.35-7,7.11-7h15.73C290.32,40.73,298,47.72,298,58.52Zm-30.63-7.87v15.9H278c6.79,0,9.14-3.2,9.14-7.83,0-4.87-2.73-8.07-8.56-8.07Z"/>
        <path class="cls-1" d="M351.88,46.1a5.5,5.5,0,0,1-5.45,5.32H334.34V90.17A5.32,5.32,0,0,1,329,95.65a5.56,5.56,0,0,1-5.41-5.48V51.42H311.46A5.44,5.44,0,0,1,306,46.1a5.35,5.35,0,0,1,5.49-5.37h35A5.32,5.32,0,0,1,351.88,46.1Z"/>
        <path class="cls-1" d="M433.63,62a81.06,81.06,0,0,1,0,12.27c-1,12.31-11,21-24.06,21H396.48c-5,0-7.72-3.36-7.72-7.61V48.3c0-4.29,2.65-7.57,7.72-7.57h12.36C422.62,40.73,432.6,49.8,433.63,62ZM399.51,51.31V84.69h9.29c8.94,0,13.47-5,14.09-12.81a45.78,45.78,0,0,0,.07-7.3c-.46-8.14-5-13.27-14.16-13.27Z"/>
        <path class="cls-1" d="M444.76,74.12a71.64,71.64,0,0,1,0-12.08A23.59,23.59,0,0,1,468.6,40a23.89,23.89,0,0,1,24,22,81.18,81.18,0,0,1,0,12.08,24,24,0,0,1-47.87,0Zm37.12-1.39a56.28,56.28,0,0,0,0-9.54c-.54-7.52-5.95-12.92-13.28-12.92s-12.52,5.4-13.09,12.92a66,66,0,0,0,0,9.54c.57,7.6,5.79,13,13.09,13S481.34,80.33,481.88,72.73Z"/>
        <path class="cls-1" d="M561.94,49.8l5.45,39c.54,4.05-1.76,6.83-5.45,6.83a5.44,5.44,0,0,1-5.41-5.13l-4.26-37.86c-.07-.35-.27-.46-.5-.46a.49.49,0,0,0-.5.42l-7.21,35c-1,5-4.23,8-8.72,8s-7.71-3-8.71-8l-7.29-35.08a.49.49,0,0,0-.5-.42.47.47,0,0,0-.5.46l-4.22,37.86a5.44,5.44,0,0,1-5.42,5.13c-3.68,0-6-2.78-5.45-6.83l5.38-39c.84-6,4.37-9.41,9.86-9.41,5.18,0,8.68,3.28,9.75,9.22l6.53,33q.12.57.57.57a.61.61,0,0,0,.66-.57l6.44-33c1-5.94,4.5-9.22,9.95-9.22C557.45,40.39,561.18,44.09,561.94,49.8Z"/>
        <path class="cls-1" d="M591.08,45.9V90.06a5.4,5.4,0,0,1-5.37,5.59,5.5,5.5,0,0,1-5.38-5.59V45.9a5.53,5.53,0,0,1,5.38-5.51A5.38,5.38,0,0,1,591.08,45.9Z"/>
        <path class="cls-1" d="M622.21,45.17l20.53,36.59H643c-.73-8.3-.69-19-.69-25.78V45.9a5.43,5.43,0,0,1,5.38-5.51A5.33,5.33,0,0,1,653,45.9V87.09c0,4.24-2.84,8.56-8.21,8.56-4.07,0-6.26-1.89-8.1-5.29l-20.5-36.54-.23.07c.73,8,.73,16.29.73,22.7v13.5a5.28,5.28,0,0,1-5.26,5.56,5.46,5.46,0,0,1-5.37-5.56V49.26c0-4.78,3.26-8.87,8.21-8.87A8.35,8.35,0,0,1,622.21,45.17Z"/>
        <path class="cls-1" d="M678.88,45.9V90.06a5.4,5.4,0,0,1-5.37,5.59,5.5,5.5,0,0,1-5.38-5.59V45.9a5.53,5.53,0,0,1,5.38-5.51A5.38,5.38,0,0,1,678.88,45.9Z"/>
        <path class="cls-1" d="M692,74.12A69.6,69.6,0,0,1,692,62a23.59,23.59,0,0,1,23.83-22,23.88,23.88,0,0,1,24,22,78.57,78.57,0,0,1,0,12.08,24,24,0,0,1-47.86,0Zm37.11-1.39a54.28,54.28,0,0,0,0-9.54c-.53-7.52-5.94-12.92-13.28-12.92s-12.51,5.4-13.09,12.92a66,66,0,0,0,0,9.54c.58,7.6,5.8,13,13.09,13S728.59,80.33,729.12,72.73Z"/>
        <path class="cls-1" d="M769.18,45.17l20.53,36.59h.23c-.73-8.3-.69-19-.69-25.78V45.9a5.43,5.43,0,0,1,5.38-5.51A5.33,5.33,0,0,1,800,45.9V87.09c0,4.24-2.84,8.56-8.21,8.56-4.07,0-6.26-1.89-8.1-5.29l-20.5-36.54-.23.07c.73,8,.73,16.29.73,22.7v13.5a5.28,5.28,0,0,1-5.26,5.56,5.46,5.46,0,0,1-5.37-5.56V49.26c0-4.78,3.26-8.87,8.21-8.87A8.35,8.35,0,0,1,769.18,45.17Z"/>
      </svg>
    </div>

    <!-- buttons -->
    <div class="window-controls" *ngIf="!isMac">

      <div class="button" id="close-button" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
        </svg>
      </div>

      <div class="button" *ngIf="isMaximized ; else showResButton" (click)="restore()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        </svg>
      </div>
      <ng-template #showResButton>
        <div class="button" id="maximize" (click)="maximize()">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          </svg>
        </div>
      </ng-template>

      <div class="button" (click)="minimize()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
        </svg>
      </div>      

    </div>
  </div>
  <div class="app-notifications" *ngIf="false">
    New Update Available
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-friend-list-header',
  templateUrl: './friend-list-header.component.html',
  styleUrls: ['./friend-list-header.component.scss']
})
export class FriendListHeaderComponent implements OnInit {
  @Output()
  friendState = new EventEmitter<string>();

  @Input()
  currentFriendState = 'all';

  constructor(private popupController: PopupControllerService) { }

  ngOnInit(): void {

  }

  addFriend(): void {
    this.popupController.openNewFriendPopup();
  }

  changeState(value: string) {
    this.friendState.emit(value);
  }
}

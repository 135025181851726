
<div class="background">
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div class="popup-box">
          <h1>Coming Soon</h1>
          <p>Art Dominion Is Coming Soon, We Will Let You Know When We Have More To Share</p>
      </div>
    </div>
  </div>
</div>
<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <form [formGroup]="addChannelForm" (ngSubmit)="onSubmit()">
          <h1>Create A New Channel</h1>
          <div class="form-group">
            <h4>CHANNEL TYPE</h4>

            <div class="popup-radio">
              <input
                type="radio"
                name="channelType"
                id="channelTypeText"
                formControlName="channelType"
                value="text"
                checked
              />
              <label for="channelTypeText">
                <div class="radio-display">
                  <div class="radio-display-dot"></div>
                </div>
                <div class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-hash"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"
                    />
                  </svg>
                </div>
                <div class="information">
                  <div class="title">Text Channel</div>
                  <div class="description">Chat with each other</div>
                </div>
              </label>
            </div>

            <div class="popup-radio" *ngIf="isProjectChannel">
              <input
                type="radio"
                name="channelType"
                id="channelTypeArtWall"
                formControlName="channelType"
                value="art-wall"
              />
              <label for="channelTypeArtWall">
                <div class="radio-display">
                  <div class="radio-display-dot"></div>
                </div>
                <div class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-brush-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                    />
                  </svg>
                </div>
                <div class="information">
                  <div class="title">Art Wall</div>
                  <div class="description">Display Art Assets</div>
                </div>
              </label>
            </div>

            <h4>CHANNEL NAME</h4>
            <input
              type="text"
              (keyup)="updateFormValidation()"
              formControlName="name"
            />
            <label class="switcher" for="private">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-lock-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                />
              </svg>
              Private Channel
              <input
                type="checkbox"
                class="switcher"
                name="private"
                formControlName="private"
              />
            </label>
            <div class="private-description">
              A private channel only allows members you add with the roles you
              select to have access.
            </div>
          </div>
          <button
            class="btn btn-block"
            type="submit"
            name="submitButton"
            formControlName="submitButton"
            ngDefaultControl
          >
            <div class="btnText">Create Channel</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { ClipboardService } from 'ngx-clipboard';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { FileService, FileTypes } from 'src/app/services/api/file.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Component({
  selector: 'app-art-board-add-menu',
  templateUrl: './art-board-add-menu.component.html',
  styleUrls: ['./art-board-add-menu.component.scss'],
})
export class ArtBoardAddMenuComponent implements OnInit {
  x = 0;
  y = 0;
  visible = false;
  _subscription: any;
  eContextTypes = ContextTypes;
  type: ContextTypes = ContextTypes.hidden;

  data;

  // image uploading
  imageUploadError: boolean = false;
  imageUploadSelected: File;

  constructor(
    private contextMenu: ContextMenuService,
    private clipboardApi: ClipboardService,
    private apiService: ApiService,
    private popupControllerService: PopupControllerService,
    private assetService: FileService
  ) {}

  ngOnInit(): void {
    debug('running Art board Add Menu Component');

    this._subscription = this.contextMenu.artBoardAddElementDialog.subscribe(
      (value: ContextMenuData) => {
        this.visible = value.isDisplayed;
        if (value.type) {
          this.x = value.x;
          this.y = value.y;
          this.type = value.type;
          this.data = value.data;
        } else {
        }
        debug('displaying menu');
      }
    );
  }

  copyText(text) {
    debug('copying');
    this.clipboardApi.copy(text);
  }

  onFileUpload(event: Event) {
    const image = (event.target as HTMLInputElement).files[0];
    debug(image.name);

    // Get image width and height
    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = (e: any) => {
      const width = e.target.width;
      const height = e.target.height;

      this.assetService
        .getPresignedUrl(
          FileTypes.IMAGES,
          image.name,
          image.type,
          width,
          height
        )
        .then((response) => {
          debug('received response');
          debug(response);
          if (response.url) {
            debug('received presigned url');
            const fileUploadUrl = response.url;
            const imageForm = new FormData();
            imageForm.append('file', image);
            this.assetService
              .uploadToS3(fileUploadUrl, image.type, image)
              .subscribe(async (uploadRes) => {
                debug('upload resolution');
                debug(uploadRes);
                if (uploadRes && uploadRes.type == 4) {
                  await this.assetService.confirmFileUpload(response._id);
                }
              });
          }
        })
        .catch((e) => {
          error('could not get presign url: ' + e);
        });
    };
  }
}

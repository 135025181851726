import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import * as moment from 'moment';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  validated = false;
  submitted = false;
  completed = false;
  emailTaken = false;
  emailNotValid = false;
  lowAge = false;
  tokenServerVerifyFailed = false;
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  public registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    dobDay: new FormControl('', [
      Validators.required,
      Validators.pattern(this.numberRegEx),
    ]),
    dobMonth: new FormControl('', [
      Validators.required,
      Validators.pattern(this.numberRegEx),
    ]),
    dobYear: new FormControl('', [
      Validators.required,
      Validators.pattern(this.numberRegEx),
    ]),
    age: new FormControl('', Validators.required),
    captcha: new FormControl('', Validators.required),
    submitButton: new FormControl(''),
    invitation: new FormControl(''),
  });

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.registerForm.get('submitButton')?.disable();
    this.registerForm.get('dobDay')?.setValue('Day');
    this.registerForm.get('dobMonth')?.setValue('Month');
    this.registerForm.get('dobYear')?.setValue('Year');

    // check route to see if an invitation token or email is present
    // if so, prefill the email field
    if (this.activatedRoute.snapshot.queryParamMap.has('inviteToken')) {
      const emailField = this.registerForm.get('email');
      emailField?.setValue(
        this.activatedRoute.snapshot.queryParamMap.get('email')
      );
      emailField?.disable();

      const invitationField = this.registerForm.get('invitation');
      invitationField?.setValue(
        this.activatedRoute.snapshot.queryParamMap.get('inviteToken')
      );
    }
  }

  async onSubmit() {
    this.registerForm.disable();
    this.submitted = true;
    console.log('registration form:');
    console.log(this.registerForm);
    this.apiService
      .register(this.registerForm)
      .then((data: Object) => {
        if (data.hasOwnProperty('token')) {
          //you are logged in
        }
        debug('then');
        debug(data);
        this.completed = true;
      })
      .catch((e) => {
        this.registerForm.disable();
        //let code = error["error"];
        error(e['error'].message);

        if (e['error'].shortCode == 'RegistrationEmailExists') {
          // email is already token
          error('Email address is already taken');
          this.emailTaken = true;
          setTimeout(() => {
            //delay
            this.registerForm.enable();
            this.emailTaken = false;
          }, 2000);
        }

        if (e['error'].shortCode == 'recapchaTokenFailedException') {
          // email is already token
          error("Recapcha token couldn't be verified");
          this.tokenServerVerifyFailed = true;
          setTimeout(() => {
            //delay
            this.registerForm.enable();
            this.tokenServerVerifyFailed = false;
          }, 2000);
        }

        if (e['error'].shortCode == 'RegistrationEmailNotAllowed') {
          // email is not allowed
          error('The email address is not currently permitted to register');
          this.emailNotValid = true;
          setTimeout(() => {
            //delay
            this.registerForm.enable();
            this.emailNotValid = false;
          }, 2000);
        }

        this.submitted = false;
      });
  }

  async resolved(captchaResponse: string) {
    this.registerForm.get('captcha').setValue(captchaResponse);
    debug(`Resolved captcha with response: ${captchaResponse}`);
    this.updateFormValidation();
  }

  async updateFormValidation() {
    this.emailTaken = false;
    const day = this.registerForm.get('dobDay').value;
    const month = this.registerForm.get('dobMonth').value;
    const year = this.registerForm.get('dobYear').value;

    if (day != 'Day' && month != 'Month' && year != 'Year') {
      const dob8601 = year + '-' + month + '-' + day;
      const age = moment().diff(moment(dob8601), 'years');
      this.registerForm.get('age').setValue(age);

      const minimumRegistrationAge = 13;
      if (age > minimumRegistrationAge) {
        this.lowAge = false;
      } else {
        this.lowAge = true;
      }
    }

    if (
      !this.registerForm.get('email').value.endsWith('@next-gendreams3d.com') &&
      !this.registerForm.get('email').value.endsWith('@nextgendreams.com') &&
      !this.registerForm.get('email').value.endsWith('@artdominion.com')
    ) {
      this.emailNotValid = true;
    } else {
      this.emailNotValid = false;
    }

    if (this.registerForm.valid && !this.lowAge && !this.emailNotValid) {
      this.registerForm.get('submitButton')?.enable();
    } else {
      this.registerForm.get('submitButton')?.disable();
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { stringify } from 'querystring';
import { ApiService } from 'src/app/services/api/api.service';
import {
  ContextMenuService,
  ContextMenuData,
  ContextTypes,
} from 'src/app/services/context-menu.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.scss'],
})
export class FriendListComponent implements OnInit {
  eContextTypes = ContextTypes;
  userSubscription;

  friendState = 'online';

  friends = [];
  requests = [];
  sentRequests = [];
  blocked = [];

  constructor(
    private contextMenuService: ContextMenuService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.userSubscription = this.apiService.user.subscribe(async (user) => {
      let usernames = [];
      this.friends = user.friends;
      this.requests = user.requests;
      this.sentRequests = user.sentRequests;
      this.blocked = user.blockedUsers;

      // make a list of all usernames
      await Promise.all(
        user.friends.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          if (!usernames.includes(fullUsername)) usernames.push(fullUsername);
        })
      );

      await Promise.all(
        user.requests.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          if (!usernames.includes(fullUsername)) usernames.push(fullUsername);
        })
      );

      await Promise.all(
        user.sentRequests.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          if (!usernames.includes(fullUsername)) usernames.push(fullUsername);
        })
      );

      await Promise.all(
        user.blockedUsers.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          if (!usernames.includes(fullUsername)) usernames.push(fullUsername);
        })
      );

      // get all avatars
      const userShorts = await this.apiService.getUsersShorts(usernames);

      // save the shorts to the users
      await Promise.all(
        user.friends.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          const userShortIndex = userShorts.findIndex(
            (value) => value.username == fullUsername
          );
          if (userShortIndex.avatar)
            username.avatarUrl =
              'https://artdominion.imgix.net/' +
              userShorts[userShortIndex].avatar;
        })
      );

      await Promise.all(
        user.requests.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          const userShortIndex = userShorts.findIndex(
            (value) => value.username == fullUsername
          );
          if (userShortIndex.avatar)
            username.avatarUrl =
              'https://artdominion.imgix.net/' +
              userShorts[userShortIndex].avatar;
        })
      );

      await Promise.all(
        user.sentRequests.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          const userShortIndex = userShorts.findIndex(
            (value) => value.username == fullUsername
          );
          if (userShortIndex.avatar)
            username.avatarUrl =
              'https://artdominion.imgix.net/' +
              userShorts[userShortIndex].avatar;
        })
      );

      await Promise.all(
        user.blockedUsers.map((username: any) => {
          const fullUsername = username.username + '#' + username.usercode;
          const userShortIndex = userShorts.findIndex(
            (value) => value.username == fullUsername
          );
          if (userShortIndex.avatar)
            username.avatarUrl =
              'https://artdominion.imgix.net/' +
              userShorts[userShortIndex].avatar;
        })
      );

      debug('friends');
      debug(this.friends);
    });
    this.apiService.GetCurrentUser();
  }

  openContextMenu(event, type: ContextTypes, friendUsername: string) {
    const data = { username: friendUsername };
    this.contextMenuService.show(event.clientX, event.clientY, type, data);
  }

  changeFriendState(value: string) {
    this.friendState = value;
  }

  convertUsername(username: String): { username: string; usercode: string } {
    const usernameParts = username.split('#');
    return {
      username: usernameParts[0],
      usercode: usernameParts[1],
    };
  }
}

import { Component, OnInit, Output } from '@angular/core';
import { Server } from 'src/app/models/server.model';
import { ServersService } from 'src/app/services/api/servers.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { Channel } from 'src/app/models/channel.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  selectedServerIndex = 'home';
  selectedServer: Server;
  servers: Server[] = [];

  selectedChannel;
  selectedAssetTab: string = 'thread'; // or art-thread

  selectedAssetIndex = null;
  // selectedAssetTab: string = 'thread';

  constructor(
    private serversService: ServersService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.serversService.servers.subscribe((servers: Server[]) => {
      this.servers = servers;
      //this.updateSelectedServer(this.selectedServerIndex);
    });
  }

  updateSelectedAsset(value) {
    this.selectedAssetTab = 'thread';
    this.selectedAssetIndex = value;
    debug('selected asset tab changed to: ' + this.selectedAssetTab);
  }

  /*updateSelectedServer(value) {
    debug('=========================================== UDATING SELECTED SERVER =================================')
    debug(value);
    if (value == 'home') this.selectedServer = null;
    this.selectedServerIndex = value;
    this.selectedAssetIndex = null;
    this.selectedChannel = null;
    if (this.servers) {
      this.servers.forEach((server: Server) => {
        if (this.selectedServerIndex == server._id) {
          if (server.categories.length > 0 && server.categories[0].channels.length > 0) {
            this.updateSelectedChannel(server.categories[0].channels[0]);
          }
          this.selectedServer = server;
          return;
        }
      });
    }
  }*/

  updateSelectedChannel(value) {
    // TODO normalise these id values
    if (value?._id) {
      value.channelId = value._id;
    }
    this.selectedChannel = value;
    this.selectedAssetIndex = null;
  }

  changeAssetTab(tab) {
    this.selectedAssetTab = tab;
    if (tab == null) {
      this.selectedAssetIndex = 0;
    }
  }

  showFriendsList() {
    this.selectedChannel = { channelType: 'none' };
  }
}

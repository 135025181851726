<div
  (scroll)="onChatAreaScroll()"
  [style.max-height]="selectedChatConfig.maxHeight"
  class="chat scrollable"
  #chatarea
>
  <div class="chat-area">
    <div
      class="wrapper"
      [style.padding-bottom]="selectedChatConfig.paddingBottom"
      [style.padding-right]="selectedChatConfig.paddingRight || 'none'"
      [style.padding-top]="selectedChatConfig.paddingTop"
    >
      <div class="content">
        <div *ngFor="let message of messages" (click)="selectComment(message)">
          <div
            class="chat-message"
            [class.message-selected]="
              selectedComment &&
              selectedComment.channelId == message.channelId &&
              selectedComment._id == message._id
            "
          >
            <div
              *ngIf="message.authorUsername === authUser.username"
              class="message-actions-container"
            >
              <div
                class="action-button"
                (click)="onDeleteMessageClick(message)"
                data-toggle="tooltip"
                placement="bottom"
                ngbTooltip="Delete"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-trash3-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"
                  />
                </svg>
                <!--<mat-icon class="action-button-icon" (click)="onDeleteMessageClick(message.timetoken)">delete_forever</mat-icon>-->
              </div>
              <div
                class="action-button"
                (click)="onEditMessageClick(message)"
                data-toggle="tooltip"
                placement="bottom"
                ngbTooltip="Edit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-pencil-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                  />
                </svg>
                <!--<mat-icon class="action-button-icon" (click)="onEditMessageClick(message.timetoken)">edit</mat-icon>-->
              </div>
              <div
                *ngIf="chatType === 'art-thread-chat'"
                class="action-button"
                (click)="onDrawingClick(message)"
                data-toggle="tooltip"
                placement="bottom"
                ngbTooltip="Draw"
              >
                <!-- Add/Edit drawing -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </div>
              <div
                class="action-button"
                data-toggle="tooltip"
                placement="bottom"
                ngbTooltip="More"
              >
                <!-- More Menu -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-three-dots"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                  />
                </svg>
              </div>
            </div>

            <div class="right-chat-message-icons">
              <div
                class="drawing-icon"
                *ngIf="message.elements && message.elements.length > 0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="bi bi-pencil-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                  />
                </svg>
              </div>
            </div>

            <div class="profile-pic" *ngIf="message.showUser">
              <img
                *ngIf="message && message.author && message.author.avatar"
                src="https://artdominion.imgix.net/{{ message.author.avatar }}"
              />
              <div
                *ngIf="
                  message &&
                  message.author &&
                  !message.author.avatar &&
                  message.showUser
                "
                class="no-avatar"
              >
                <div
                  *ngIf="
                    message &&
                    message.author &&
                    !message.author.avatar &&
                    message.showUser
                  "
                  class="initials"
                >
                  {{ message.author.username.slice(0, 2) | uppercase }}
                </div>
              </div>
            </div>
            <div
              *ngIf="message.editingState == eMessageEditingTypes.NONE"
              class="text-area"
              [class.sending]="message.syncState != eMessageSyncStates.SYNCED"
            >
              <div class="title" *ngIf="message.showUser">
                <span
                  class="username"
                  (click)="onOpenProfileHoverClick(message, $event)"
                  (contextmenu)="
                    onRightClickUsername(
                      $event,
                      eContextTypes.username,
                      message.author.username
                    )
                  "
                  >{{ message.author.username.split("#")[0] }}
                </span>
                <span class="date">{{
                  fMoment(message.createdAt).format("D-M-Y h:mm a")
                }}</span>
              </div>
              <div class="message">{{ message.message }}</div>
            </div>
            <div
              *ngIf="message.editingState == eMessageEditingTypes.EDITING"
              class="edit-message-dialog chat-input"
            >
              <textarea
                #editingTextArea
                autosize
                rows="1"
                placeholder="New Message ..."
                class="edit-message-input"
                [(ngModel)]="currentEditText"
                (keydown.enter)="submitEditMessage($event, message)"
                (keydown.escape)="cancelEditing()"
                (focus)="focusTextInput(true)"
                (blur)="focusTextInput(false)"
              ></textarea>
              <button class="cancel-button" (click)="cancelEditing()">
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chatbar">
    <div class="chat-input">
      <!--<div class="add">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
             class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
        </svg>
      </div>-->
      <textarea
        id="mainChatTextArea"
        autosize
        rows="1"
        placeholder="Message ..."
        [(ngModel)]="currentMessageVal"
        (keydown.enter)="submitMessage($event)"
        (keydown.escape)="blurInput($event)"
        (focus)="focusTextInput(true)"
        (blur)="focusTextInput(false)"
      ></textarea>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Server } from 'src/app/models/server.model';
import { ActivatedRoute } from '@angular/router';
import { ServersService } from '../../../services/api/servers.service';
import { GlobalLocationResolverService } from 'src/app/services/global-location-resolver.service';

@Component({
  selector: 'app-chat-channel',
  templateUrl: './chat-channel.component.html',
  styleUrls: ['./chat-channel.component.scss'],
})
export class ChatChannelComponent implements OnInit {
  id: string;

  @Input()
  selectedServer: Server;

  @Input()
  selectedChannel;

  @Input() channelId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private servers: ServersService,
    private globalLocationResolver: GlobalLocationResolverService
  ) {
    console.log('chat-channel - constructor');
    this.activatedRoute.params.subscribe((routerParams) => {
      this.channelId = routerParams?.chatId;
    });

    this.globalLocationResolver.activeChannelIdSub.subscribe((channelId) => {
      this.channelId = channelId;
      this.selectedChannel =
        this.servers.getChannel(channelId) || this.selectedChannel;
    });
  }

  ngOnInit(): void {
    const chatId =
      this.activatedRoute.snapshot.paramMap.get('chatId') || this.channelId;
    this.selectedChannel =
      this.servers.getChannel(chatId) || this.selectedChannel;
    this.channelId = chatId;
  }
}

<div class="background">
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">

      <div class="popup-box" *ngIf="!submitted && !changePasswordForm && !verifyFailed && !completed">
        <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
          <h1 class="h1">Forgot Your Password?</h1>
          <p class="descriptionText">Enter Your Email And We Will Send You A Link To Reset Your Password</p>
          <div class="form-group">
           <label for="email">EMAIL
                <input type="text" name="email" formControlName="email" (change)="updateFormValidation()" />
                <div class="errors">
                  <span class="error-message" *ngIf="passwordResetForm.get('email')?.hasError('required') && (passwordResetForm.get('email')?.dirty || passwordResetForm.get('email')?.touched)">Email is Required</span> 
                  <span class="error-message" *ngIf="passwordResetForm.get('email')?.hasError('email') && (passwordResetForm.get('email')?.dirty || passwordResetForm.get('email')?.touched)">Email is mailformed</span> 
                </div>
              </label>
          </div>
          <div class="capcha-container">
              <re-captcha (resolved)="resolved($event)" siteKey="6Lei9QYcAAAAAPuirG5kkXqA5fHwyTLlEzqEiltx"></re-captcha>
              <span class="error-message" *ngIf="tokenServerVerifyFailed">There Was An Issue With Your reCaptcha</span>
            </div>
          <button class="btn btn-block" type="submit" name="submitButton" formControlName="submitButton" ngDefaultControl>
            <div class="btnText">Reset Password</div>
          </button>
          <span class="subtle-form-link">Want to try logging in again? <a routerLink="/login">Login Here</a></span>
        </form>
      </div>

      <div class="popup-box" *ngIf="!submitted && changePasswordForm && !verifyFailed && !completed">
        <form [formGroup]="passwordChangeForm" (ngSubmit)="onSubmitChange()">
          <h1 class="h1">Change Your Password</h1>
          <p class="descriptionText">Please Enter Your New Password</p>
          
          <div class="form-group">
           <label for="email">PASSWORD
                <input type="password" name="password" formControlName="password" (keyup)="updatePasswordChangeFormValidation()" />
                <div class="errors">
                  <span class="error-message" *ngIf="passwordChangeForm.get('password')?.hasError('minlength') && (passwordChangeForm.get('password')?.dirty || passwordChangeForm.get('password')?.touched)">Password needs to be at least 8 characters</span>
                </div>
              </label>
          </div>

          <div class="form-group">
           <label for="email">CONFIRM PASSWORD
                <input type="password" name="passwordConf" formControlName="passwordConf" (keyup)="updatePasswordChangeFormValidation()" />
                <div class="errors">
            <span class="error-message" *ngIf="passwordMismatch">Passwords Must Match</span> 
          </div>
              </label>
          </div>

          <button class="btn btn-block" type="submit" name="submitButton" formControlName="submitButton" ngDefaultControl>
            <div class="btnText">Save</div>
          </button>
        </form>
      </div>

      <div class="popup-box" *ngIf="submitted">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
        </svg>
        <span class="checkImgText">Check Your Email, We Sent You A Link To Reset Your Password</span>
      </div>

      <div class="popup-box" *ngIf="completed">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
          <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
        </svg>
        <h5>Your Password Has Been Successfully Changed</h5>
      </div>

      <div class="popup-box" *ngIf="verifyFailed">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
        </svg>
        <h5>Your Password Could Not Be Changed</h5>
        <p>Send Us An Email<br>
           Be sure to include your email address and describe the process you followed to get this error<br>
           We will look into your issue as soon as we have someone available</p>
        <button class="btn btn-block">
          <a class="btnText" href="mailto: support@artdominion.com">Send Us A Support Email</a>
        </button>
      </div>


    </div>
  </div>
</div>
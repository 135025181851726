<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <form [formGroup]="addServerForm" (ngSubmit)="onSubmit()">
          <h1>Create A New Server</h1>

          <div class="form-group">
            <div class="thumbnail center">
              <label for="thumbnail">
                <div class="thumbnail-overlay-main"></div>
                <img
                  *ngIf="selectedImage"
                  [src]="selectedImageSrc"
                  width="200"
                  height="200"
                />
                <div class="thumbnail-overlay">
                  <span>UPLOAD<br />IMAGE</span>
                </div>
                <div class="thumbnail-overlay-main">
                  <span *ngIf="!selectedImageSrc">THUMBNAIL</span>
                </div>
              </label>
            </div>
            <input
              (change)="onFileUpload($event)"
              type="file"
              accept=".png,.jpg,.gif"
              name="thumbnail"
              id="thumbnail"
              style="display: none"
            />

            <label for="email"
              >Server Name
              <input
                type="text"
                name="name"
                (keyup)="updateFormValidation()"
                formControlName="name"
              />
            </label>
            <label class="switcher" for="private">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-lock-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                />
              </svg>
              Private Server
              <input
                id="private"
                type="checkbox"
                class="switcher"
                name="private"
                formControlName="private"
              />
            </label>
            <div class="private-description">
              A private server requires members to be invited. Anybody can join
              a public server.
            </div>
          </div>
          <button
            class="btn btn-block"
            type="submit"
            name="submitButton"
            formControlName="submitButton"
            ngDefaultControl
          >
            <div class="btnText">Create Server</div>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalLocationResolverService } from '../services/global-location-resolver.service';
import { ServersService } from '../services/api/servers.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';

type AssetTabOptions = 'thread' | 'board' | 'files';
const tabItems = new Set<AssetTabOptions>(['thread', 'board', 'files']);

@Component({
  selector: 'app-asset-main',
  templateUrl: './asset-main.component.html',
  styleUrls: ['./asset-main.component.scss'],
})
export class AssetMainComponent implements OnInit {
  selectedTab = null;
  assetId;

  selectedServer;
  selectedChannel;

  @Output()
  selectedTabState = new EventEmitter<string>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private serverService: ServersService,
    private globalLocationResolver: GlobalLocationResolverService,
    private assetService: AssetsService
  ) {
    this.activatedRoute.url.subscribe(async (urlSegment) => {
      const matchedPath = urlSegment
        .map(({ path }) => path)
        .filter((pathItem) => tabItems.has(pathItem as AssetTabOptions))?.[0];

      if (matchedPath) {
        this.selectedTab = matchedPath;
      }

      this.selectedTabState.emit(this.selectedTab);
      this.globalLocationResolver.setActiveAssetTab(
        'asset-main - constructor',
        this.selectedTab
      );

      // console.log('asset-main - constructor - activatedRoute: ');
      // console.log(this.activatedRoute.snapshot.parent);

      // get assetId
      this.assetId = this.activatedRoute.snapshot.paramMap.get('assetId');
      this.globalLocationResolver.setActiveAssetId(
        'asset-main - constructor',
        this.assetId
      );
      // console.log('assetId: ' + this.assetId);

      // get channel
      const channelId =
        this.activatedRoute.snapshot.parent.paramMap.get('wallId');
      this.selectedChannel = channelId; //this.serverService.getChannel(channelId);
      this.globalLocationResolver.setActiveChannelId(
        'asset-main - constructor',
        channelId
      );
      // console.log('channelId: ' + channelId);

      await this.assetService.GetAllChannelAssets(channelId);

      console.log('asset-main - constructor - activatedRoute: SEEING VALUES:');
      console.log('this.selectedTab: ', this.selectedTab);
      console.log('this.assetId: ', this.assetId);
      console.log('this.selectedChannel: ', this.selectedChannel);
    });
  }

  @Output()
  public assetEventsListener;

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.globalLocationResolver.setActiveAssetTab(
      'asset-main - onDestroy',
      null
    );
  }

  setSelectedTab(selectedValue): void {
    let navPath: string[] = [];
    // let relativeTo: ActivatedRoute = this.activatedRoute

    if (selectedValue !== null) {
      navPath = ['asset', this.assetId, selectedValue];
    } else {
      navPath = ['./'];
    }

    const relativeTo = this.activatedRoute.parent;

    this.router.navigate(navPath, { relativeTo }).catch((e) => {
      // TODO what is the error state
      console.error(e);
    });
  }
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {debug, warn, error, info} from 'src/app/services/logger.service';

@Directive({
selector: '[auto-grow]'
})
export class AutoGrowDirective {

  constructor(public element: ElementRef) { }
  @Input() maximumHeight: number; // based on pixel
  @Input() minHeight: number; // based on pixel

  @HostListener('input', ['$event.target'])
  @HostListener('cut', ['$event.target'])
  @HostListener('paste', ['$event.target'])
  @HostListener('change', ['$event.target'])
  adjustCustom(): void {
    debug('adusting textarea');
    const element = this.element.nativeElement;
    debug('scroll height: ' + element.scrollHeight);
    element.style.height = '';
    
    element.style.height = (element.scrollHeight) + 'px';
    if (element.scrollHeight <= this.maximumHeight) {

        element.style.overflowY = 'hidden'
        delete element.style.maxHeight
    } else {

        element.style.overflowY = 'scroll'
        element.style.maxHeight = this.maximumHeight + 'px';
    }

  }  
  
  ngOnInit(): void {
    debug('directive running');
    this.adjustCustom();
  }

}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ContextTypes {
  hidden,
  sentFriendRequest,
  friendRequest,
  blockedUsers,
  friendList,
  friendChat,
  server,
  AddServer,
  channelList,
  channel_category,
  channel_project,
  project,
  category,
  artBoardAddElementMenu,
  postKababMenu,
  username,
  artwall_asset,
}
export interface ContextMenuData {
  isDisplayed: boolean;
  x: number;
  y: number;
  type: ContextTypes;
  data: object;
}

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  isDisplayed: boolean;
  x: number;
  y: number;
  type: ContextTypes = ContextTypes.hidden;
  data: object;
  dialog: Subject<ContextMenuData> = new Subject<ContextMenuData>();
  artBoardAddElementDialog: Subject<ContextMenuData> =
    new Subject<ContextMenuData>();
  isJustSpawned: boolean = false;

  constructor() {}

  private showInit(
    x: number,
    y: number,
    type: ContextTypes,
    data: object
  ): ContextMenuData {
    this.hide();
    this.isDisplayed = true;
    this.x = x;
    this.y = y;
    this.type = type;
    this.data = data;

    return {
      isDisplayed: this.isDisplayed,
      x: this.x,
      y: this.y,
      type: this.type,
      data: this.data,
    };
  }

  private hideInit(): ContextMenuData {
    this.isDisplayed = false;
    this.x = 0;
    this.y = 0;
    this.type = ContextTypes.hidden;
    this.data = null;
    return {
      isDisplayed: null,
      x: null,
      y: null,
      type: null,
      data: null,
    };
  }

  /*************** CONTEXT MENUS ******************/

  /** Open Standard Context Menu */
  public show(x: number, y: number, type: ContextTypes, data: object) {
    this.isJustSpawned = true;
    setInterval(() => {
      this.isJustSpawned = false;
    }, 100);
    this.dialog.next(this.showInit(x, y, type, data));
  }

  public hide() {
    if (!this.isJustSpawned) {
      this.dialog.next(this.hideInit());
    }
  }

  // Art board Add Element popup menu
  public showArtboardAddElementDialog(x: number, y: number, data: object) {
    this.artBoardAddElementDialog.next(
      this.showInit(x, y, ContextTypes.artBoardAddElementMenu, data)
    );
  }
  public hideArtboardAddElementDialog() {
    this.artBoardAddElementDialog.next(this.hideInit());
  }
}

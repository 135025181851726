import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { AssetsService } from 'src/app/services/api/assets/assets.service';
import { Server } from 'src/app/models/server.model';
import { Channel } from 'src/app/models/channel.model';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-modify-channel',
  templateUrl: './modify-channel.component.html',
  styleUrls: ['./modify-channel.component.scss'],
})
export class ModifyChannelComponent implements OnInit {
  visible = false;
  channel: Channel;
  serverId: string;
  categoryId: string;

  public modifyChannelForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serverService: ServersService
  ) {}

  ngOnInit(): void {
    this.modifyChannelForm.get('submitButton')?.disable();
    this.popupController.modifyChannel.subscribe((data) => {
      debug('updating modify-server visibility');
      console.log(data);
      this.visible = data == null ? false : true;
      this.channel = data.channel;
      this.serverId = data.serverId;
      this.categoryId = data.categoryId;

      this.modifyChannelForm.get('name')?.setValue(this.channel.name);
    });
  }

  onSubmit() {
    debug('Sending request to patch');
    this.serverService
      .patchChannel(
        this.modifyChannelForm,
        this.serverId,
        this.categoryId,
        this.channel._id
      )
      .then((response) => {
        debug(response);
        this.serverService.getUsersOwnedServers();
        this.killWindow();
      });
  }

  async updateFormValidation() {
    if (this.modifyChannelForm.valid) {
      this.modifyChannelForm.get('submitButton')?.enable();
    } else {
      this.modifyChannelForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.modifyChannelForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

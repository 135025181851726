import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  FormGroup,
  AbstractControl,
  Validators,
  FormControl,
  FormArray,
  FormBuilder,
} from '@angular/forms';

export type EmailGroup = {
  email: string;
  invalid: boolean;
};

@Component({
  selector: 'app-multi-email-text-area',
  templateUrl: './multi-email-text-area.component.html',
  styleUrls: ['./multi-email-text-area.component.scss'],
})
export class MultiEmailTextAreaComponent implements OnInit {
  @Input() placeholder: string = 'Enter emails';
  @Output() emails = new EventEmitter<EmailGroup[]>();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  _emails: EmailGroup[] = [];
  form: FormGroup;
  fb: FormBuilder = new FormBuilder();

  constructor() {}

  ngOnInit(): void {
    this.form = this.fb.group({
      emails: this.fb.array(
        [],
        [this.validateArrayNotEmpty, Validators.required]
      ),
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      if (this.validateEmail(value)) {
        this._emails.push({ email: value, invalid: false });
      } else {
        this._emails.push({ email: value, invalid: true });
        this.form.controls['emails'].setErrors({ 'incorrect Email': true });
      }
      this.emails.emit(this._emails);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  reset() {
    this._emails = [];
    this.emails.emit(this._emails);
  }

  remove(email: EmailGroup): void {
    const index = this._emails.indexOf(email);

    if (index >= 0) {
      this._emails.splice(index, 1);
    }
    this.emails.emit(this._emails);
  }

  private validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private validateArrayNotEmpty(c: AbstractControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  checkInputForEmails(pasteContent) {
    // check to see if the input now contains spaces or commas
    // if it does, then we need to add the emails to the list
    // and clear the input
    console.log('running checkInputForEmails');
    console.log(pasteContent);
    if (pasteContent.includes(' ') || pasteContent.includes(',')) {
      const emails = pasteContent.split(/[\s,]+/);
      console.log(emails);
      emails.forEach((email) => {
        if (this.validateEmail(email)) {
          this._emails.push({ email: email, invalid: false });
        } else {
          this._emails.push({ email: email, invalid: true });
          this.form.controls['emails'].setErrors({ 'incorrect Email': true });
        }
      });
      this.emails.emit(this._emails);

      //clear input box
      this.form.controls['emails'].setValue('');
    }
  }
}

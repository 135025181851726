import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { FileService, FileTypes } from 'src/app/services/api/file.service';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api/api.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss'],
})
export class UploadAvatarComponent implements OnInit {
  visible = false;
  user: User;
  selectedImage: string;
  newImageUploaded: boolean;

  public uploadAvatarForm = new FormGroup({
    avatar: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private assetsService: FileService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.popupController.avatar.subscribe((data: any) => {
      debug('Reloading upload avatar Popup');
      if (data == true) {
        this.visible = true;
      } else {
        this.visible = false;
      }
      this.apiService.user.subscribe((user) => {
        this.user = user;

        if (!this.newImageUploaded) {
          this.selectedImage = this.user.avatarUrl;
        }

        debug('updating user in upload avatar');
      });
      this.apiService.GetCurrentUser();
    });
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.uploadAvatarForm.reset();
    debug('testing upload avatar screen');
  }

  onFileUpload(event) {
    debug(event);
    debug('onFileUpload');
    const image = (event.target as HTMLInputElement).files[0];
    debug('onFileUpload 2');

    const reader = new FileReader();
    debug('onFileUpload 3');
    if (event.target.files && event.target.files.length) {
      debug('onFileUpload 4');
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      debug('onFileUpload 5');
      reader.onload = () => {
        this.selectedImage = reader.result as string;
        debug('onFileUpload 6');
      };
    }

    const img = new Image();
    debug('onFileUpload 8');
    img.src = URL.createObjectURL(image);
    debug('onFileUpload 9');

    img.onload = (e: any) => {
      debug('onFileUpload 10');
      const width = e.target.width;
      const height = e.target.height;

      debug('image.name: ' + image.name);
      debug('image.type: ' + image.type);
      debug('width: ' + width);
      debug('height: ' + height);

      // upload selected image
      this.assetsService
        .getPresignedUrl(
          FileTypes.AVATAR,
          image.name,
          image.type,
          width,
          height
        )
        .then((response) => {
          debug('onFileUpload 11');
          debug('recieved response');
          this.uploadAvatarForm.get('avatar')?.setValue(response.filename);

          debug(response);
          if (response.url) {
            debug('onFileUpload 12');
            debug('recieved presigned url');
            const fileUploadUrl = response.url;
            const imageForm = new FormData();
            imageForm.append('file', image);
            this.assetsService
              .uploadToS3(fileUploadUrl, image.type, image)
              .subscribe(async (uploadResponse) => {
                debug('onFileUpload 13');
                debug(uploadResponse);
                if (uploadResponse && uploadResponse.type == 4) {
                  debug('onFileUpload 14');
                  this.assetsService
                    .confirmFileUpload(response._id)
                    .then((confirmResponse) => {
                      debug('onFileUpload 15');
                      debug('avatar upload confirmed');
                      debug(confirmResponse);
                      this.newImageUploaded = true;
                      this.apiService.GetCurrentUser(true);
                    });
                }
              });
          }
        });
    };
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

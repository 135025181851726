<div class="sub-main">
  <app-channel-header>
  </app-channel-header>

  <div class="chat-channel-area"
       *ngIf="channelId"
  >
    <app-general-chat-component
      [channelId]="channelId"
      chatType="full-screen-chat"
      [server]="selectedServer"
    >
    </app-general-chat-component>
  </div>
  <app-channel-side-panel [selectedServer]="selectedServer"
                          [selectedChannel]="selectedChannel"></app-channel-side-panel>
</div>

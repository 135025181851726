<div class="sidebar" *ngIf="selectedServer">
  <button class="heading-dropdown">
    <span>{{ selectedServer.name }}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-chevron-down"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  </button>
  <hr />
  <div class="scroll">
    <div *ngIf="selectedServer.thumbnail" class="thumbnail">
      <img
        [src]="'https://artdominion.imgix.net/' + selectedServer.thumbnail"
      />
    </div>

    <div class="category">
      <div class="sidebar-heading">
        Projects<span
          class="add"
          data-toggle="tooltip"
          placement="right"
          ngbTooltip="New Project"
          (click)="AddProject()"
          >+</span
        >
      </div>

      <ul class="list-group">
        <mat-accordion #accordion="matAccordion">
          <mat-expansion-panel
            hideToggle
            *ngFor="let project of selectedServer.projects"
            [class.mat-expansion-selected]="project._id == selectedProjectId"
            (click)="setActiveProject(project._id)"
            [expanded]="project._id == selectedProjectId"
          >
            <mat-expansion-panel-header
              (contextmenu)="
                onrightClick(
                  $event,
                  eContextTypes.project,
                  selectedServer._id,
                  eChannelParentIdType.PROJECT,
                  project,
                  user._id
                )
              "
            >
              <mat-panel-title>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-folder"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
                  />
                </svg>
                {{ project.name }}
                <span
                  class="add"
                  data-toggle="tooltip"
                  placement="left"
                  ngbTooltip="Add Channel"
                  (click)="
                    AddChannel(project._id, eChannelParentIdType.PROJECT)
                  "
                  >+</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-selection-list>
                <!--<mat-list-item>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-kanban"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.5 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h11zm-11-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-11z"
                    />
                    <path
                      d="M6.5 3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3zm-4 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3zm8 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3z"
                    />
                  </svg>
                  Kanban</mat-list-item>-->
                <div
                  *ngFor="let channel of project.channels"
                  (contextmenu)="
                    onrightClick(
                      $event,
                      eContextTypes.channel_project,
                      selectedServer._id,
                      eChannelParentIdType.PROJECT,
                      project._id,
                      user._id,
                      channel
                    )
                  "
                >
                  <mat-list-item
                    *ngIf="channel.channelType == 'art-wall'"
                    (click)="selectChannel(channel)"
                    [class.selected]="selectedChannel?._id == channel._id"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-box"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                      />
                    </svg>
                    {{ channel.name }}</mat-list-item
                  >
                  <mat-list-item
                    *ngIf="channel.channelType == 'text'"
                    (click)="selectChannel(channel)"
                    [class.selected]="selectedChannel?._id == channel._id"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chat"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"
                      />
                    </svg>
                    {{ channel.name }}</mat-list-item
                  >

                  <!--class="mat-selected"-->
                  <!--<mat-list-item>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-easel"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0a.5.5 0 0 1 .473.337L9.046 2H14a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1.85l1.323 3.837a.5.5 0 1 1-.946.326L11.092 11H8.5v3a.5.5 0 0 1-1 0v-3H4.908l-1.435 4.163a.5.5 0 1 1-.946-.326L3.85 11H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4.954L7.527.337A.5.5 0 0 1 8 0zM2 3v7h12V3H2z"
                    />
                  </svg>
                  Board</mat-list-item>-->
                  <!--<mat-list-item>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-files"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"
                    />
                  </svg>
                  Files</mat-list-item>-->
                </div>
              </mat-selection-list>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>

        <!--
        <li *ngFor="let number of [1, 2, 3]" [class.selected]="number == 2">
          <!-- TESTING --><!--
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-folder"
            viewBox="0 0 16 16"
          >
            <path
              d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
            />
          </svg>
          <span>Asset {{ number }}</span>
        </li>-->
      </ul>
    </div>

    <div class="category" *ngFor="let category of selectedServer.categories">
      <div
        class="sidebar-heading"
        (contextmenu)="
          onrightClick(
            $event,
            eContextTypes.category,
            selectedServer._id,
            eChannelParentIdType.CATEGORY,
            category,
            user._id
          )
        "
      >
        {{ category.name
        }}<span
          class="add"
          data-toggle="tooltip"
          placement="right"
          ngbTooltip="Create Channel"
          (click)="AddChannel(category._id)"
          >+</span
        >
      </div>

      <ul class="list-group" *ngFor="let channel of category.channels">
        <li
          (click)="
            accordion.closeAll(); setActiveProject(null); selectChannel(channel)
          "
          (contextmenu)="
            onrightClick(
              $event,
              eContextTypes.channel_category,
              selectedServer._id,
              eChannelParentIdType.CATEGORY,
              category._id,
              user._id,
              channel
            )
          "
          [class.selected]="selectedChannel?._id == channel._id"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-hash"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"
            />
          </svg>
          <span>{{ channel.name }}</span>
        </li>
      </ul>
    </div>

    <!--

      <ul class="list-group" *ngFor="let channel of category.channels">
        <li
          class="channel"
          [class.opened-asset-thread]="
            channel._id == selectedChannel._id && selectedAssetTab == 'thread'
          "
          [class.opened-asset-board]="
            channel._id == selectedChannel._id && selectedAssetTab == 'board'
          "
          [class.opened-asset-board]="
            channel._id == selectedChannel._id && selectedAssetTab == 'files'
          "
          [class.selected]="channel._id == selectedChannel._id"
          *ngIf="channel.channelType == 'art-wall'"
          (contextmenu)="
            onrightClick(
              $event,
              eContextTypes.channel,
              selectedServer._id,
              category,
              user._id,
              channel
            )
          "
          (click)="selectChannel(channel)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-brush-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
            />
          </svg>
          <span>{{ channel.name }}</span>
          <div *ngIf="channel._id == selectedChannel._id">
            <span
              *ngIf="selectedAssetTab == 'thread'"
              class="art-mode art-mode-thread"
              >THREAD</span
            >
            <span
              *ngIf="selectedAssetTab == 'board'"
              class="art-mode art-mode-board"
              >BOARD</span
            >
            <span
              *ngIf="selectedAssetTab == 'files'"
              class="art-mode art-mode-files"
              >FILES</span
            >
          </div>
        </li>

        <li
          [class.selected]="channel._id == selectedChannel._id"
          *ngIf="channel.channelType == 'text'"
          (contextmenu)="
            onrightClick(
              $event,
              eContextTypes.channel,
              selectedServer._id,
              category._id,
              user._id,
              channel
            )
          "
          (click)="selectChannel(channel)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-hash"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"
            />
          </svg>
          <span>{{ channel.name }}</span>
        </li>
      </ul>
    </div>

    -->

    <div
      class="remainder"
      (contextmenu)="
        onrightClickBlank(
          $event,
          eContextTypes.channelList,
          selectedServer._id,
          user._id
        )
      "
    ></div>
  </div>
</div>

<div class="invitation-container">
  <div class="centered-dialog">
    <h2>You have been invited to join {{selectedServer.name}}, do you want to join?</h2>
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-3"><button class="btn btn-block" (click)="acceptInvitation()">Accept Invitation</button></div>
      <div class="col-sm-3"><button class="btn btn-block btn-cancel" (click)="deleteInvitation()">Delete Invitation</button></div>
    </div>
  </div>
</div>

<div
  class="fullscreen"
  id="fullscreen"
  [class.fadein]="visible"
  [class.fadeout]="!visible"
  (click)="closeWindow($event)"
>
  <div class="popup-wrapper-whole">
    <div class="popup-wrapper-width">
      <div
        class="popup-box"
        [class.scalein]="visible"
        [class.scaleout]="!visible"
      >
        <div class="invite-sent" *ngIf="invitesSent">
          <div class="invite-sent-message">Invitations Sent</div>
        </div>
        <h1>Create Private Room</h1>
        <!--<h3>Email Addresses</h3>-->
        <form [formGroup]="form">
          <div>
            Add Friends:
            <span style="float: right"
              >{{ friends.selectedOptions.selected.length }} friends
              selected</span
            >
          </div>
          <mat-selection-list #friends [formControl]="selectedFriends">
            <mat-list-option
              *ngFor="let friend of userFriends"
              [value]="friend"
            >
              {{ friend.username.split("#")[0] }}
            </mat-list-option>
          </mat-selection-list>
          <div class="form-group">
            <button
              class="btn btn-block invite-btn"
              type="submit"
              name="submitButton"
              ngDefaultControl
              formControlName="submitButton"
              (click)="createRoom()"
            >
              <div class="btnText">
                {{ channel ? "Add To Room" : "Create Room" }}
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

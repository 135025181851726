import { Component, OnInit, HostListener } from '@angular/core';
import { PopupControllerService } from 'src/app/services/popup-controller.service';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ServersService } from 'src/app/services/api/servers.service';
import { debug, warn, error, info } from 'src/app/services/logger.service';
import { ProjectsService } from 'src/app/services/api/projects/projects.service';
import { GlobalLocationResolverService } from 'src/app/services/global-location-resolver.service';

export enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent implements OnInit {
  visible = false;
  serverId = null;
  public addProjectForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    serverId: new FormControl(''),
    submitButton: new FormControl(''),
  });
  constructor(
    private popupController: PopupControllerService,
    private serversService: ServersService,
    private projectsService: ProjectsService,
    private globalLocationResolver: GlobalLocationResolverService
  ) {}

  ngOnInit(): void {
    this.addProjectForm.get('submitButton')?.disable();
    this.popupController.newProject.subscribe((data: any) => {
      debug('Reloading New Project Popup: payload:');
      console.log(data);
      if (data.serverId == null) {
        this.visible = false;
      } else {
        this.visible = true;
      }
      this.serverId = data.serverId;
      this.addProjectForm.get('serverId')?.setValue(data.serverId);
      this.addProjectForm.get('owner')?.setValue(data.owner);
      debug('new project popup - ServerId: ' + data.serverId);
    });
  }

  onSubmit() {
    debug('Sending Create Project Request. Data:');
    this.projectsService
      .CreateProject(this.addProjectForm)
      .then((response) => {
        debug('Create Server Response:');
        debug(response);
        this.projectsService
          .GetProjects(this.serverId)
          .then((projects) => {
            this.globalLocationResolver.setActiveProjectId(
              'new-project: onSubmit',
              response.project._id
            );
            this.globalLocationResolver.setActiveCategoryId(
              'new-project: onSubmit',
              null
            );

            this.globalLocationResolver.setActiveChannelId(
              'new-project: onSubmit',
              response.generalChat._id
            );
            this.globalLocationResolver.setActiveChannelType(
              'new-project: onSubmit',
              'text'
            );

            this.killWindow();
          })
          .catch((eGetProjects) => {
            error(eGetProjects);
            this.killWindow();
          });
      })
      .catch((eCreateProject) => {
        error(eCreateProject);
        this.killWindow();
      });
  }

  async updateFormValidation() {
    if (this.addProjectForm.valid) {
      this.addProjectForm.get('submitButton')?.enable();
    } else {
      this.addProjectForm.get('submitButton')?.disable();
    }
  }

  closeWindow(event = null) {
    if (event) {
      //check what was clicked
      if (event.target.parentNode.parentNode.id) {
        this.killWindow();
      }
    } else {
      this.killWindow();
    }
  }

  killWindow() {
    this.visible = false;
    this.addProjectForm.reset();
    this.updateFormValidation();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.killWindow();
    }
  }
}

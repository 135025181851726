import * as moment from 'moment';
import SvgElement from 'src/app/classes/svg/svg-element.class';
import { COLOR_LIST } from 'src/app/enums/color-list.enum';

export enum MessageEditingTypes {
  NONE,
  EDITING,
}

export enum MessageSyncStates {
  CREATED, // message has been created locally
  MODIFIED, // message has been modified locally
  SYNCED, // message has been synced with server
}

export type Message = {
  _id?: string;
  authorUsername?: string;
  author: {
    id: string;
    username: string;
    avatar?: string;
  };
  message?: string;
  replies?: Message[];
  isEditing?: boolean;
  elements?: SvgElement[];
  isElementsHidden?: boolean;
  color?: COLOR_LIST;
  completed?: boolean;
  selected?: boolean;
  channelId?: string;
  updatedAt: Date;
  createdAt: Date;
  syncState: MessageSyncStates;
  editingState: MessageEditingTypes;
  showUser?: boolean;
};

class ChatMessages {
  constructor() {}
}

export default ChatMessages;

const isSameDay = (moment1: moment.Moment, moment2: moment.Moment) => {
  const moment1Clone = moment1.clone();
  const moment2Clone = moment2.clone();
  moment1Clone.startOf('d');
  return moment2Clone.isSame(moment1Clone, 'd');
};

type MessageComponents = {
  messageValue: string;
  authorId: string;
  authorUsername: string;
  authorAvatar: string;
  timetoken: string;
  isPostMessage?: boolean;
  channelId?;
  syncState: MessageSyncStates;
  editingState: MessageEditingTypes;
  updatedAt: Date;
  createdAt: Date;
};

export const createMessageObject = ({
  messageValue,
  authorId,
  authorUsername,
  authorAvatar,
  channelId,
  syncState,
  editingState,
  updatedAt,
  createdAt,
}: MessageComponents): Message => {
  //const messageMoment = moment(parseInt(createdAt) / 10000);
  //const timeStr = messageMoment.format('hh:mm A');
  //const nowMoment = moment(Date.now());
  //const dateStr = isSameDay(nowMoment, messageMoment)
  //  ? 'Today at'
  //  : messageMoment.format('DD/MM/YYYY');

  return {
    message: messageValue,
    authorUsername,
    author: {
      id: authorId,
      username: authorUsername,
      avatar: authorAvatar,
    },
    channelId,
    syncState,
    editingState,
    updatedAt,
    createdAt,
  };
};
